import "./App.css";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ApolloProvider } from "@apollo/client";
import TrainingRegistration from "./TrainingRegistration";
import ThankYou from "./ThankYou";

const apolloClient = new ApolloClient({
  uri: "https://yomassage-prod.hasura.app/v1/graphql",
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <Switch>
          <Route path="/trainings/:slug">
            <TrainingRegistration />
          </Route>
          <Route path="/thank-you">
            <ThankYou />
          </Route>
        </Switch>
      </Router>
    </ApolloProvider>
  );
}

export default App;
