import { FC } from "react";

const ThankYou: FC = () => {
  return (
    <>
      <h2 className="low-bottom">thank you for enrolling</h2>
      <h4 style={{ textTransform: "uppercase" }}>
        We will be in touch with more information
      </h4>
      <p style={{ textAlign: "center" }}>
        In the meantime, please check your email and activate your account. You
        can find all current enrollments{" "}
        <a href="https://yomassage.com/pages/downloads">here</a> .
      </p>
    </>
  );
};

export default ThankYou;
