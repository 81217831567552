import { ChangeEvent, FC, useCallback } from "react";
// @ts-ignore
import { IMaskInput } from "react-imask";
import IMask from "imask";
import { DateTime } from "luxon";
import classnames from "classnames";
import { ClassValue } from "classnames/types";
import { RegistrationFormInputType } from "./lib";

interface Props {
  inputType: RegistrationFormInputType;
  placeholder?: string;
  required?: boolean;
  name: string;
  onChange: (v: string) => void;
  classNames?: ClassValue;
}

const RegistrationFormInput: FC<Props> = ({
  inputType,
  placeholder,
  required,
  name,
  onChange,
  classNames,
}) => {
  let input;

  const handleChange = useCallback((v: string) => onChange(v), [onChange]);

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      handleChange(e.target.value),
    [handleChange]
  );

  switch (inputType) {
    case "email":
      input = (
        <input
          className={classnames("text-field w-input", classNames)}
          type="email"
          placeholder={placeholder}
          required={required}
          name={name}
          onChange={handleInputChange}
        ></input>
      );
      break;
    case "text":
      input = (
        <input
          className={classnames("text-field w-input", classNames)}
          type="text"
          placeholder={placeholder}
          required={required}
          name={name}
          onChange={handleInputChange}
        ></input>
      );
      break;
    case "text_area":
      input = (
        <textarea
          className={classnames("text-field w-input", classNames)}
          placeholder={placeholder}
          name={name}
          required={required}
          onChange={handleInputChange}
        ></textarea>
      );
      break;
    case "date":
      input = (
        <IMaskInput
          placeholder={placeholder}
          className={classnames("text-field w-input", classNames)}
          onAccept={(v: string) => handleChange(v)}
          mask={Date}
          name={name}
          pattern={"m/d/Y"}
          lazy={true}
          format={(date: Date) => {
            return DateTime.fromJSDate(date).toFormat("LL/dd/y");
          }}
          parse={(dateStr: string) => {
            return DateTime.fromFormat(dateStr, "LL/dd/y").toJSDate();
          }}
          blocks={{
            Y: {
              mask: IMask.MaskedRange,
              from: 1900,
              to: 3000,
            },
            m: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 12,
            },
            d: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 31,
            },
          }}
        />
      );
      break;
    default:
      throw new Error("Unexpected input type");
  }

  return input;
};

export default RegistrationFormInput;
