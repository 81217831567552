import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  json: any;
  jsonb: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type EmailDripCampaignOptOutResult = {
  __typename?: 'EmailDripCampaignOptOutResult';
  success: Scalars['Boolean'];
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type RegistrationDataInput = {
  coupon?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  training: Scalars['String'];
};

export type RegistrationReportRow = {
  __typename?: 'RegistrationReportRow';
  coupon?: Maybe<Scalars['String']>;
  created: Scalars['timestamptz'];
  customer?: Maybe<Scalars['String']>;
  data: Scalars['json'];
  discount: Scalars['Int'];
  email: Scalars['String'];
  payment_plan: Scalars['Boolean'];
  stripe_customer?: Maybe<Scalars['String']>;
  stripe_payment?: Maybe<Scalars['String']>;
  teachable_course: Scalars['bigint'];
  tracking_id?: Maybe<Scalars['String']>;
  training: Scalars['String'];
  training_date?: Maybe<Scalars['date']>;
  training_slug: Scalars['String'];
  value: Scalars['Int'];
};

export enum RegistrationReportType {
  All = 'All',
  Enrolled = 'Enrolled',
  Incomplete = 'Incomplete'
}

export type RegistrationResult = {
  __typename?: 'RegistrationResult';
  checkoutSession?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  training: Scalars['String'];
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type ValidateCouponInput = {
  code: Scalars['String'];
  email: Scalars['String'];
  training: Scalars['String'];
};

export type ValidateCouponResult = {
  __typename?: 'ValidateCouponResult';
  isValid: Scalars['Boolean'];
};

export type ValidateTrainingDownpaymentResult = {
  __typename?: 'ValidateTrainingDownpaymentResult';
  isValid: Scalars['Boolean'];
};


/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "commission_structures" */
export type Commission_Structures = {
  __typename?: 'commission_structures';
  identifier: Scalars['String'];
};

/** aggregated selection of "commission_structures" */
export type Commission_Structures_Aggregate = {
  __typename?: 'commission_structures_aggregate';
  aggregate?: Maybe<Commission_Structures_Aggregate_Fields>;
  nodes: Array<Commission_Structures>;
};

/** aggregate fields of "commission_structures" */
export type Commission_Structures_Aggregate_Fields = {
  __typename?: 'commission_structures_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Commission_Structures_Max_Fields>;
  min?: Maybe<Commission_Structures_Min_Fields>;
};


/** aggregate fields of "commission_structures" */
export type Commission_Structures_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Commission_Structures_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "commission_structures" */
export type Commission_Structures_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Commission_Structures_Max_Order_By>;
  min?: Maybe<Commission_Structures_Min_Order_By>;
};

/** input type for inserting array relation for remote table "commission_structures" */
export type Commission_Structures_Arr_Rel_Insert_Input = {
  data: Array<Commission_Structures_Insert_Input>;
  on_conflict?: Maybe<Commission_Structures_On_Conflict>;
};

/** Boolean expression to filter rows from the table "commission_structures". All fields are combined with a logical 'AND'. */
export type Commission_Structures_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Commission_Structures_Bool_Exp>>>;
  _not?: Maybe<Commission_Structures_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Commission_Structures_Bool_Exp>>>;
  identifier?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "commission_structures" */
export enum Commission_Structures_Constraint {
  /** unique or primary key constraint */
  CommissionStructuresPkey = 'commission_structures_pkey'
}

/** input type for inserting data into table "commission_structures" */
export type Commission_Structures_Insert_Input = {
  identifier?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Commission_Structures_Max_Fields = {
  __typename?: 'commission_structures_max_fields';
  identifier?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "commission_structures" */
export type Commission_Structures_Max_Order_By = {
  identifier?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Commission_Structures_Min_Fields = {
  __typename?: 'commission_structures_min_fields';
  identifier?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "commission_structures" */
export type Commission_Structures_Min_Order_By = {
  identifier?: Maybe<Order_By>;
};

/** response of any mutation on the table "commission_structures" */
export type Commission_Structures_Mutation_Response = {
  __typename?: 'commission_structures_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Commission_Structures>;
};

/** input type for inserting object relation for remote table "commission_structures" */
export type Commission_Structures_Obj_Rel_Insert_Input = {
  data: Commission_Structures_Insert_Input;
  on_conflict?: Maybe<Commission_Structures_On_Conflict>;
};

/** on conflict condition type for table "commission_structures" */
export type Commission_Structures_On_Conflict = {
  constraint: Commission_Structures_Constraint;
  update_columns: Array<Commission_Structures_Update_Column>;
  where?: Maybe<Commission_Structures_Bool_Exp>;
};

/** ordering options when selecting data from "commission_structures" */
export type Commission_Structures_Order_By = {
  identifier?: Maybe<Order_By>;
};

/** primary key columns input for table: "commission_structures" */
export type Commission_Structures_Pk_Columns_Input = {
  identifier: Scalars['String'];
};

/** select columns of table "commission_structures" */
export enum Commission_Structures_Select_Column {
  /** column name */
  Identifier = 'identifier'
}

/** input type for updating data in table "commission_structures" */
export type Commission_Structures_Set_Input = {
  identifier?: Maybe<Scalars['String']>;
};

/** update columns of table "commission_structures" */
export enum Commission_Structures_Update_Column {
  /** column name */
  Identifier = 'identifier'
}

/** columns and relationships of "coupon_discount_types" */
export type Coupon_Discount_Types = {
  __typename?: 'coupon_discount_types';
  /** An array relationship */
  coupons: Array<Coupons>;
  /** An aggregated array relationship */
  coupons_aggregate: Coupons_Aggregate;
  name: Scalars['String'];
};


/** columns and relationships of "coupon_discount_types" */
export type Coupon_Discount_TypesCouponsArgs = {
  distinct_on?: Maybe<Array<Coupons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupons_Order_By>>;
  where?: Maybe<Coupons_Bool_Exp>;
};


/** columns and relationships of "coupon_discount_types" */
export type Coupon_Discount_TypesCoupons_AggregateArgs = {
  distinct_on?: Maybe<Array<Coupons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupons_Order_By>>;
  where?: Maybe<Coupons_Bool_Exp>;
};

/** aggregated selection of "coupon_discount_types" */
export type Coupon_Discount_Types_Aggregate = {
  __typename?: 'coupon_discount_types_aggregate';
  aggregate?: Maybe<Coupon_Discount_Types_Aggregate_Fields>;
  nodes: Array<Coupon_Discount_Types>;
};

/** aggregate fields of "coupon_discount_types" */
export type Coupon_Discount_Types_Aggregate_Fields = {
  __typename?: 'coupon_discount_types_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Coupon_Discount_Types_Max_Fields>;
  min?: Maybe<Coupon_Discount_Types_Min_Fields>;
};


/** aggregate fields of "coupon_discount_types" */
export type Coupon_Discount_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Coupon_Discount_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coupon_discount_types" */
export type Coupon_Discount_Types_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Coupon_Discount_Types_Max_Order_By>;
  min?: Maybe<Coupon_Discount_Types_Min_Order_By>;
};

/** input type for inserting array relation for remote table "coupon_discount_types" */
export type Coupon_Discount_Types_Arr_Rel_Insert_Input = {
  data: Array<Coupon_Discount_Types_Insert_Input>;
  on_conflict?: Maybe<Coupon_Discount_Types_On_Conflict>;
};

/** Boolean expression to filter rows from the table "coupon_discount_types". All fields are combined with a logical 'AND'. */
export type Coupon_Discount_Types_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Coupon_Discount_Types_Bool_Exp>>>;
  _not?: Maybe<Coupon_Discount_Types_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Coupon_Discount_Types_Bool_Exp>>>;
  coupons?: Maybe<Coupons_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "coupon_discount_types" */
export enum Coupon_Discount_Types_Constraint {
  /** unique or primary key constraint */
  CouponDiscountTypesPkey = 'coupon_discount_types_pkey'
}

export enum Coupon_Discount_Types_Enum {
  Dollar = 'dollar',
  Percent = 'percent'
}

/** expression to compare columns of type coupon_discount_types_enum. All fields are combined with logical 'AND'. */
export type Coupon_Discount_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Coupon_Discount_Types_Enum>;
  _in?: Maybe<Array<Coupon_Discount_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Coupon_Discount_Types_Enum>;
  _nin?: Maybe<Array<Coupon_Discount_Types_Enum>>;
};

/** input type for inserting data into table "coupon_discount_types" */
export type Coupon_Discount_Types_Insert_Input = {
  coupons?: Maybe<Coupons_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Coupon_Discount_Types_Max_Fields = {
  __typename?: 'coupon_discount_types_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "coupon_discount_types" */
export type Coupon_Discount_Types_Max_Order_By = {
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Coupon_Discount_Types_Min_Fields = {
  __typename?: 'coupon_discount_types_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "coupon_discount_types" */
export type Coupon_Discount_Types_Min_Order_By = {
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "coupon_discount_types" */
export type Coupon_Discount_Types_Mutation_Response = {
  __typename?: 'coupon_discount_types_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Coupon_Discount_Types>;
};

/** input type for inserting object relation for remote table "coupon_discount_types" */
export type Coupon_Discount_Types_Obj_Rel_Insert_Input = {
  data: Coupon_Discount_Types_Insert_Input;
  on_conflict?: Maybe<Coupon_Discount_Types_On_Conflict>;
};

/** on conflict condition type for table "coupon_discount_types" */
export type Coupon_Discount_Types_On_Conflict = {
  constraint: Coupon_Discount_Types_Constraint;
  update_columns: Array<Coupon_Discount_Types_Update_Column>;
  where?: Maybe<Coupon_Discount_Types_Bool_Exp>;
};

/** ordering options when selecting data from "coupon_discount_types" */
export type Coupon_Discount_Types_Order_By = {
  coupons_aggregate?: Maybe<Coupons_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "coupon_discount_types" */
export type Coupon_Discount_Types_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "coupon_discount_types" */
export enum Coupon_Discount_Types_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "coupon_discount_types" */
export type Coupon_Discount_Types_Set_Input = {
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "coupon_discount_types" */
export enum Coupon_Discount_Types_Update_Column {
  /** column name */
  Name = 'name'
}

/** columns and relationships of "coupons" */
export type Coupons = {
  __typename?: 'coupons';
  active: Scalars['Boolean'];
  allowed_emails?: Maybe<Scalars['json']>;
  allowed_trainings: Scalars['jsonb'];
  amount: Scalars['Int'];
  auto_generated: Scalars['Boolean'];
  code: Scalars['String'];
  /** An object relationship */
  coupon_discount_type?: Maybe<Coupon_Discount_Types>;
  discount_type?: Maybe<Coupon_Discount_Types_Enum>;
  expires?: Maybe<Scalars['date']>;
  forbidden_trainings: Scalars['jsonb'];
  legacy_id?: Maybe<Scalars['bigint']>;
  max_uses?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  /** An array relationship */
  registrations: Array<Registrations>;
  /** An aggregated array relationship */
  registrations_aggregate: Registrations_Aggregate;
};


/** columns and relationships of "coupons" */
export type CouponsAllowed_EmailsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "coupons" */
export type CouponsAllowed_TrainingsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "coupons" */
export type CouponsForbidden_TrainingsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "coupons" */
export type CouponsRegistrationsArgs = {
  distinct_on?: Maybe<Array<Registrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registrations_Order_By>>;
  where?: Maybe<Registrations_Bool_Exp>;
};


/** columns and relationships of "coupons" */
export type CouponsRegistrations_AggregateArgs = {
  distinct_on?: Maybe<Array<Registrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registrations_Order_By>>;
  where?: Maybe<Registrations_Bool_Exp>;
};

/** aggregated selection of "coupons" */
export type Coupons_Aggregate = {
  __typename?: 'coupons_aggregate';
  aggregate?: Maybe<Coupons_Aggregate_Fields>;
  nodes: Array<Coupons>;
};

/** aggregate fields of "coupons" */
export type Coupons_Aggregate_Fields = {
  __typename?: 'coupons_aggregate_fields';
  avg?: Maybe<Coupons_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Coupons_Max_Fields>;
  min?: Maybe<Coupons_Min_Fields>;
  stddev?: Maybe<Coupons_Stddev_Fields>;
  stddev_pop?: Maybe<Coupons_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Coupons_Stddev_Samp_Fields>;
  sum?: Maybe<Coupons_Sum_Fields>;
  var_pop?: Maybe<Coupons_Var_Pop_Fields>;
  var_samp?: Maybe<Coupons_Var_Samp_Fields>;
  variance?: Maybe<Coupons_Variance_Fields>;
};


/** aggregate fields of "coupons" */
export type Coupons_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Coupons_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coupons" */
export type Coupons_Aggregate_Order_By = {
  avg?: Maybe<Coupons_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Coupons_Max_Order_By>;
  min?: Maybe<Coupons_Min_Order_By>;
  stddev?: Maybe<Coupons_Stddev_Order_By>;
  stddev_pop?: Maybe<Coupons_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Coupons_Stddev_Samp_Order_By>;
  sum?: Maybe<Coupons_Sum_Order_By>;
  var_pop?: Maybe<Coupons_Var_Pop_Order_By>;
  var_samp?: Maybe<Coupons_Var_Samp_Order_By>;
  variance?: Maybe<Coupons_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Coupons_Append_Input = {
  allowed_trainings?: Maybe<Scalars['jsonb']>;
  forbidden_trainings?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "coupons" */
export type Coupons_Arr_Rel_Insert_Input = {
  data: Array<Coupons_Insert_Input>;
  on_conflict?: Maybe<Coupons_On_Conflict>;
};

/** aggregate avg on columns */
export type Coupons_Avg_Fields = {
  __typename?: 'coupons_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  max_uses?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coupons" */
export type Coupons_Avg_Order_By = {
  amount?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  max_uses?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "coupons". All fields are combined with a logical 'AND'. */
export type Coupons_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Coupons_Bool_Exp>>>;
  _not?: Maybe<Coupons_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Coupons_Bool_Exp>>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  allowed_emails?: Maybe<Json_Comparison_Exp>;
  allowed_trainings?: Maybe<Jsonb_Comparison_Exp>;
  amount?: Maybe<Int_Comparison_Exp>;
  auto_generated?: Maybe<Boolean_Comparison_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  coupon_discount_type?: Maybe<Coupon_Discount_Types_Bool_Exp>;
  discount_type?: Maybe<Coupon_Discount_Types_Enum_Comparison_Exp>;
  expires?: Maybe<Date_Comparison_Exp>;
  forbidden_trainings?: Maybe<Jsonb_Comparison_Exp>;
  legacy_id?: Maybe<Bigint_Comparison_Exp>;
  max_uses?: Maybe<Int_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  registrations?: Maybe<Registrations_Bool_Exp>;
};

/** unique or primary key constraints on table "coupons" */
export enum Coupons_Constraint {
  /** unique or primary key constraint */
  CouponsPkey = 'coupons_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Coupons_Delete_At_Path_Input = {
  allowed_trainings?: Maybe<Array<Maybe<Scalars['String']>>>;
  forbidden_trainings?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Coupons_Delete_Elem_Input = {
  allowed_trainings?: Maybe<Scalars['Int']>;
  forbidden_trainings?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Coupons_Delete_Key_Input = {
  allowed_trainings?: Maybe<Scalars['String']>;
  forbidden_trainings?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "coupons" */
export type Coupons_Inc_Input = {
  amount?: Maybe<Scalars['Int']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  max_uses?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "coupons" */
export type Coupons_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  allowed_emails?: Maybe<Scalars['json']>;
  allowed_trainings?: Maybe<Scalars['jsonb']>;
  amount?: Maybe<Scalars['Int']>;
  auto_generated?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  coupon_discount_type?: Maybe<Coupon_Discount_Types_Obj_Rel_Insert_Input>;
  discount_type?: Maybe<Coupon_Discount_Types_Enum>;
  expires?: Maybe<Scalars['date']>;
  forbidden_trainings?: Maybe<Scalars['jsonb']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  max_uses?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  registrations?: Maybe<Registrations_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Coupons_Max_Fields = {
  __typename?: 'coupons_max_fields';
  amount?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  expires?: Maybe<Scalars['date']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  max_uses?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "coupons" */
export type Coupons_Max_Order_By = {
  amount?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  expires?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  max_uses?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Coupons_Min_Fields = {
  __typename?: 'coupons_min_fields';
  amount?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  expires?: Maybe<Scalars['date']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  max_uses?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "coupons" */
export type Coupons_Min_Order_By = {
  amount?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  expires?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  max_uses?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
};

/** response of any mutation on the table "coupons" */
export type Coupons_Mutation_Response = {
  __typename?: 'coupons_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Coupons>;
};

/** input type for inserting object relation for remote table "coupons" */
export type Coupons_Obj_Rel_Insert_Input = {
  data: Coupons_Insert_Input;
  on_conflict?: Maybe<Coupons_On_Conflict>;
};

/** on conflict condition type for table "coupons" */
export type Coupons_On_Conflict = {
  constraint: Coupons_Constraint;
  update_columns: Array<Coupons_Update_Column>;
  where?: Maybe<Coupons_Bool_Exp>;
};

/** ordering options when selecting data from "coupons" */
export type Coupons_Order_By = {
  active?: Maybe<Order_By>;
  allowed_emails?: Maybe<Order_By>;
  allowed_trainings?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  auto_generated?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  coupon_discount_type?: Maybe<Coupon_Discount_Types_Order_By>;
  discount_type?: Maybe<Order_By>;
  expires?: Maybe<Order_By>;
  forbidden_trainings?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  max_uses?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  registrations_aggregate?: Maybe<Registrations_Aggregate_Order_By>;
};

/** primary key columns input for table: "coupons" */
export type Coupons_Pk_Columns_Input = {
  code: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Coupons_Prepend_Input = {
  allowed_trainings?: Maybe<Scalars['jsonb']>;
  forbidden_trainings?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "coupons" */
export enum Coupons_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AllowedEmails = 'allowed_emails',
  /** column name */
  AllowedTrainings = 'allowed_trainings',
  /** column name */
  Amount = 'amount',
  /** column name */
  AutoGenerated = 'auto_generated',
  /** column name */
  Code = 'code',
  /** column name */
  DiscountType = 'discount_type',
  /** column name */
  Expires = 'expires',
  /** column name */
  ForbiddenTrainings = 'forbidden_trainings',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  MaxUses = 'max_uses',
  /** column name */
  Notes = 'notes'
}

/** input type for updating data in table "coupons" */
export type Coupons_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  allowed_emails?: Maybe<Scalars['json']>;
  allowed_trainings?: Maybe<Scalars['jsonb']>;
  amount?: Maybe<Scalars['Int']>;
  auto_generated?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  discount_type?: Maybe<Coupon_Discount_Types_Enum>;
  expires?: Maybe<Scalars['date']>;
  forbidden_trainings?: Maybe<Scalars['jsonb']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  max_uses?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Coupons_Stddev_Fields = {
  __typename?: 'coupons_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  max_uses?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coupons" */
export type Coupons_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  max_uses?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Coupons_Stddev_Pop_Fields = {
  __typename?: 'coupons_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  max_uses?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coupons" */
export type Coupons_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  max_uses?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Coupons_Stddev_Samp_Fields = {
  __typename?: 'coupons_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  max_uses?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coupons" */
export type Coupons_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  max_uses?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Coupons_Sum_Fields = {
  __typename?: 'coupons_sum_fields';
  amount?: Maybe<Scalars['Int']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  max_uses?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "coupons" */
export type Coupons_Sum_Order_By = {
  amount?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  max_uses?: Maybe<Order_By>;
};

/** update columns of table "coupons" */
export enum Coupons_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AllowedEmails = 'allowed_emails',
  /** column name */
  AllowedTrainings = 'allowed_trainings',
  /** column name */
  Amount = 'amount',
  /** column name */
  AutoGenerated = 'auto_generated',
  /** column name */
  Code = 'code',
  /** column name */
  DiscountType = 'discount_type',
  /** column name */
  Expires = 'expires',
  /** column name */
  ForbiddenTrainings = 'forbidden_trainings',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  MaxUses = 'max_uses',
  /** column name */
  Notes = 'notes'
}

/** aggregate var_pop on columns */
export type Coupons_Var_Pop_Fields = {
  __typename?: 'coupons_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  max_uses?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coupons" */
export type Coupons_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  max_uses?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Coupons_Var_Samp_Fields = {
  __typename?: 'coupons_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  max_uses?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coupons" */
export type Coupons_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  max_uses?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Coupons_Variance_Fields = {
  __typename?: 'coupons_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  max_uses?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coupons" */
export type Coupons_Variance_Order_By = {
  amount?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  max_uses?: Maybe<Order_By>;
};


/** expression to compare columns of type date. All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "email_drip_campaign_cancelation_status" */
export type Email_Drip_Campaign_Cancelation_Status = {
  __typename?: 'email_drip_campaign_cancelation_status';
  /** An array relationship */
  email_drip_campaigns: Array<Email_Drip_Campaigns>;
  /** An aggregated array relationship */
  email_drip_campaigns_aggregate: Email_Drip_Campaigns_Aggregate;
  name: Scalars['String'];
};


/** columns and relationships of "email_drip_campaign_cancelation_status" */
export type Email_Drip_Campaign_Cancelation_StatusEmail_Drip_CampaignsArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaigns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaigns_Order_By>>;
  where?: Maybe<Email_Drip_Campaigns_Bool_Exp>;
};


/** columns and relationships of "email_drip_campaign_cancelation_status" */
export type Email_Drip_Campaign_Cancelation_StatusEmail_Drip_Campaigns_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaigns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaigns_Order_By>>;
  where?: Maybe<Email_Drip_Campaigns_Bool_Exp>;
};

/** aggregated selection of "email_drip_campaign_cancelation_status" */
export type Email_Drip_Campaign_Cancelation_Status_Aggregate = {
  __typename?: 'email_drip_campaign_cancelation_status_aggregate';
  aggregate?: Maybe<Email_Drip_Campaign_Cancelation_Status_Aggregate_Fields>;
  nodes: Array<Email_Drip_Campaign_Cancelation_Status>;
};

/** aggregate fields of "email_drip_campaign_cancelation_status" */
export type Email_Drip_Campaign_Cancelation_Status_Aggregate_Fields = {
  __typename?: 'email_drip_campaign_cancelation_status_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Email_Drip_Campaign_Cancelation_Status_Max_Fields>;
  min?: Maybe<Email_Drip_Campaign_Cancelation_Status_Min_Fields>;
};


/** aggregate fields of "email_drip_campaign_cancelation_status" */
export type Email_Drip_Campaign_Cancelation_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Email_Drip_Campaign_Cancelation_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "email_drip_campaign_cancelation_status" */
export type Email_Drip_Campaign_Cancelation_Status_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Email_Drip_Campaign_Cancelation_Status_Max_Order_By>;
  min?: Maybe<Email_Drip_Campaign_Cancelation_Status_Min_Order_By>;
};

/** input type for inserting array relation for remote table "email_drip_campaign_cancelation_status" */
export type Email_Drip_Campaign_Cancelation_Status_Arr_Rel_Insert_Input = {
  data: Array<Email_Drip_Campaign_Cancelation_Status_Insert_Input>;
  on_conflict?: Maybe<Email_Drip_Campaign_Cancelation_Status_On_Conflict>;
};

/** Boolean expression to filter rows from the table "email_drip_campaign_cancelation_status". All fields are combined with a logical 'AND'. */
export type Email_Drip_Campaign_Cancelation_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Email_Drip_Campaign_Cancelation_Status_Bool_Exp>>>;
  _not?: Maybe<Email_Drip_Campaign_Cancelation_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Email_Drip_Campaign_Cancelation_Status_Bool_Exp>>>;
  email_drip_campaigns?: Maybe<Email_Drip_Campaigns_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_drip_campaign_cancelation_status" */
export enum Email_Drip_Campaign_Cancelation_Status_Constraint {
  /** unique or primary key constraint */
  EmailDripCampaignCancelationStatusPkey = 'email_drip_campaign_cancelation_status_pkey'
}

export enum Email_Drip_Campaign_Cancelation_Status_Enum {
  GoalComplete = 'goal_complete',
  ProspectOptOut = 'prospect_opt_out',
  SequenceComplete = 'sequence_complete'
}

/** expression to compare columns of type email_drip_campaign_cancelation_status_enum. All fields are combined with logical 'AND'. */
export type Email_Drip_Campaign_Cancelation_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Email_Drip_Campaign_Cancelation_Status_Enum>;
  _in?: Maybe<Array<Email_Drip_Campaign_Cancelation_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Email_Drip_Campaign_Cancelation_Status_Enum>;
  _nin?: Maybe<Array<Email_Drip_Campaign_Cancelation_Status_Enum>>;
};

/** input type for inserting data into table "email_drip_campaign_cancelation_status" */
export type Email_Drip_Campaign_Cancelation_Status_Insert_Input = {
  email_drip_campaigns?: Maybe<Email_Drip_Campaigns_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Email_Drip_Campaign_Cancelation_Status_Max_Fields = {
  __typename?: 'email_drip_campaign_cancelation_status_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "email_drip_campaign_cancelation_status" */
export type Email_Drip_Campaign_Cancelation_Status_Max_Order_By = {
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Email_Drip_Campaign_Cancelation_Status_Min_Fields = {
  __typename?: 'email_drip_campaign_cancelation_status_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "email_drip_campaign_cancelation_status" */
export type Email_Drip_Campaign_Cancelation_Status_Min_Order_By = {
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "email_drip_campaign_cancelation_status" */
export type Email_Drip_Campaign_Cancelation_Status_Mutation_Response = {
  __typename?: 'email_drip_campaign_cancelation_status_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Email_Drip_Campaign_Cancelation_Status>;
};

/** input type for inserting object relation for remote table "email_drip_campaign_cancelation_status" */
export type Email_Drip_Campaign_Cancelation_Status_Obj_Rel_Insert_Input = {
  data: Email_Drip_Campaign_Cancelation_Status_Insert_Input;
  on_conflict?: Maybe<Email_Drip_Campaign_Cancelation_Status_On_Conflict>;
};

/** on conflict condition type for table "email_drip_campaign_cancelation_status" */
export type Email_Drip_Campaign_Cancelation_Status_On_Conflict = {
  constraint: Email_Drip_Campaign_Cancelation_Status_Constraint;
  update_columns: Array<Email_Drip_Campaign_Cancelation_Status_Update_Column>;
  where?: Maybe<Email_Drip_Campaign_Cancelation_Status_Bool_Exp>;
};

/** ordering options when selecting data from "email_drip_campaign_cancelation_status" */
export type Email_Drip_Campaign_Cancelation_Status_Order_By = {
  email_drip_campaigns_aggregate?: Maybe<Email_Drip_Campaigns_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "email_drip_campaign_cancelation_status" */
export type Email_Drip_Campaign_Cancelation_Status_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "email_drip_campaign_cancelation_status" */
export enum Email_Drip_Campaign_Cancelation_Status_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "email_drip_campaign_cancelation_status" */
export type Email_Drip_Campaign_Cancelation_Status_Set_Input = {
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "email_drip_campaign_cancelation_status" */
export enum Email_Drip_Campaign_Cancelation_Status_Update_Column {
  /** column name */
  Name = 'name'
}

/** columns and relationships of "email_drip_campaign_goal_types" */
export type Email_Drip_Campaign_Goal_Types = {
  __typename?: 'email_drip_campaign_goal_types';
  /** An array relationship */
  email_drip_campaigns: Array<Email_Drip_Campaigns>;
  /** An aggregated array relationship */
  email_drip_campaigns_aggregate: Email_Drip_Campaigns_Aggregate;
  name: Scalars['String'];
};


/** columns and relationships of "email_drip_campaign_goal_types" */
export type Email_Drip_Campaign_Goal_TypesEmail_Drip_CampaignsArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaigns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaigns_Order_By>>;
  where?: Maybe<Email_Drip_Campaigns_Bool_Exp>;
};


/** columns and relationships of "email_drip_campaign_goal_types" */
export type Email_Drip_Campaign_Goal_TypesEmail_Drip_Campaigns_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaigns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaigns_Order_By>>;
  where?: Maybe<Email_Drip_Campaigns_Bool_Exp>;
};

/** aggregated selection of "email_drip_campaign_goal_types" */
export type Email_Drip_Campaign_Goal_Types_Aggregate = {
  __typename?: 'email_drip_campaign_goal_types_aggregate';
  aggregate?: Maybe<Email_Drip_Campaign_Goal_Types_Aggregate_Fields>;
  nodes: Array<Email_Drip_Campaign_Goal_Types>;
};

/** aggregate fields of "email_drip_campaign_goal_types" */
export type Email_Drip_Campaign_Goal_Types_Aggregate_Fields = {
  __typename?: 'email_drip_campaign_goal_types_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Email_Drip_Campaign_Goal_Types_Max_Fields>;
  min?: Maybe<Email_Drip_Campaign_Goal_Types_Min_Fields>;
};


/** aggregate fields of "email_drip_campaign_goal_types" */
export type Email_Drip_Campaign_Goal_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Email_Drip_Campaign_Goal_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "email_drip_campaign_goal_types" */
export type Email_Drip_Campaign_Goal_Types_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Email_Drip_Campaign_Goal_Types_Max_Order_By>;
  min?: Maybe<Email_Drip_Campaign_Goal_Types_Min_Order_By>;
};

/** input type for inserting array relation for remote table "email_drip_campaign_goal_types" */
export type Email_Drip_Campaign_Goal_Types_Arr_Rel_Insert_Input = {
  data: Array<Email_Drip_Campaign_Goal_Types_Insert_Input>;
  on_conflict?: Maybe<Email_Drip_Campaign_Goal_Types_On_Conflict>;
};

/** Boolean expression to filter rows from the table "email_drip_campaign_goal_types". All fields are combined with a logical 'AND'. */
export type Email_Drip_Campaign_Goal_Types_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Email_Drip_Campaign_Goal_Types_Bool_Exp>>>;
  _not?: Maybe<Email_Drip_Campaign_Goal_Types_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Email_Drip_Campaign_Goal_Types_Bool_Exp>>>;
  email_drip_campaigns?: Maybe<Email_Drip_Campaigns_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_drip_campaign_goal_types" */
export enum Email_Drip_Campaign_Goal_Types_Constraint {
  /** unique or primary key constraint */
  EmailDripCampaignGoalTypesPkey = 'email_drip_campaign_goal_types_pkey'
}

export enum Email_Drip_Campaign_Goal_Types_Enum {
  Enrollment = 'enrollment'
}

/** expression to compare columns of type email_drip_campaign_goal_types_enum. All fields are combined with logical 'AND'. */
export type Email_Drip_Campaign_Goal_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Email_Drip_Campaign_Goal_Types_Enum>;
  _in?: Maybe<Array<Email_Drip_Campaign_Goal_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Email_Drip_Campaign_Goal_Types_Enum>;
  _nin?: Maybe<Array<Email_Drip_Campaign_Goal_Types_Enum>>;
};

/** input type for inserting data into table "email_drip_campaign_goal_types" */
export type Email_Drip_Campaign_Goal_Types_Insert_Input = {
  email_drip_campaigns?: Maybe<Email_Drip_Campaigns_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Email_Drip_Campaign_Goal_Types_Max_Fields = {
  __typename?: 'email_drip_campaign_goal_types_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "email_drip_campaign_goal_types" */
export type Email_Drip_Campaign_Goal_Types_Max_Order_By = {
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Email_Drip_Campaign_Goal_Types_Min_Fields = {
  __typename?: 'email_drip_campaign_goal_types_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "email_drip_campaign_goal_types" */
export type Email_Drip_Campaign_Goal_Types_Min_Order_By = {
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "email_drip_campaign_goal_types" */
export type Email_Drip_Campaign_Goal_Types_Mutation_Response = {
  __typename?: 'email_drip_campaign_goal_types_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Email_Drip_Campaign_Goal_Types>;
};

/** input type for inserting object relation for remote table "email_drip_campaign_goal_types" */
export type Email_Drip_Campaign_Goal_Types_Obj_Rel_Insert_Input = {
  data: Email_Drip_Campaign_Goal_Types_Insert_Input;
  on_conflict?: Maybe<Email_Drip_Campaign_Goal_Types_On_Conflict>;
};

/** on conflict condition type for table "email_drip_campaign_goal_types" */
export type Email_Drip_Campaign_Goal_Types_On_Conflict = {
  constraint: Email_Drip_Campaign_Goal_Types_Constraint;
  update_columns: Array<Email_Drip_Campaign_Goal_Types_Update_Column>;
  where?: Maybe<Email_Drip_Campaign_Goal_Types_Bool_Exp>;
};

/** ordering options when selecting data from "email_drip_campaign_goal_types" */
export type Email_Drip_Campaign_Goal_Types_Order_By = {
  email_drip_campaigns_aggregate?: Maybe<Email_Drip_Campaigns_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "email_drip_campaign_goal_types" */
export type Email_Drip_Campaign_Goal_Types_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "email_drip_campaign_goal_types" */
export enum Email_Drip_Campaign_Goal_Types_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "email_drip_campaign_goal_types" */
export type Email_Drip_Campaign_Goal_Types_Set_Input = {
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "email_drip_campaign_goal_types" */
export enum Email_Drip_Campaign_Goal_Types_Update_Column {
  /** column name */
  Name = 'name'
}

/** columns and relationships of "email_drip_campaign_send_after_references" */
export type Email_Drip_Campaign_Send_After_References = {
  __typename?: 'email_drip_campaign_send_after_references';
  /** An array relationship */
  email_drip_sequence_steps: Array<Email_Drip_Sequence_Steps>;
  /** An aggregated array relationship */
  email_drip_sequence_steps_aggregate: Email_Drip_Sequence_Steps_Aggregate;
  title: Scalars['String'];
};


/** columns and relationships of "email_drip_campaign_send_after_references" */
export type Email_Drip_Campaign_Send_After_ReferencesEmail_Drip_Sequence_StepsArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Sequence_Steps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Sequence_Steps_Order_By>>;
  where?: Maybe<Email_Drip_Sequence_Steps_Bool_Exp>;
};


/** columns and relationships of "email_drip_campaign_send_after_references" */
export type Email_Drip_Campaign_Send_After_ReferencesEmail_Drip_Sequence_Steps_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Sequence_Steps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Sequence_Steps_Order_By>>;
  where?: Maybe<Email_Drip_Sequence_Steps_Bool_Exp>;
};

/** aggregated selection of "email_drip_campaign_send_after_references" */
export type Email_Drip_Campaign_Send_After_References_Aggregate = {
  __typename?: 'email_drip_campaign_send_after_references_aggregate';
  aggregate?: Maybe<Email_Drip_Campaign_Send_After_References_Aggregate_Fields>;
  nodes: Array<Email_Drip_Campaign_Send_After_References>;
};

/** aggregate fields of "email_drip_campaign_send_after_references" */
export type Email_Drip_Campaign_Send_After_References_Aggregate_Fields = {
  __typename?: 'email_drip_campaign_send_after_references_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Email_Drip_Campaign_Send_After_References_Max_Fields>;
  min?: Maybe<Email_Drip_Campaign_Send_After_References_Min_Fields>;
};


/** aggregate fields of "email_drip_campaign_send_after_references" */
export type Email_Drip_Campaign_Send_After_References_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Email_Drip_Campaign_Send_After_References_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "email_drip_campaign_send_after_references" */
export type Email_Drip_Campaign_Send_After_References_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Email_Drip_Campaign_Send_After_References_Max_Order_By>;
  min?: Maybe<Email_Drip_Campaign_Send_After_References_Min_Order_By>;
};

/** input type for inserting array relation for remote table "email_drip_campaign_send_after_references" */
export type Email_Drip_Campaign_Send_After_References_Arr_Rel_Insert_Input = {
  data: Array<Email_Drip_Campaign_Send_After_References_Insert_Input>;
  on_conflict?: Maybe<Email_Drip_Campaign_Send_After_References_On_Conflict>;
};

/** Boolean expression to filter rows from the table "email_drip_campaign_send_after_references". All fields are combined with a logical 'AND'. */
export type Email_Drip_Campaign_Send_After_References_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Email_Drip_Campaign_Send_After_References_Bool_Exp>>>;
  _not?: Maybe<Email_Drip_Campaign_Send_After_References_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Email_Drip_Campaign_Send_After_References_Bool_Exp>>>;
  email_drip_sequence_steps?: Maybe<Email_Drip_Sequence_Steps_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_drip_campaign_send_after_references" */
export enum Email_Drip_Campaign_Send_After_References_Constraint {
  /** unique or primary key constraint */
  EmailDripCampaignSendAfterReferencesPkey = 'email_drip_campaign_send_after_references_pkey'
}

export enum Email_Drip_Campaign_Send_After_References_Enum {
  CampaignStart = 'campaign_start',
  PreviousStep = 'previous_step'
}

/** expression to compare columns of type email_drip_campaign_send_after_references_enum. All fields are combined with logical 'AND'. */
export type Email_Drip_Campaign_Send_After_References_Enum_Comparison_Exp = {
  _eq?: Maybe<Email_Drip_Campaign_Send_After_References_Enum>;
  _in?: Maybe<Array<Email_Drip_Campaign_Send_After_References_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Email_Drip_Campaign_Send_After_References_Enum>;
  _nin?: Maybe<Array<Email_Drip_Campaign_Send_After_References_Enum>>;
};

/** input type for inserting data into table "email_drip_campaign_send_after_references" */
export type Email_Drip_Campaign_Send_After_References_Insert_Input = {
  email_drip_sequence_steps?: Maybe<Email_Drip_Sequence_Steps_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Email_Drip_Campaign_Send_After_References_Max_Fields = {
  __typename?: 'email_drip_campaign_send_after_references_max_fields';
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "email_drip_campaign_send_after_references" */
export type Email_Drip_Campaign_Send_After_References_Max_Order_By = {
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Email_Drip_Campaign_Send_After_References_Min_Fields = {
  __typename?: 'email_drip_campaign_send_after_references_min_fields';
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "email_drip_campaign_send_after_references" */
export type Email_Drip_Campaign_Send_After_References_Min_Order_By = {
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "email_drip_campaign_send_after_references" */
export type Email_Drip_Campaign_Send_After_References_Mutation_Response = {
  __typename?: 'email_drip_campaign_send_after_references_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Email_Drip_Campaign_Send_After_References>;
};

/** input type for inserting object relation for remote table "email_drip_campaign_send_after_references" */
export type Email_Drip_Campaign_Send_After_References_Obj_Rel_Insert_Input = {
  data: Email_Drip_Campaign_Send_After_References_Insert_Input;
  on_conflict?: Maybe<Email_Drip_Campaign_Send_After_References_On_Conflict>;
};

/** on conflict condition type for table "email_drip_campaign_send_after_references" */
export type Email_Drip_Campaign_Send_After_References_On_Conflict = {
  constraint: Email_Drip_Campaign_Send_After_References_Constraint;
  update_columns: Array<Email_Drip_Campaign_Send_After_References_Update_Column>;
  where?: Maybe<Email_Drip_Campaign_Send_After_References_Bool_Exp>;
};

/** ordering options when selecting data from "email_drip_campaign_send_after_references" */
export type Email_Drip_Campaign_Send_After_References_Order_By = {
  email_drip_sequence_steps_aggregate?: Maybe<Email_Drip_Sequence_Steps_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "email_drip_campaign_send_after_references" */
export type Email_Drip_Campaign_Send_After_References_Pk_Columns_Input = {
  title: Scalars['String'];
};

/** select columns of table "email_drip_campaign_send_after_references" */
export enum Email_Drip_Campaign_Send_After_References_Select_Column {
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "email_drip_campaign_send_after_references" */
export type Email_Drip_Campaign_Send_After_References_Set_Input = {
  title?: Maybe<Scalars['String']>;
};

/** update columns of table "email_drip_campaign_send_after_references" */
export enum Email_Drip_Campaign_Send_After_References_Update_Column {
  /** column name */
  Title = 'title'
}

/** columns and relationships of "email_drip_campaign_send_opens" */
export type Email_Drip_Campaign_Send_Opens = {
  __typename?: 'email_drip_campaign_send_opens';
  /** An object relationship */
  emailDripSequenceStepByEmailDripSequenceStep: Email_Drip_Sequence_Steps;
  email_drip_campaign_email: Scalars['String'];
  /** An object relationship */
  email_drip_campaign_send?: Maybe<Email_Drip_Campaign_Sends>;
  email_drip_sequence_step: Scalars['String'];
  timestamp: Scalars['timestamptz'];
};

/** aggregated selection of "email_drip_campaign_send_opens" */
export type Email_Drip_Campaign_Send_Opens_Aggregate = {
  __typename?: 'email_drip_campaign_send_opens_aggregate';
  aggregate?: Maybe<Email_Drip_Campaign_Send_Opens_Aggregate_Fields>;
  nodes: Array<Email_Drip_Campaign_Send_Opens>;
};

/** aggregate fields of "email_drip_campaign_send_opens" */
export type Email_Drip_Campaign_Send_Opens_Aggregate_Fields = {
  __typename?: 'email_drip_campaign_send_opens_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Email_Drip_Campaign_Send_Opens_Max_Fields>;
  min?: Maybe<Email_Drip_Campaign_Send_Opens_Min_Fields>;
};


/** aggregate fields of "email_drip_campaign_send_opens" */
export type Email_Drip_Campaign_Send_Opens_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Email_Drip_Campaign_Send_Opens_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "email_drip_campaign_send_opens" */
export type Email_Drip_Campaign_Send_Opens_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Email_Drip_Campaign_Send_Opens_Max_Order_By>;
  min?: Maybe<Email_Drip_Campaign_Send_Opens_Min_Order_By>;
};

/** input type for inserting array relation for remote table "email_drip_campaign_send_opens" */
export type Email_Drip_Campaign_Send_Opens_Arr_Rel_Insert_Input = {
  data: Array<Email_Drip_Campaign_Send_Opens_Insert_Input>;
  on_conflict?: Maybe<Email_Drip_Campaign_Send_Opens_On_Conflict>;
};

/** Boolean expression to filter rows from the table "email_drip_campaign_send_opens". All fields are combined with a logical 'AND'. */
export type Email_Drip_Campaign_Send_Opens_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Email_Drip_Campaign_Send_Opens_Bool_Exp>>>;
  _not?: Maybe<Email_Drip_Campaign_Send_Opens_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Email_Drip_Campaign_Send_Opens_Bool_Exp>>>;
  emailDripSequenceStepByEmailDripSequenceStep?: Maybe<Email_Drip_Sequence_Steps_Bool_Exp>;
  email_drip_campaign_email?: Maybe<String_Comparison_Exp>;
  email_drip_campaign_send?: Maybe<Email_Drip_Campaign_Sends_Bool_Exp>;
  email_drip_sequence_step?: Maybe<String_Comparison_Exp>;
  timestamp?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_drip_campaign_send_opens" */
export enum Email_Drip_Campaign_Send_Opens_Constraint {
  /** unique or primary key constraint */
  EmailDripCampaignSendOpensPkey = 'email_drip_campaign_send_opens_pkey'
}

/** input type for inserting data into table "email_drip_campaign_send_opens" */
export type Email_Drip_Campaign_Send_Opens_Insert_Input = {
  emailDripSequenceStepByEmailDripSequenceStep?: Maybe<Email_Drip_Sequence_Steps_Obj_Rel_Insert_Input>;
  email_drip_campaign_email?: Maybe<Scalars['String']>;
  email_drip_campaign_send?: Maybe<Email_Drip_Campaign_Sends_Obj_Rel_Insert_Input>;
  email_drip_sequence_step?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Email_Drip_Campaign_Send_Opens_Max_Fields = {
  __typename?: 'email_drip_campaign_send_opens_max_fields';
  email_drip_campaign_email?: Maybe<Scalars['String']>;
  email_drip_sequence_step?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "email_drip_campaign_send_opens" */
export type Email_Drip_Campaign_Send_Opens_Max_Order_By = {
  email_drip_campaign_email?: Maybe<Order_By>;
  email_drip_sequence_step?: Maybe<Order_By>;
  timestamp?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Email_Drip_Campaign_Send_Opens_Min_Fields = {
  __typename?: 'email_drip_campaign_send_opens_min_fields';
  email_drip_campaign_email?: Maybe<Scalars['String']>;
  email_drip_sequence_step?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "email_drip_campaign_send_opens" */
export type Email_Drip_Campaign_Send_Opens_Min_Order_By = {
  email_drip_campaign_email?: Maybe<Order_By>;
  email_drip_sequence_step?: Maybe<Order_By>;
  timestamp?: Maybe<Order_By>;
};

/** response of any mutation on the table "email_drip_campaign_send_opens" */
export type Email_Drip_Campaign_Send_Opens_Mutation_Response = {
  __typename?: 'email_drip_campaign_send_opens_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Email_Drip_Campaign_Send_Opens>;
};

/** input type for inserting object relation for remote table "email_drip_campaign_send_opens" */
export type Email_Drip_Campaign_Send_Opens_Obj_Rel_Insert_Input = {
  data: Email_Drip_Campaign_Send_Opens_Insert_Input;
  on_conflict?: Maybe<Email_Drip_Campaign_Send_Opens_On_Conflict>;
};

/** on conflict condition type for table "email_drip_campaign_send_opens" */
export type Email_Drip_Campaign_Send_Opens_On_Conflict = {
  constraint: Email_Drip_Campaign_Send_Opens_Constraint;
  update_columns: Array<Email_Drip_Campaign_Send_Opens_Update_Column>;
  where?: Maybe<Email_Drip_Campaign_Send_Opens_Bool_Exp>;
};

/** ordering options when selecting data from "email_drip_campaign_send_opens" */
export type Email_Drip_Campaign_Send_Opens_Order_By = {
  emailDripSequenceStepByEmailDripSequenceStep?: Maybe<Email_Drip_Sequence_Steps_Order_By>;
  email_drip_campaign_email?: Maybe<Order_By>;
  email_drip_campaign_send?: Maybe<Email_Drip_Campaign_Sends_Order_By>;
  email_drip_sequence_step?: Maybe<Order_By>;
  timestamp?: Maybe<Order_By>;
};

/** primary key columns input for table: "email_drip_campaign_send_opens" */
export type Email_Drip_Campaign_Send_Opens_Pk_Columns_Input = {
  timestamp: Scalars['timestamptz'];
};

/** select columns of table "email_drip_campaign_send_opens" */
export enum Email_Drip_Campaign_Send_Opens_Select_Column {
  /** column name */
  EmailDripCampaignEmail = 'email_drip_campaign_email',
  /** column name */
  EmailDripSequenceStep = 'email_drip_sequence_step',
  /** column name */
  Timestamp = 'timestamp'
}

/** input type for updating data in table "email_drip_campaign_send_opens" */
export type Email_Drip_Campaign_Send_Opens_Set_Input = {
  email_drip_campaign_email?: Maybe<Scalars['String']>;
  email_drip_sequence_step?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "email_drip_campaign_send_opens" */
export enum Email_Drip_Campaign_Send_Opens_Update_Column {
  /** column name */
  EmailDripCampaignEmail = 'email_drip_campaign_email',
  /** column name */
  EmailDripSequenceStep = 'email_drip_sequence_step',
  /** column name */
  Timestamp = 'timestamp'
}

/** columns and relationships of "email_drip_campaign_sends" */
export type Email_Drip_Campaign_Sends = {
  __typename?: 'email_drip_campaign_sends';
  /** An object relationship */
  emailDripSequenceStepByEmailDripSequenceStep: Email_Drip_Sequence_Steps;
  /** An object relationship */
  email_drip_campaign?: Maybe<Email_Drip_Campaigns>;
  email_drip_campaign_email: Scalars['String'];
  /** An array relationship */
  email_drip_campaign_send_opens: Array<Email_Drip_Campaign_Send_Opens>;
  /** An aggregated array relationship */
  email_drip_campaign_send_opens_aggregate: Email_Drip_Campaign_Send_Opens_Aggregate;
  email_drip_campaign_sequence: Scalars['String'];
  email_drip_sequence_step: Scalars['String'];
  generated_embed_meta: Scalars['jsonb'];
  sent_at: Scalars['timestamp'];
  tracking_pixel: Scalars['uuid'];
};


/** columns and relationships of "email_drip_campaign_sends" */
export type Email_Drip_Campaign_SendsEmail_Drip_Campaign_Send_OpensArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Send_Opens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Send_Opens_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Send_Opens_Bool_Exp>;
};


/** columns and relationships of "email_drip_campaign_sends" */
export type Email_Drip_Campaign_SendsEmail_Drip_Campaign_Send_Opens_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Send_Opens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Send_Opens_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Send_Opens_Bool_Exp>;
};


/** columns and relationships of "email_drip_campaign_sends" */
export type Email_Drip_Campaign_SendsGenerated_Embed_MetaArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "email_drip_campaign_sends" */
export type Email_Drip_Campaign_Sends_Aggregate = {
  __typename?: 'email_drip_campaign_sends_aggregate';
  aggregate?: Maybe<Email_Drip_Campaign_Sends_Aggregate_Fields>;
  nodes: Array<Email_Drip_Campaign_Sends>;
};

/** aggregate fields of "email_drip_campaign_sends" */
export type Email_Drip_Campaign_Sends_Aggregate_Fields = {
  __typename?: 'email_drip_campaign_sends_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Email_Drip_Campaign_Sends_Max_Fields>;
  min?: Maybe<Email_Drip_Campaign_Sends_Min_Fields>;
};


/** aggregate fields of "email_drip_campaign_sends" */
export type Email_Drip_Campaign_Sends_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Email_Drip_Campaign_Sends_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "email_drip_campaign_sends" */
export type Email_Drip_Campaign_Sends_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Email_Drip_Campaign_Sends_Max_Order_By>;
  min?: Maybe<Email_Drip_Campaign_Sends_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Email_Drip_Campaign_Sends_Append_Input = {
  generated_embed_meta?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "email_drip_campaign_sends" */
export type Email_Drip_Campaign_Sends_Arr_Rel_Insert_Input = {
  data: Array<Email_Drip_Campaign_Sends_Insert_Input>;
  on_conflict?: Maybe<Email_Drip_Campaign_Sends_On_Conflict>;
};

/** Boolean expression to filter rows from the table "email_drip_campaign_sends". All fields are combined with a logical 'AND'. */
export type Email_Drip_Campaign_Sends_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Email_Drip_Campaign_Sends_Bool_Exp>>>;
  _not?: Maybe<Email_Drip_Campaign_Sends_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Email_Drip_Campaign_Sends_Bool_Exp>>>;
  emailDripSequenceStepByEmailDripSequenceStep?: Maybe<Email_Drip_Sequence_Steps_Bool_Exp>;
  email_drip_campaign?: Maybe<Email_Drip_Campaigns_Bool_Exp>;
  email_drip_campaign_email?: Maybe<String_Comparison_Exp>;
  email_drip_campaign_send_opens?: Maybe<Email_Drip_Campaign_Send_Opens_Bool_Exp>;
  email_drip_campaign_sequence?: Maybe<String_Comparison_Exp>;
  email_drip_sequence_step?: Maybe<String_Comparison_Exp>;
  generated_embed_meta?: Maybe<Jsonb_Comparison_Exp>;
  sent_at?: Maybe<Timestamp_Comparison_Exp>;
  tracking_pixel?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_drip_campaign_sends" */
export enum Email_Drip_Campaign_Sends_Constraint {
  /** unique or primary key constraint */
  EmailDripCampaignSendsPkey = 'email_drip_campaign_sends_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Email_Drip_Campaign_Sends_Delete_At_Path_Input = {
  generated_embed_meta?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Email_Drip_Campaign_Sends_Delete_Elem_Input = {
  generated_embed_meta?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Email_Drip_Campaign_Sends_Delete_Key_Input = {
  generated_embed_meta?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "email_drip_campaign_sends" */
export type Email_Drip_Campaign_Sends_Insert_Input = {
  emailDripSequenceStepByEmailDripSequenceStep?: Maybe<Email_Drip_Sequence_Steps_Obj_Rel_Insert_Input>;
  email_drip_campaign?: Maybe<Email_Drip_Campaigns_Obj_Rel_Insert_Input>;
  email_drip_campaign_email?: Maybe<Scalars['String']>;
  email_drip_campaign_send_opens?: Maybe<Email_Drip_Campaign_Send_Opens_Arr_Rel_Insert_Input>;
  email_drip_campaign_sequence?: Maybe<Scalars['String']>;
  email_drip_sequence_step?: Maybe<Scalars['String']>;
  generated_embed_meta?: Maybe<Scalars['jsonb']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  tracking_pixel?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Email_Drip_Campaign_Sends_Max_Fields = {
  __typename?: 'email_drip_campaign_sends_max_fields';
  email_drip_campaign_email?: Maybe<Scalars['String']>;
  email_drip_campaign_sequence?: Maybe<Scalars['String']>;
  email_drip_sequence_step?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  tracking_pixel?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "email_drip_campaign_sends" */
export type Email_Drip_Campaign_Sends_Max_Order_By = {
  email_drip_campaign_email?: Maybe<Order_By>;
  email_drip_campaign_sequence?: Maybe<Order_By>;
  email_drip_sequence_step?: Maybe<Order_By>;
  sent_at?: Maybe<Order_By>;
  tracking_pixel?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Email_Drip_Campaign_Sends_Min_Fields = {
  __typename?: 'email_drip_campaign_sends_min_fields';
  email_drip_campaign_email?: Maybe<Scalars['String']>;
  email_drip_campaign_sequence?: Maybe<Scalars['String']>;
  email_drip_sequence_step?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  tracking_pixel?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "email_drip_campaign_sends" */
export type Email_Drip_Campaign_Sends_Min_Order_By = {
  email_drip_campaign_email?: Maybe<Order_By>;
  email_drip_campaign_sequence?: Maybe<Order_By>;
  email_drip_sequence_step?: Maybe<Order_By>;
  sent_at?: Maybe<Order_By>;
  tracking_pixel?: Maybe<Order_By>;
};

/** response of any mutation on the table "email_drip_campaign_sends" */
export type Email_Drip_Campaign_Sends_Mutation_Response = {
  __typename?: 'email_drip_campaign_sends_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Email_Drip_Campaign_Sends>;
};

/** input type for inserting object relation for remote table "email_drip_campaign_sends" */
export type Email_Drip_Campaign_Sends_Obj_Rel_Insert_Input = {
  data: Email_Drip_Campaign_Sends_Insert_Input;
  on_conflict?: Maybe<Email_Drip_Campaign_Sends_On_Conflict>;
};

/** on conflict condition type for table "email_drip_campaign_sends" */
export type Email_Drip_Campaign_Sends_On_Conflict = {
  constraint: Email_Drip_Campaign_Sends_Constraint;
  update_columns: Array<Email_Drip_Campaign_Sends_Update_Column>;
  where?: Maybe<Email_Drip_Campaign_Sends_Bool_Exp>;
};

/** ordering options when selecting data from "email_drip_campaign_sends" */
export type Email_Drip_Campaign_Sends_Order_By = {
  emailDripSequenceStepByEmailDripSequenceStep?: Maybe<Email_Drip_Sequence_Steps_Order_By>;
  email_drip_campaign?: Maybe<Email_Drip_Campaigns_Order_By>;
  email_drip_campaign_email?: Maybe<Order_By>;
  email_drip_campaign_send_opens_aggregate?: Maybe<Email_Drip_Campaign_Send_Opens_Aggregate_Order_By>;
  email_drip_campaign_sequence?: Maybe<Order_By>;
  email_drip_sequence_step?: Maybe<Order_By>;
  generated_embed_meta?: Maybe<Order_By>;
  sent_at?: Maybe<Order_By>;
  tracking_pixel?: Maybe<Order_By>;
};

/** primary key columns input for table: "email_drip_campaign_sends" */
export type Email_Drip_Campaign_Sends_Pk_Columns_Input = {
  email_drip_campaign_email: Scalars['String'];
  email_drip_sequence_step: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Email_Drip_Campaign_Sends_Prepend_Input = {
  generated_embed_meta?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "email_drip_campaign_sends" */
export enum Email_Drip_Campaign_Sends_Select_Column {
  /** column name */
  EmailDripCampaignEmail = 'email_drip_campaign_email',
  /** column name */
  EmailDripCampaignSequence = 'email_drip_campaign_sequence',
  /** column name */
  EmailDripSequenceStep = 'email_drip_sequence_step',
  /** column name */
  GeneratedEmbedMeta = 'generated_embed_meta',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  TrackingPixel = 'tracking_pixel'
}

/** input type for updating data in table "email_drip_campaign_sends" */
export type Email_Drip_Campaign_Sends_Set_Input = {
  email_drip_campaign_email?: Maybe<Scalars['String']>;
  email_drip_campaign_sequence?: Maybe<Scalars['String']>;
  email_drip_sequence_step?: Maybe<Scalars['String']>;
  generated_embed_meta?: Maybe<Scalars['jsonb']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  tracking_pixel?: Maybe<Scalars['uuid']>;
};

/** update columns of table "email_drip_campaign_sends" */
export enum Email_Drip_Campaign_Sends_Update_Column {
  /** column name */
  EmailDripCampaignEmail = 'email_drip_campaign_email',
  /** column name */
  EmailDripCampaignSequence = 'email_drip_campaign_sequence',
  /** column name */
  EmailDripSequenceStep = 'email_drip_sequence_step',
  /** column name */
  GeneratedEmbedMeta = 'generated_embed_meta',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  TrackingPixel = 'tracking_pixel'
}

/** columns and relationships of "email_drip_campaigns" */
export type Email_Drip_Campaigns = {
  __typename?: 'email_drip_campaigns';
  cancelation_status?: Maybe<Email_Drip_Campaign_Cancelation_Status_Enum>;
  canceled: Scalars['Boolean'];
  email: Scalars['String'];
  /** An object relationship */
  emailDripSequenceByEmailDripSequence: Email_Drip_Sequences;
  /** An object relationship */
  email_drip_campaign_cancelation_status?: Maybe<Email_Drip_Campaign_Cancelation_Status>;
  /** An object relationship */
  email_drip_campaign_goal_type?: Maybe<Email_Drip_Campaign_Goal_Types>;
  /** An array relationship */
  email_drip_campaign_sends: Array<Email_Drip_Campaign_Sends>;
  /** An aggregated array relationship */
  email_drip_campaign_sends_aggregate: Email_Drip_Campaign_Sends_Aggregate;
  email_drip_sequence: Scalars['String'];
  goal_data: Scalars['jsonb'];
  goal_type?: Maybe<Email_Drip_Campaign_Goal_Types_Enum>;
  is_test: Scalars['Boolean'];
};


/** columns and relationships of "email_drip_campaigns" */
export type Email_Drip_CampaignsEmail_Drip_Campaign_SendsArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Sends_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Sends_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Sends_Bool_Exp>;
};


/** columns and relationships of "email_drip_campaigns" */
export type Email_Drip_CampaignsEmail_Drip_Campaign_Sends_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Sends_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Sends_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Sends_Bool_Exp>;
};


/** columns and relationships of "email_drip_campaigns" */
export type Email_Drip_CampaignsGoal_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "email_drip_campaigns" */
export type Email_Drip_Campaigns_Aggregate = {
  __typename?: 'email_drip_campaigns_aggregate';
  aggregate?: Maybe<Email_Drip_Campaigns_Aggregate_Fields>;
  nodes: Array<Email_Drip_Campaigns>;
};

/** aggregate fields of "email_drip_campaigns" */
export type Email_Drip_Campaigns_Aggregate_Fields = {
  __typename?: 'email_drip_campaigns_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Email_Drip_Campaigns_Max_Fields>;
  min?: Maybe<Email_Drip_Campaigns_Min_Fields>;
};


/** aggregate fields of "email_drip_campaigns" */
export type Email_Drip_Campaigns_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Email_Drip_Campaigns_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "email_drip_campaigns" */
export type Email_Drip_Campaigns_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Email_Drip_Campaigns_Max_Order_By>;
  min?: Maybe<Email_Drip_Campaigns_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Email_Drip_Campaigns_Append_Input = {
  goal_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "email_drip_campaigns" */
export type Email_Drip_Campaigns_Arr_Rel_Insert_Input = {
  data: Array<Email_Drip_Campaigns_Insert_Input>;
  on_conflict?: Maybe<Email_Drip_Campaigns_On_Conflict>;
};

/** Boolean expression to filter rows from the table "email_drip_campaigns". All fields are combined with a logical 'AND'. */
export type Email_Drip_Campaigns_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Email_Drip_Campaigns_Bool_Exp>>>;
  _not?: Maybe<Email_Drip_Campaigns_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Email_Drip_Campaigns_Bool_Exp>>>;
  cancelation_status?: Maybe<Email_Drip_Campaign_Cancelation_Status_Enum_Comparison_Exp>;
  canceled?: Maybe<Boolean_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  emailDripSequenceByEmailDripSequence?: Maybe<Email_Drip_Sequences_Bool_Exp>;
  email_drip_campaign_cancelation_status?: Maybe<Email_Drip_Campaign_Cancelation_Status_Bool_Exp>;
  email_drip_campaign_goal_type?: Maybe<Email_Drip_Campaign_Goal_Types_Bool_Exp>;
  email_drip_campaign_sends?: Maybe<Email_Drip_Campaign_Sends_Bool_Exp>;
  email_drip_sequence?: Maybe<String_Comparison_Exp>;
  goal_data?: Maybe<Jsonb_Comparison_Exp>;
  goal_type?: Maybe<Email_Drip_Campaign_Goal_Types_Enum_Comparison_Exp>;
  is_test?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_drip_campaigns" */
export enum Email_Drip_Campaigns_Constraint {
  /** unique or primary key constraint */
  EmailDripCampaignsPkey = 'email_drip_campaigns_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Email_Drip_Campaigns_Delete_At_Path_Input = {
  goal_data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Email_Drip_Campaigns_Delete_Elem_Input = {
  goal_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Email_Drip_Campaigns_Delete_Key_Input = {
  goal_data?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "email_drip_campaigns" */
export type Email_Drip_Campaigns_Insert_Input = {
  cancelation_status?: Maybe<Email_Drip_Campaign_Cancelation_Status_Enum>;
  canceled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  emailDripSequenceByEmailDripSequence?: Maybe<Email_Drip_Sequences_Obj_Rel_Insert_Input>;
  email_drip_campaign_cancelation_status?: Maybe<Email_Drip_Campaign_Cancelation_Status_Obj_Rel_Insert_Input>;
  email_drip_campaign_goal_type?: Maybe<Email_Drip_Campaign_Goal_Types_Obj_Rel_Insert_Input>;
  email_drip_campaign_sends?: Maybe<Email_Drip_Campaign_Sends_Arr_Rel_Insert_Input>;
  email_drip_sequence?: Maybe<Scalars['String']>;
  goal_data?: Maybe<Scalars['jsonb']>;
  goal_type?: Maybe<Email_Drip_Campaign_Goal_Types_Enum>;
  is_test?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Email_Drip_Campaigns_Max_Fields = {
  __typename?: 'email_drip_campaigns_max_fields';
  email?: Maybe<Scalars['String']>;
  email_drip_sequence?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "email_drip_campaigns" */
export type Email_Drip_Campaigns_Max_Order_By = {
  email?: Maybe<Order_By>;
  email_drip_sequence?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Email_Drip_Campaigns_Min_Fields = {
  __typename?: 'email_drip_campaigns_min_fields';
  email?: Maybe<Scalars['String']>;
  email_drip_sequence?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "email_drip_campaigns" */
export type Email_Drip_Campaigns_Min_Order_By = {
  email?: Maybe<Order_By>;
  email_drip_sequence?: Maybe<Order_By>;
};

/** response of any mutation on the table "email_drip_campaigns" */
export type Email_Drip_Campaigns_Mutation_Response = {
  __typename?: 'email_drip_campaigns_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Email_Drip_Campaigns>;
};

/** input type for inserting object relation for remote table "email_drip_campaigns" */
export type Email_Drip_Campaigns_Obj_Rel_Insert_Input = {
  data: Email_Drip_Campaigns_Insert_Input;
  on_conflict?: Maybe<Email_Drip_Campaigns_On_Conflict>;
};

/** on conflict condition type for table "email_drip_campaigns" */
export type Email_Drip_Campaigns_On_Conflict = {
  constraint: Email_Drip_Campaigns_Constraint;
  update_columns: Array<Email_Drip_Campaigns_Update_Column>;
  where?: Maybe<Email_Drip_Campaigns_Bool_Exp>;
};

/** ordering options when selecting data from "email_drip_campaigns" */
export type Email_Drip_Campaigns_Order_By = {
  cancelation_status?: Maybe<Order_By>;
  canceled?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  emailDripSequenceByEmailDripSequence?: Maybe<Email_Drip_Sequences_Order_By>;
  email_drip_campaign_cancelation_status?: Maybe<Email_Drip_Campaign_Cancelation_Status_Order_By>;
  email_drip_campaign_goal_type?: Maybe<Email_Drip_Campaign_Goal_Types_Order_By>;
  email_drip_campaign_sends_aggregate?: Maybe<Email_Drip_Campaign_Sends_Aggregate_Order_By>;
  email_drip_sequence?: Maybe<Order_By>;
  goal_data?: Maybe<Order_By>;
  goal_type?: Maybe<Order_By>;
  is_test?: Maybe<Order_By>;
};

/** primary key columns input for table: "email_drip_campaigns" */
export type Email_Drip_Campaigns_Pk_Columns_Input = {
  email: Scalars['String'];
  email_drip_sequence: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Email_Drip_Campaigns_Prepend_Input = {
  goal_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "email_drip_campaigns" */
export enum Email_Drip_Campaigns_Select_Column {
  /** column name */
  CancelationStatus = 'cancelation_status',
  /** column name */
  Canceled = 'canceled',
  /** column name */
  Email = 'email',
  /** column name */
  EmailDripSequence = 'email_drip_sequence',
  /** column name */
  GoalData = 'goal_data',
  /** column name */
  GoalType = 'goal_type',
  /** column name */
  IsTest = 'is_test'
}

/** input type for updating data in table "email_drip_campaigns" */
export type Email_Drip_Campaigns_Set_Input = {
  cancelation_status?: Maybe<Email_Drip_Campaign_Cancelation_Status_Enum>;
  canceled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  email_drip_sequence?: Maybe<Scalars['String']>;
  goal_data?: Maybe<Scalars['jsonb']>;
  goal_type?: Maybe<Email_Drip_Campaign_Goal_Types_Enum>;
  is_test?: Maybe<Scalars['Boolean']>;
};

/** update columns of table "email_drip_campaigns" */
export enum Email_Drip_Campaigns_Update_Column {
  /** column name */
  CancelationStatus = 'cancelation_status',
  /** column name */
  Canceled = 'canceled',
  /** column name */
  Email = 'email',
  /** column name */
  EmailDripSequence = 'email_drip_sequence',
  /** column name */
  GoalData = 'goal_data',
  /** column name */
  GoalType = 'goal_type',
  /** column name */
  IsTest = 'is_test'
}

/** columns and relationships of "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps = {
  __typename?: 'email_drip_sequence_steps';
  /** An object relationship */
  emailDripSequenceByTitle: Email_Drip_Sequences;
  /** An object relationship */
  email_drip_campaign_send_after_reference?: Maybe<Email_Drip_Campaign_Send_After_References>;
  /** An array relationship */
  email_drip_campaign_send_opens: Array<Email_Drip_Campaign_Send_Opens>;
  /** An aggregated array relationship */
  email_drip_campaign_send_opens_aggregate: Email_Drip_Campaign_Send_Opens_Aggregate;
  /** An array relationship */
  email_drip_campaign_sends: Array<Email_Drip_Campaign_Sends>;
  /** An aggregated array relationship */
  email_drip_campaign_sends_aggregate: Email_Drip_Campaign_Sends_Aggregate;
  email_drip_sequence: Scalars['String'];
  position: Scalars['Int'];
  send_after_reference?: Maybe<Email_Drip_Campaign_Send_After_References_Enum>;
  send_after_seconds?: Maybe<Scalars['Int']>;
  subject: Scalars['String'];
  template: Scalars['String'];
  template_data: Scalars['jsonb'];
  title: Scalars['String'];
};


/** columns and relationships of "email_drip_sequence_steps" */
export type Email_Drip_Sequence_StepsEmail_Drip_Campaign_Send_OpensArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Send_Opens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Send_Opens_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Send_Opens_Bool_Exp>;
};


/** columns and relationships of "email_drip_sequence_steps" */
export type Email_Drip_Sequence_StepsEmail_Drip_Campaign_Send_Opens_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Send_Opens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Send_Opens_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Send_Opens_Bool_Exp>;
};


/** columns and relationships of "email_drip_sequence_steps" */
export type Email_Drip_Sequence_StepsEmail_Drip_Campaign_SendsArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Sends_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Sends_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Sends_Bool_Exp>;
};


/** columns and relationships of "email_drip_sequence_steps" */
export type Email_Drip_Sequence_StepsEmail_Drip_Campaign_Sends_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Sends_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Sends_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Sends_Bool_Exp>;
};


/** columns and relationships of "email_drip_sequence_steps" */
export type Email_Drip_Sequence_StepsTemplate_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Aggregate = {
  __typename?: 'email_drip_sequence_steps_aggregate';
  aggregate?: Maybe<Email_Drip_Sequence_Steps_Aggregate_Fields>;
  nodes: Array<Email_Drip_Sequence_Steps>;
};

/** aggregate fields of "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Aggregate_Fields = {
  __typename?: 'email_drip_sequence_steps_aggregate_fields';
  avg?: Maybe<Email_Drip_Sequence_Steps_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Email_Drip_Sequence_Steps_Max_Fields>;
  min?: Maybe<Email_Drip_Sequence_Steps_Min_Fields>;
  stddev?: Maybe<Email_Drip_Sequence_Steps_Stddev_Fields>;
  stddev_pop?: Maybe<Email_Drip_Sequence_Steps_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Email_Drip_Sequence_Steps_Stddev_Samp_Fields>;
  sum?: Maybe<Email_Drip_Sequence_Steps_Sum_Fields>;
  var_pop?: Maybe<Email_Drip_Sequence_Steps_Var_Pop_Fields>;
  var_samp?: Maybe<Email_Drip_Sequence_Steps_Var_Samp_Fields>;
  variance?: Maybe<Email_Drip_Sequence_Steps_Variance_Fields>;
};


/** aggregate fields of "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Email_Drip_Sequence_Steps_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Aggregate_Order_By = {
  avg?: Maybe<Email_Drip_Sequence_Steps_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Email_Drip_Sequence_Steps_Max_Order_By>;
  min?: Maybe<Email_Drip_Sequence_Steps_Min_Order_By>;
  stddev?: Maybe<Email_Drip_Sequence_Steps_Stddev_Order_By>;
  stddev_pop?: Maybe<Email_Drip_Sequence_Steps_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Email_Drip_Sequence_Steps_Stddev_Samp_Order_By>;
  sum?: Maybe<Email_Drip_Sequence_Steps_Sum_Order_By>;
  var_pop?: Maybe<Email_Drip_Sequence_Steps_Var_Pop_Order_By>;
  var_samp?: Maybe<Email_Drip_Sequence_Steps_Var_Samp_Order_By>;
  variance?: Maybe<Email_Drip_Sequence_Steps_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Email_Drip_Sequence_Steps_Append_Input = {
  template_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Arr_Rel_Insert_Input = {
  data: Array<Email_Drip_Sequence_Steps_Insert_Input>;
  on_conflict?: Maybe<Email_Drip_Sequence_Steps_On_Conflict>;
};

/** aggregate avg on columns */
export type Email_Drip_Sequence_Steps_Avg_Fields = {
  __typename?: 'email_drip_sequence_steps_avg_fields';
  position?: Maybe<Scalars['Float']>;
  send_after_seconds?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Avg_Order_By = {
  position?: Maybe<Order_By>;
  send_after_seconds?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "email_drip_sequence_steps". All fields are combined with a logical 'AND'. */
export type Email_Drip_Sequence_Steps_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Email_Drip_Sequence_Steps_Bool_Exp>>>;
  _not?: Maybe<Email_Drip_Sequence_Steps_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Email_Drip_Sequence_Steps_Bool_Exp>>>;
  emailDripSequenceByTitle?: Maybe<Email_Drip_Sequences_Bool_Exp>;
  email_drip_campaign_send_after_reference?: Maybe<Email_Drip_Campaign_Send_After_References_Bool_Exp>;
  email_drip_campaign_send_opens?: Maybe<Email_Drip_Campaign_Send_Opens_Bool_Exp>;
  email_drip_campaign_sends?: Maybe<Email_Drip_Campaign_Sends_Bool_Exp>;
  email_drip_sequence?: Maybe<String_Comparison_Exp>;
  position?: Maybe<Int_Comparison_Exp>;
  send_after_reference?: Maybe<Email_Drip_Campaign_Send_After_References_Enum_Comparison_Exp>;
  send_after_seconds?: Maybe<Int_Comparison_Exp>;
  subject?: Maybe<String_Comparison_Exp>;
  template?: Maybe<String_Comparison_Exp>;
  template_data?: Maybe<Jsonb_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_drip_sequence_steps" */
export enum Email_Drip_Sequence_Steps_Constraint {
  /** unique or primary key constraint */
  EmailDripSequenceStepsPkey = 'email_drip_sequence_steps_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Email_Drip_Sequence_Steps_Delete_At_Path_Input = {
  template_data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Email_Drip_Sequence_Steps_Delete_Elem_Input = {
  template_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Email_Drip_Sequence_Steps_Delete_Key_Input = {
  template_data?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Inc_Input = {
  position?: Maybe<Scalars['Int']>;
  send_after_seconds?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Insert_Input = {
  emailDripSequenceByTitle?: Maybe<Email_Drip_Sequences_Obj_Rel_Insert_Input>;
  email_drip_campaign_send_after_reference?: Maybe<Email_Drip_Campaign_Send_After_References_Obj_Rel_Insert_Input>;
  email_drip_campaign_send_opens?: Maybe<Email_Drip_Campaign_Send_Opens_Arr_Rel_Insert_Input>;
  email_drip_campaign_sends?: Maybe<Email_Drip_Campaign_Sends_Arr_Rel_Insert_Input>;
  email_drip_sequence?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  send_after_reference?: Maybe<Email_Drip_Campaign_Send_After_References_Enum>;
  send_after_seconds?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  template_data?: Maybe<Scalars['jsonb']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Email_Drip_Sequence_Steps_Max_Fields = {
  __typename?: 'email_drip_sequence_steps_max_fields';
  email_drip_sequence?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  send_after_seconds?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Max_Order_By = {
  email_drip_sequence?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  send_after_seconds?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  template?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Email_Drip_Sequence_Steps_Min_Fields = {
  __typename?: 'email_drip_sequence_steps_min_fields';
  email_drip_sequence?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  send_after_seconds?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Min_Order_By = {
  email_drip_sequence?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  send_after_seconds?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  template?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Mutation_Response = {
  __typename?: 'email_drip_sequence_steps_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Email_Drip_Sequence_Steps>;
};

/** input type for inserting object relation for remote table "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Obj_Rel_Insert_Input = {
  data: Email_Drip_Sequence_Steps_Insert_Input;
  on_conflict?: Maybe<Email_Drip_Sequence_Steps_On_Conflict>;
};

/** on conflict condition type for table "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_On_Conflict = {
  constraint: Email_Drip_Sequence_Steps_Constraint;
  update_columns: Array<Email_Drip_Sequence_Steps_Update_Column>;
  where?: Maybe<Email_Drip_Sequence_Steps_Bool_Exp>;
};

/** ordering options when selecting data from "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Order_By = {
  emailDripSequenceByTitle?: Maybe<Email_Drip_Sequences_Order_By>;
  email_drip_campaign_send_after_reference?: Maybe<Email_Drip_Campaign_Send_After_References_Order_By>;
  email_drip_campaign_send_opens_aggregate?: Maybe<Email_Drip_Campaign_Send_Opens_Aggregate_Order_By>;
  email_drip_campaign_sends_aggregate?: Maybe<Email_Drip_Campaign_Sends_Aggregate_Order_By>;
  email_drip_sequence?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  send_after_reference?: Maybe<Order_By>;
  send_after_seconds?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  template?: Maybe<Order_By>;
  template_data?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Pk_Columns_Input = {
  title: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Email_Drip_Sequence_Steps_Prepend_Input = {
  template_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "email_drip_sequence_steps" */
export enum Email_Drip_Sequence_Steps_Select_Column {
  /** column name */
  EmailDripSequence = 'email_drip_sequence',
  /** column name */
  Position = 'position',
  /** column name */
  SendAfterReference = 'send_after_reference',
  /** column name */
  SendAfterSeconds = 'send_after_seconds',
  /** column name */
  Subject = 'subject',
  /** column name */
  Template = 'template',
  /** column name */
  TemplateData = 'template_data',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Set_Input = {
  email_drip_sequence?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  send_after_reference?: Maybe<Email_Drip_Campaign_Send_After_References_Enum>;
  send_after_seconds?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  template_data?: Maybe<Scalars['jsonb']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Email_Drip_Sequence_Steps_Stddev_Fields = {
  __typename?: 'email_drip_sequence_steps_stddev_fields';
  position?: Maybe<Scalars['Float']>;
  send_after_seconds?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Stddev_Order_By = {
  position?: Maybe<Order_By>;
  send_after_seconds?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Email_Drip_Sequence_Steps_Stddev_Pop_Fields = {
  __typename?: 'email_drip_sequence_steps_stddev_pop_fields';
  position?: Maybe<Scalars['Float']>;
  send_after_seconds?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Stddev_Pop_Order_By = {
  position?: Maybe<Order_By>;
  send_after_seconds?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Email_Drip_Sequence_Steps_Stddev_Samp_Fields = {
  __typename?: 'email_drip_sequence_steps_stddev_samp_fields';
  position?: Maybe<Scalars['Float']>;
  send_after_seconds?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Stddev_Samp_Order_By = {
  position?: Maybe<Order_By>;
  send_after_seconds?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Email_Drip_Sequence_Steps_Sum_Fields = {
  __typename?: 'email_drip_sequence_steps_sum_fields';
  position?: Maybe<Scalars['Int']>;
  send_after_seconds?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Sum_Order_By = {
  position?: Maybe<Order_By>;
  send_after_seconds?: Maybe<Order_By>;
};

/** update columns of table "email_drip_sequence_steps" */
export enum Email_Drip_Sequence_Steps_Update_Column {
  /** column name */
  EmailDripSequence = 'email_drip_sequence',
  /** column name */
  Position = 'position',
  /** column name */
  SendAfterReference = 'send_after_reference',
  /** column name */
  SendAfterSeconds = 'send_after_seconds',
  /** column name */
  Subject = 'subject',
  /** column name */
  Template = 'template',
  /** column name */
  TemplateData = 'template_data',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type Email_Drip_Sequence_Steps_Var_Pop_Fields = {
  __typename?: 'email_drip_sequence_steps_var_pop_fields';
  position?: Maybe<Scalars['Float']>;
  send_after_seconds?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Var_Pop_Order_By = {
  position?: Maybe<Order_By>;
  send_after_seconds?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Email_Drip_Sequence_Steps_Var_Samp_Fields = {
  __typename?: 'email_drip_sequence_steps_var_samp_fields';
  position?: Maybe<Scalars['Float']>;
  send_after_seconds?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Var_Samp_Order_By = {
  position?: Maybe<Order_By>;
  send_after_seconds?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Email_Drip_Sequence_Steps_Variance_Fields = {
  __typename?: 'email_drip_sequence_steps_variance_fields';
  position?: Maybe<Scalars['Float']>;
  send_after_seconds?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "email_drip_sequence_steps" */
export type Email_Drip_Sequence_Steps_Variance_Order_By = {
  position?: Maybe<Order_By>;
  send_after_seconds?: Maybe<Order_By>;
};

/** columns and relationships of "email_drip_sequences" */
export type Email_Drip_Sequences = {
  __typename?: 'email_drip_sequences';
  /** An array relationship */
  email_drip_campaigns: Array<Email_Drip_Campaigns>;
  /** An aggregated array relationship */
  email_drip_campaigns_aggregate: Email_Drip_Campaigns_Aggregate;
  /** An array relationship */
  email_drip_sequence_steps: Array<Email_Drip_Sequence_Steps>;
  /** An aggregated array relationship */
  email_drip_sequence_steps_aggregate: Email_Drip_Sequence_Steps_Aggregate;
  title: Scalars['String'];
};


/** columns and relationships of "email_drip_sequences" */
export type Email_Drip_SequencesEmail_Drip_CampaignsArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaigns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaigns_Order_By>>;
  where?: Maybe<Email_Drip_Campaigns_Bool_Exp>;
};


/** columns and relationships of "email_drip_sequences" */
export type Email_Drip_SequencesEmail_Drip_Campaigns_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaigns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaigns_Order_By>>;
  where?: Maybe<Email_Drip_Campaigns_Bool_Exp>;
};


/** columns and relationships of "email_drip_sequences" */
export type Email_Drip_SequencesEmail_Drip_Sequence_StepsArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Sequence_Steps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Sequence_Steps_Order_By>>;
  where?: Maybe<Email_Drip_Sequence_Steps_Bool_Exp>;
};


/** columns and relationships of "email_drip_sequences" */
export type Email_Drip_SequencesEmail_Drip_Sequence_Steps_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Sequence_Steps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Sequence_Steps_Order_By>>;
  where?: Maybe<Email_Drip_Sequence_Steps_Bool_Exp>;
};

/** aggregated selection of "email_drip_sequences" */
export type Email_Drip_Sequences_Aggregate = {
  __typename?: 'email_drip_sequences_aggregate';
  aggregate?: Maybe<Email_Drip_Sequences_Aggregate_Fields>;
  nodes: Array<Email_Drip_Sequences>;
};

/** aggregate fields of "email_drip_sequences" */
export type Email_Drip_Sequences_Aggregate_Fields = {
  __typename?: 'email_drip_sequences_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Email_Drip_Sequences_Max_Fields>;
  min?: Maybe<Email_Drip_Sequences_Min_Fields>;
};


/** aggregate fields of "email_drip_sequences" */
export type Email_Drip_Sequences_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Email_Drip_Sequences_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "email_drip_sequences" */
export type Email_Drip_Sequences_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Email_Drip_Sequences_Max_Order_By>;
  min?: Maybe<Email_Drip_Sequences_Min_Order_By>;
};

/** input type for inserting array relation for remote table "email_drip_sequences" */
export type Email_Drip_Sequences_Arr_Rel_Insert_Input = {
  data: Array<Email_Drip_Sequences_Insert_Input>;
  on_conflict?: Maybe<Email_Drip_Sequences_On_Conflict>;
};

/** Boolean expression to filter rows from the table "email_drip_sequences". All fields are combined with a logical 'AND'. */
export type Email_Drip_Sequences_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Email_Drip_Sequences_Bool_Exp>>>;
  _not?: Maybe<Email_Drip_Sequences_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Email_Drip_Sequences_Bool_Exp>>>;
  email_drip_campaigns?: Maybe<Email_Drip_Campaigns_Bool_Exp>;
  email_drip_sequence_steps?: Maybe<Email_Drip_Sequence_Steps_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_drip_sequences" */
export enum Email_Drip_Sequences_Constraint {
  /** unique or primary key constraint */
  EmailDripSequencesPkey = 'email_drip_sequences_pkey'
}

/** input type for inserting data into table "email_drip_sequences" */
export type Email_Drip_Sequences_Insert_Input = {
  email_drip_campaigns?: Maybe<Email_Drip_Campaigns_Arr_Rel_Insert_Input>;
  email_drip_sequence_steps?: Maybe<Email_Drip_Sequence_Steps_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Email_Drip_Sequences_Max_Fields = {
  __typename?: 'email_drip_sequences_max_fields';
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "email_drip_sequences" */
export type Email_Drip_Sequences_Max_Order_By = {
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Email_Drip_Sequences_Min_Fields = {
  __typename?: 'email_drip_sequences_min_fields';
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "email_drip_sequences" */
export type Email_Drip_Sequences_Min_Order_By = {
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "email_drip_sequences" */
export type Email_Drip_Sequences_Mutation_Response = {
  __typename?: 'email_drip_sequences_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Email_Drip_Sequences>;
};

/** input type for inserting object relation for remote table "email_drip_sequences" */
export type Email_Drip_Sequences_Obj_Rel_Insert_Input = {
  data: Email_Drip_Sequences_Insert_Input;
  on_conflict?: Maybe<Email_Drip_Sequences_On_Conflict>;
};

/** on conflict condition type for table "email_drip_sequences" */
export type Email_Drip_Sequences_On_Conflict = {
  constraint: Email_Drip_Sequences_Constraint;
  update_columns: Array<Email_Drip_Sequences_Update_Column>;
  where?: Maybe<Email_Drip_Sequences_Bool_Exp>;
};

/** ordering options when selecting data from "email_drip_sequences" */
export type Email_Drip_Sequences_Order_By = {
  email_drip_campaigns_aggregate?: Maybe<Email_Drip_Campaigns_Aggregate_Order_By>;
  email_drip_sequence_steps_aggregate?: Maybe<Email_Drip_Sequence_Steps_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "email_drip_sequences" */
export type Email_Drip_Sequences_Pk_Columns_Input = {
  title: Scalars['String'];
};

/** select columns of table "email_drip_sequences" */
export enum Email_Drip_Sequences_Select_Column {
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "email_drip_sequences" */
export type Email_Drip_Sequences_Set_Input = {
  title?: Maybe<Scalars['String']>;
};

/** update columns of table "email_drip_sequences" */
export enum Email_Drip_Sequences_Update_Column {
  /** column name */
  Title = 'title'
}

/** columns and relationships of "enrollment_transfers" */
export type Enrollment_Transfers = {
  __typename?: 'enrollment_transfers';
  /** An object relationship */
  registration?: Maybe<Registrations>;
  registration_email: Scalars['String'];
  registration_training: Scalars['String'];
  /** An object relationship */
  training: Trainings;
  transfer_email?: Maybe<Scalars['String']>;
  transfer_training: Scalars['String'];
};

/** aggregated selection of "enrollment_transfers" */
export type Enrollment_Transfers_Aggregate = {
  __typename?: 'enrollment_transfers_aggregate';
  aggregate?: Maybe<Enrollment_Transfers_Aggregate_Fields>;
  nodes: Array<Enrollment_Transfers>;
};

/** aggregate fields of "enrollment_transfers" */
export type Enrollment_Transfers_Aggregate_Fields = {
  __typename?: 'enrollment_transfers_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Enrollment_Transfers_Max_Fields>;
  min?: Maybe<Enrollment_Transfers_Min_Fields>;
};


/** aggregate fields of "enrollment_transfers" */
export type Enrollment_Transfers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enrollment_Transfers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "enrollment_transfers" */
export type Enrollment_Transfers_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Enrollment_Transfers_Max_Order_By>;
  min?: Maybe<Enrollment_Transfers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "enrollment_transfers" */
export type Enrollment_Transfers_Arr_Rel_Insert_Input = {
  data: Array<Enrollment_Transfers_Insert_Input>;
  on_conflict?: Maybe<Enrollment_Transfers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "enrollment_transfers". All fields are combined with a logical 'AND'. */
export type Enrollment_Transfers_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Enrollment_Transfers_Bool_Exp>>>;
  _not?: Maybe<Enrollment_Transfers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Enrollment_Transfers_Bool_Exp>>>;
  registration?: Maybe<Registrations_Bool_Exp>;
  registration_email?: Maybe<String_Comparison_Exp>;
  registration_training?: Maybe<String_Comparison_Exp>;
  training?: Maybe<Trainings_Bool_Exp>;
  transfer_email?: Maybe<String_Comparison_Exp>;
  transfer_training?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enrollment_transfers" */
export enum Enrollment_Transfers_Constraint {
  /** unique or primary key constraint */
  EnrollmentTransfersPkey = 'enrollment_transfers_pkey'
}

/** input type for inserting data into table "enrollment_transfers" */
export type Enrollment_Transfers_Insert_Input = {
  registration?: Maybe<Registrations_Obj_Rel_Insert_Input>;
  registration_email?: Maybe<Scalars['String']>;
  registration_training?: Maybe<Scalars['String']>;
  training?: Maybe<Trainings_Obj_Rel_Insert_Input>;
  transfer_email?: Maybe<Scalars['String']>;
  transfer_training?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enrollment_Transfers_Max_Fields = {
  __typename?: 'enrollment_transfers_max_fields';
  registration_email?: Maybe<Scalars['String']>;
  registration_training?: Maybe<Scalars['String']>;
  transfer_email?: Maybe<Scalars['String']>;
  transfer_training?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "enrollment_transfers" */
export type Enrollment_Transfers_Max_Order_By = {
  registration_email?: Maybe<Order_By>;
  registration_training?: Maybe<Order_By>;
  transfer_email?: Maybe<Order_By>;
  transfer_training?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Enrollment_Transfers_Min_Fields = {
  __typename?: 'enrollment_transfers_min_fields';
  registration_email?: Maybe<Scalars['String']>;
  registration_training?: Maybe<Scalars['String']>;
  transfer_email?: Maybe<Scalars['String']>;
  transfer_training?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "enrollment_transfers" */
export type Enrollment_Transfers_Min_Order_By = {
  registration_email?: Maybe<Order_By>;
  registration_training?: Maybe<Order_By>;
  transfer_email?: Maybe<Order_By>;
  transfer_training?: Maybe<Order_By>;
};

/** response of any mutation on the table "enrollment_transfers" */
export type Enrollment_Transfers_Mutation_Response = {
  __typename?: 'enrollment_transfers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Enrollment_Transfers>;
};

/** input type for inserting object relation for remote table "enrollment_transfers" */
export type Enrollment_Transfers_Obj_Rel_Insert_Input = {
  data: Enrollment_Transfers_Insert_Input;
  on_conflict?: Maybe<Enrollment_Transfers_On_Conflict>;
};

/** on conflict condition type for table "enrollment_transfers" */
export type Enrollment_Transfers_On_Conflict = {
  constraint: Enrollment_Transfers_Constraint;
  update_columns: Array<Enrollment_Transfers_Update_Column>;
  where?: Maybe<Enrollment_Transfers_Bool_Exp>;
};

/** ordering options when selecting data from "enrollment_transfers" */
export type Enrollment_Transfers_Order_By = {
  registration?: Maybe<Registrations_Order_By>;
  registration_email?: Maybe<Order_By>;
  registration_training?: Maybe<Order_By>;
  training?: Maybe<Trainings_Order_By>;
  transfer_email?: Maybe<Order_By>;
  transfer_training?: Maybe<Order_By>;
};

/** primary key columns input for table: "enrollment_transfers" */
export type Enrollment_Transfers_Pk_Columns_Input = {
  registration_email: Scalars['String'];
  registration_training: Scalars['String'];
  transfer_training: Scalars['String'];
};

/** select columns of table "enrollment_transfers" */
export enum Enrollment_Transfers_Select_Column {
  /** column name */
  RegistrationEmail = 'registration_email',
  /** column name */
  RegistrationTraining = 'registration_training',
  /** column name */
  TransferEmail = 'transfer_email',
  /** column name */
  TransferTraining = 'transfer_training'
}

/** input type for updating data in table "enrollment_transfers" */
export type Enrollment_Transfers_Set_Input = {
  registration_email?: Maybe<Scalars['String']>;
  registration_training?: Maybe<Scalars['String']>;
  transfer_email?: Maybe<Scalars['String']>;
  transfer_training?: Maybe<Scalars['String']>;
};

/** update columns of table "enrollment_transfers" */
export enum Enrollment_Transfers_Update_Column {
  /** column name */
  RegistrationEmail = 'registration_email',
  /** column name */
  RegistrationTraining = 'registration_training',
  /** column name */
  TransferEmail = 'transfer_email',
  /** column name */
  TransferTraining = 'transfer_training'
}


/** expression to compare columns of type json. All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};


/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "commission_structures" */
  delete_commission_structures?: Maybe<Commission_Structures_Mutation_Response>;
  /** delete single row from the table: "commission_structures" */
  delete_commission_structures_by_pk?: Maybe<Commission_Structures>;
  /** delete data from the table: "coupon_discount_types" */
  delete_coupon_discount_types?: Maybe<Coupon_Discount_Types_Mutation_Response>;
  /** delete single row from the table: "coupon_discount_types" */
  delete_coupon_discount_types_by_pk?: Maybe<Coupon_Discount_Types>;
  /** delete data from the table: "coupons" */
  delete_coupons?: Maybe<Coupons_Mutation_Response>;
  /** delete single row from the table: "coupons" */
  delete_coupons_by_pk?: Maybe<Coupons>;
  /** delete data from the table: "email_drip_campaign_cancelation_status" */
  delete_email_drip_campaign_cancelation_status?: Maybe<Email_Drip_Campaign_Cancelation_Status_Mutation_Response>;
  /** delete single row from the table: "email_drip_campaign_cancelation_status" */
  delete_email_drip_campaign_cancelation_status_by_pk?: Maybe<Email_Drip_Campaign_Cancelation_Status>;
  /** delete data from the table: "email_drip_campaign_goal_types" */
  delete_email_drip_campaign_goal_types?: Maybe<Email_Drip_Campaign_Goal_Types_Mutation_Response>;
  /** delete single row from the table: "email_drip_campaign_goal_types" */
  delete_email_drip_campaign_goal_types_by_pk?: Maybe<Email_Drip_Campaign_Goal_Types>;
  /** delete data from the table: "email_drip_campaign_send_after_references" */
  delete_email_drip_campaign_send_after_references?: Maybe<Email_Drip_Campaign_Send_After_References_Mutation_Response>;
  /** delete single row from the table: "email_drip_campaign_send_after_references" */
  delete_email_drip_campaign_send_after_references_by_pk?: Maybe<Email_Drip_Campaign_Send_After_References>;
  /** delete data from the table: "email_drip_campaign_send_opens" */
  delete_email_drip_campaign_send_opens?: Maybe<Email_Drip_Campaign_Send_Opens_Mutation_Response>;
  /** delete single row from the table: "email_drip_campaign_send_opens" */
  delete_email_drip_campaign_send_opens_by_pk?: Maybe<Email_Drip_Campaign_Send_Opens>;
  /** delete data from the table: "email_drip_campaign_sends" */
  delete_email_drip_campaign_sends?: Maybe<Email_Drip_Campaign_Sends_Mutation_Response>;
  /** delete single row from the table: "email_drip_campaign_sends" */
  delete_email_drip_campaign_sends_by_pk?: Maybe<Email_Drip_Campaign_Sends>;
  /** delete data from the table: "email_drip_campaigns" */
  delete_email_drip_campaigns?: Maybe<Email_Drip_Campaigns_Mutation_Response>;
  /** delete single row from the table: "email_drip_campaigns" */
  delete_email_drip_campaigns_by_pk?: Maybe<Email_Drip_Campaigns>;
  /** delete data from the table: "email_drip_sequence_steps" */
  delete_email_drip_sequence_steps?: Maybe<Email_Drip_Sequence_Steps_Mutation_Response>;
  /** delete single row from the table: "email_drip_sequence_steps" */
  delete_email_drip_sequence_steps_by_pk?: Maybe<Email_Drip_Sequence_Steps>;
  /** delete data from the table: "email_drip_sequences" */
  delete_email_drip_sequences?: Maybe<Email_Drip_Sequences_Mutation_Response>;
  /** delete single row from the table: "email_drip_sequences" */
  delete_email_drip_sequences_by_pk?: Maybe<Email_Drip_Sequences>;
  /** delete data from the table: "enrollment_transfers" */
  delete_enrollment_transfers?: Maybe<Enrollment_Transfers_Mutation_Response>;
  /** delete single row from the table: "enrollment_transfers" */
  delete_enrollment_transfers_by_pk?: Maybe<Enrollment_Transfers>;
  /** delete data from the table: "practitioners" */
  delete_practitioners?: Maybe<Practitioners_Mutation_Response>;
  /** delete single row from the table: "practitioners" */
  delete_practitioners_by_pk?: Maybe<Practitioners>;
  /** delete data from the table: "registration_forms" */
  delete_registration_forms?: Maybe<Registration_Forms_Mutation_Response>;
  /** delete single row from the table: "registration_forms" */
  delete_registration_forms_by_pk?: Maybe<Registration_Forms>;
  /** delete data from the table: "registration_payments" */
  delete_registration_payments?: Maybe<Registration_Payments_Mutation_Response>;
  /** delete single row from the table: "registration_payments" */
  delete_registration_payments_by_pk?: Maybe<Registration_Payments>;
  /** delete data from the table: "registrations" */
  delete_registrations?: Maybe<Registrations_Mutation_Response>;
  /** delete single row from the table: "registrations" */
  delete_registrations_by_pk?: Maybe<Registrations>;
  /** delete data from the table: "scheduled_registration_payments" */
  delete_scheduled_registration_payments?: Maybe<Scheduled_Registration_Payments_Mutation_Response>;
  /** delete single row from the table: "scheduled_registration_payments" */
  delete_scheduled_registration_payments_by_pk?: Maybe<Scheduled_Registration_Payments>;
  /** delete data from the table: "shopify_webhooks" */
  delete_shopify_webhooks?: Maybe<Shopify_Webhooks_Mutation_Response>;
  /** delete single row from the table: "shopify_webhooks" */
  delete_shopify_webhooks_by_pk?: Maybe<Shopify_Webhooks>;
  /** delete data from the table: "stripe_webhooks" */
  delete_stripe_webhooks?: Maybe<Stripe_Webhooks_Mutation_Response>;
  /** delete single row from the table: "stripe_webhooks" */
  delete_stripe_webhooks_by_pk?: Maybe<Stripe_Webhooks>;
  /** delete data from the table: "trainings" */
  delete_trainings?: Maybe<Trainings_Mutation_Response>;
  /** delete single row from the table: "trainings" */
  delete_trainings_by_pk?: Maybe<Trainings>;
  /** perform the action: "emailDripCampaignOptOut" */
  emailDripCampaignOptOut: EmailDripCampaignOptOutResult;
  /** insert data into the table: "commission_structures" */
  insert_commission_structures?: Maybe<Commission_Structures_Mutation_Response>;
  /** insert a single row into the table: "commission_structures" */
  insert_commission_structures_one?: Maybe<Commission_Structures>;
  /** insert data into the table: "coupon_discount_types" */
  insert_coupon_discount_types?: Maybe<Coupon_Discount_Types_Mutation_Response>;
  /** insert a single row into the table: "coupon_discount_types" */
  insert_coupon_discount_types_one?: Maybe<Coupon_Discount_Types>;
  /** insert data into the table: "coupons" */
  insert_coupons?: Maybe<Coupons_Mutation_Response>;
  /** insert a single row into the table: "coupons" */
  insert_coupons_one?: Maybe<Coupons>;
  /** insert data into the table: "email_drip_campaign_cancelation_status" */
  insert_email_drip_campaign_cancelation_status?: Maybe<Email_Drip_Campaign_Cancelation_Status_Mutation_Response>;
  /** insert a single row into the table: "email_drip_campaign_cancelation_status" */
  insert_email_drip_campaign_cancelation_status_one?: Maybe<Email_Drip_Campaign_Cancelation_Status>;
  /** insert data into the table: "email_drip_campaign_goal_types" */
  insert_email_drip_campaign_goal_types?: Maybe<Email_Drip_Campaign_Goal_Types_Mutation_Response>;
  /** insert a single row into the table: "email_drip_campaign_goal_types" */
  insert_email_drip_campaign_goal_types_one?: Maybe<Email_Drip_Campaign_Goal_Types>;
  /** insert data into the table: "email_drip_campaign_send_after_references" */
  insert_email_drip_campaign_send_after_references?: Maybe<Email_Drip_Campaign_Send_After_References_Mutation_Response>;
  /** insert a single row into the table: "email_drip_campaign_send_after_references" */
  insert_email_drip_campaign_send_after_references_one?: Maybe<Email_Drip_Campaign_Send_After_References>;
  /** insert data into the table: "email_drip_campaign_send_opens" */
  insert_email_drip_campaign_send_opens?: Maybe<Email_Drip_Campaign_Send_Opens_Mutation_Response>;
  /** insert a single row into the table: "email_drip_campaign_send_opens" */
  insert_email_drip_campaign_send_opens_one?: Maybe<Email_Drip_Campaign_Send_Opens>;
  /** insert data into the table: "email_drip_campaign_sends" */
  insert_email_drip_campaign_sends?: Maybe<Email_Drip_Campaign_Sends_Mutation_Response>;
  /** insert a single row into the table: "email_drip_campaign_sends" */
  insert_email_drip_campaign_sends_one?: Maybe<Email_Drip_Campaign_Sends>;
  /** insert data into the table: "email_drip_campaigns" */
  insert_email_drip_campaigns?: Maybe<Email_Drip_Campaigns_Mutation_Response>;
  /** insert a single row into the table: "email_drip_campaigns" */
  insert_email_drip_campaigns_one?: Maybe<Email_Drip_Campaigns>;
  /** insert data into the table: "email_drip_sequence_steps" */
  insert_email_drip_sequence_steps?: Maybe<Email_Drip_Sequence_Steps_Mutation_Response>;
  /** insert a single row into the table: "email_drip_sequence_steps" */
  insert_email_drip_sequence_steps_one?: Maybe<Email_Drip_Sequence_Steps>;
  /** insert data into the table: "email_drip_sequences" */
  insert_email_drip_sequences?: Maybe<Email_Drip_Sequences_Mutation_Response>;
  /** insert a single row into the table: "email_drip_sequences" */
  insert_email_drip_sequences_one?: Maybe<Email_Drip_Sequences>;
  /** insert data into the table: "enrollment_transfers" */
  insert_enrollment_transfers?: Maybe<Enrollment_Transfers_Mutation_Response>;
  /** insert a single row into the table: "enrollment_transfers" */
  insert_enrollment_transfers_one?: Maybe<Enrollment_Transfers>;
  /** insert data into the table: "practitioners" */
  insert_practitioners?: Maybe<Practitioners_Mutation_Response>;
  /** insert a single row into the table: "practitioners" */
  insert_practitioners_one?: Maybe<Practitioners>;
  /** insert data into the table: "registration_forms" */
  insert_registration_forms?: Maybe<Registration_Forms_Mutation_Response>;
  /** insert a single row into the table: "registration_forms" */
  insert_registration_forms_one?: Maybe<Registration_Forms>;
  /** insert data into the table: "registration_payments" */
  insert_registration_payments?: Maybe<Registration_Payments_Mutation_Response>;
  /** insert a single row into the table: "registration_payments" */
  insert_registration_payments_one?: Maybe<Registration_Payments>;
  /** insert data into the table: "registrations" */
  insert_registrations?: Maybe<Registrations_Mutation_Response>;
  /** insert a single row into the table: "registrations" */
  insert_registrations_one?: Maybe<Registrations>;
  /** insert data into the table: "scheduled_registration_payments" */
  insert_scheduled_registration_payments?: Maybe<Scheduled_Registration_Payments_Mutation_Response>;
  /** insert a single row into the table: "scheduled_registration_payments" */
  insert_scheduled_registration_payments_one?: Maybe<Scheduled_Registration_Payments>;
  /** insert data into the table: "shopify_webhooks" */
  insert_shopify_webhooks?: Maybe<Shopify_Webhooks_Mutation_Response>;
  /** insert a single row into the table: "shopify_webhooks" */
  insert_shopify_webhooks_one?: Maybe<Shopify_Webhooks>;
  /** insert data into the table: "stripe_webhooks" */
  insert_stripe_webhooks?: Maybe<Stripe_Webhooks_Mutation_Response>;
  /** insert a single row into the table: "stripe_webhooks" */
  insert_stripe_webhooks_one?: Maybe<Stripe_Webhooks>;
  /** insert data into the table: "trainings" */
  insert_trainings?: Maybe<Trainings_Mutation_Response>;
  /** insert a single row into the table: "trainings" */
  insert_trainings_one?: Maybe<Trainings>;
  /** perform the action: "register" */
  register?: Maybe<RegistrationResult>;
  /** update data of the table: "commission_structures" */
  update_commission_structures?: Maybe<Commission_Structures_Mutation_Response>;
  /** update single row of the table: "commission_structures" */
  update_commission_structures_by_pk?: Maybe<Commission_Structures>;
  /** update data of the table: "coupon_discount_types" */
  update_coupon_discount_types?: Maybe<Coupon_Discount_Types_Mutation_Response>;
  /** update single row of the table: "coupon_discount_types" */
  update_coupon_discount_types_by_pk?: Maybe<Coupon_Discount_Types>;
  /** update data of the table: "coupons" */
  update_coupons?: Maybe<Coupons_Mutation_Response>;
  /** update single row of the table: "coupons" */
  update_coupons_by_pk?: Maybe<Coupons>;
  /** update data of the table: "email_drip_campaign_cancelation_status" */
  update_email_drip_campaign_cancelation_status?: Maybe<Email_Drip_Campaign_Cancelation_Status_Mutation_Response>;
  /** update single row of the table: "email_drip_campaign_cancelation_status" */
  update_email_drip_campaign_cancelation_status_by_pk?: Maybe<Email_Drip_Campaign_Cancelation_Status>;
  /** update data of the table: "email_drip_campaign_goal_types" */
  update_email_drip_campaign_goal_types?: Maybe<Email_Drip_Campaign_Goal_Types_Mutation_Response>;
  /** update single row of the table: "email_drip_campaign_goal_types" */
  update_email_drip_campaign_goal_types_by_pk?: Maybe<Email_Drip_Campaign_Goal_Types>;
  /** update data of the table: "email_drip_campaign_send_after_references" */
  update_email_drip_campaign_send_after_references?: Maybe<Email_Drip_Campaign_Send_After_References_Mutation_Response>;
  /** update single row of the table: "email_drip_campaign_send_after_references" */
  update_email_drip_campaign_send_after_references_by_pk?: Maybe<Email_Drip_Campaign_Send_After_References>;
  /** update data of the table: "email_drip_campaign_send_opens" */
  update_email_drip_campaign_send_opens?: Maybe<Email_Drip_Campaign_Send_Opens_Mutation_Response>;
  /** update single row of the table: "email_drip_campaign_send_opens" */
  update_email_drip_campaign_send_opens_by_pk?: Maybe<Email_Drip_Campaign_Send_Opens>;
  /** update data of the table: "email_drip_campaign_sends" */
  update_email_drip_campaign_sends?: Maybe<Email_Drip_Campaign_Sends_Mutation_Response>;
  /** update single row of the table: "email_drip_campaign_sends" */
  update_email_drip_campaign_sends_by_pk?: Maybe<Email_Drip_Campaign_Sends>;
  /** update data of the table: "email_drip_campaigns" */
  update_email_drip_campaigns?: Maybe<Email_Drip_Campaigns_Mutation_Response>;
  /** update single row of the table: "email_drip_campaigns" */
  update_email_drip_campaigns_by_pk?: Maybe<Email_Drip_Campaigns>;
  /** update data of the table: "email_drip_sequence_steps" */
  update_email_drip_sequence_steps?: Maybe<Email_Drip_Sequence_Steps_Mutation_Response>;
  /** update single row of the table: "email_drip_sequence_steps" */
  update_email_drip_sequence_steps_by_pk?: Maybe<Email_Drip_Sequence_Steps>;
  /** update data of the table: "email_drip_sequences" */
  update_email_drip_sequences?: Maybe<Email_Drip_Sequences_Mutation_Response>;
  /** update single row of the table: "email_drip_sequences" */
  update_email_drip_sequences_by_pk?: Maybe<Email_Drip_Sequences>;
  /** update data of the table: "enrollment_transfers" */
  update_enrollment_transfers?: Maybe<Enrollment_Transfers_Mutation_Response>;
  /** update single row of the table: "enrollment_transfers" */
  update_enrollment_transfers_by_pk?: Maybe<Enrollment_Transfers>;
  /** update data of the table: "practitioners" */
  update_practitioners?: Maybe<Practitioners_Mutation_Response>;
  /** update single row of the table: "practitioners" */
  update_practitioners_by_pk?: Maybe<Practitioners>;
  /** update data of the table: "registration_forms" */
  update_registration_forms?: Maybe<Registration_Forms_Mutation_Response>;
  /** update single row of the table: "registration_forms" */
  update_registration_forms_by_pk?: Maybe<Registration_Forms>;
  /** update data of the table: "registration_payments" */
  update_registration_payments?: Maybe<Registration_Payments_Mutation_Response>;
  /** update single row of the table: "registration_payments" */
  update_registration_payments_by_pk?: Maybe<Registration_Payments>;
  /** update data of the table: "registrations" */
  update_registrations?: Maybe<Registrations_Mutation_Response>;
  /** update single row of the table: "registrations" */
  update_registrations_by_pk?: Maybe<Registrations>;
  /** update data of the table: "scheduled_registration_payments" */
  update_scheduled_registration_payments?: Maybe<Scheduled_Registration_Payments_Mutation_Response>;
  /** update single row of the table: "scheduled_registration_payments" */
  update_scheduled_registration_payments_by_pk?: Maybe<Scheduled_Registration_Payments>;
  /** update data of the table: "shopify_webhooks" */
  update_shopify_webhooks?: Maybe<Shopify_Webhooks_Mutation_Response>;
  /** update single row of the table: "shopify_webhooks" */
  update_shopify_webhooks_by_pk?: Maybe<Shopify_Webhooks>;
  /** update data of the table: "stripe_webhooks" */
  update_stripe_webhooks?: Maybe<Stripe_Webhooks_Mutation_Response>;
  /** update single row of the table: "stripe_webhooks" */
  update_stripe_webhooks_by_pk?: Maybe<Stripe_Webhooks>;
  /** update data of the table: "trainings" */
  update_trainings?: Maybe<Trainings_Mutation_Response>;
  /** update single row of the table: "trainings" */
  update_trainings_by_pk?: Maybe<Trainings>;
};


/** mutation root */
export type Mutation_RootDelete_Commission_StructuresArgs = {
  where: Commission_Structures_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Commission_Structures_By_PkArgs = {
  identifier: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Coupon_Discount_TypesArgs = {
  where: Coupon_Discount_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Coupon_Discount_Types_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CouponsArgs = {
  where: Coupons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Coupons_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Email_Drip_Campaign_Cancelation_StatusArgs = {
  where: Email_Drip_Campaign_Cancelation_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Drip_Campaign_Cancelation_Status_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Email_Drip_Campaign_Goal_TypesArgs = {
  where: Email_Drip_Campaign_Goal_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Drip_Campaign_Goal_Types_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Email_Drip_Campaign_Send_After_ReferencesArgs = {
  where: Email_Drip_Campaign_Send_After_References_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Drip_Campaign_Send_After_References_By_PkArgs = {
  title: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Email_Drip_Campaign_Send_OpensArgs = {
  where: Email_Drip_Campaign_Send_Opens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Drip_Campaign_Send_Opens_By_PkArgs = {
  timestamp: Scalars['timestamptz'];
};


/** mutation root */
export type Mutation_RootDelete_Email_Drip_Campaign_SendsArgs = {
  where: Email_Drip_Campaign_Sends_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Drip_Campaign_Sends_By_PkArgs = {
  email_drip_campaign_email: Scalars['String'];
  email_drip_sequence_step: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Email_Drip_CampaignsArgs = {
  where: Email_Drip_Campaigns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Drip_Campaigns_By_PkArgs = {
  email: Scalars['String'];
  email_drip_sequence: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Email_Drip_Sequence_StepsArgs = {
  where: Email_Drip_Sequence_Steps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Drip_Sequence_Steps_By_PkArgs = {
  title: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Email_Drip_SequencesArgs = {
  where: Email_Drip_Sequences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Drip_Sequences_By_PkArgs = {
  title: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enrollment_TransfersArgs = {
  where: Enrollment_Transfers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enrollment_Transfers_By_PkArgs = {
  registration_email: Scalars['String'];
  registration_training: Scalars['String'];
  transfer_training: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_PractitionersArgs = {
  where: Practitioners_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Practitioners_By_PkArgs = {
  shopify_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Registration_FormsArgs = {
  where: Registration_Forms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Registration_Forms_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Registration_PaymentsArgs = {
  where: Registration_Payments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Registration_Payments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RegistrationsArgs = {
  where: Registrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Registrations_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  training: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Scheduled_Registration_PaymentsArgs = {
  where: Scheduled_Registration_Payments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Scheduled_Registration_Payments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Shopify_WebhooksArgs = {
  where: Shopify_Webhooks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Shopify_Webhooks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Stripe_WebhooksArgs = {
  where: Stripe_Webhooks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stripe_Webhooks_By_PkArgs = {
  event_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_TrainingsArgs = {
  where: Trainings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Trainings_By_PkArgs = {
  slug: Scalars['String'];
};


/** mutation root */
export type Mutation_RootEmailDripCampaignOptOutArgs = {
  email: Scalars['String'];
  sequence: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_Commission_StructuresArgs = {
  objects: Array<Commission_Structures_Insert_Input>;
  on_conflict?: Maybe<Commission_Structures_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Commission_Structures_OneArgs = {
  object: Commission_Structures_Insert_Input;
  on_conflict?: Maybe<Commission_Structures_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Coupon_Discount_TypesArgs = {
  objects: Array<Coupon_Discount_Types_Insert_Input>;
  on_conflict?: Maybe<Coupon_Discount_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Coupon_Discount_Types_OneArgs = {
  object: Coupon_Discount_Types_Insert_Input;
  on_conflict?: Maybe<Coupon_Discount_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CouponsArgs = {
  objects: Array<Coupons_Insert_Input>;
  on_conflict?: Maybe<Coupons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Coupons_OneArgs = {
  object: Coupons_Insert_Input;
  on_conflict?: Maybe<Coupons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Drip_Campaign_Cancelation_StatusArgs = {
  objects: Array<Email_Drip_Campaign_Cancelation_Status_Insert_Input>;
  on_conflict?: Maybe<Email_Drip_Campaign_Cancelation_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Drip_Campaign_Cancelation_Status_OneArgs = {
  object: Email_Drip_Campaign_Cancelation_Status_Insert_Input;
  on_conflict?: Maybe<Email_Drip_Campaign_Cancelation_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Drip_Campaign_Goal_TypesArgs = {
  objects: Array<Email_Drip_Campaign_Goal_Types_Insert_Input>;
  on_conflict?: Maybe<Email_Drip_Campaign_Goal_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Drip_Campaign_Goal_Types_OneArgs = {
  object: Email_Drip_Campaign_Goal_Types_Insert_Input;
  on_conflict?: Maybe<Email_Drip_Campaign_Goal_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Drip_Campaign_Send_After_ReferencesArgs = {
  objects: Array<Email_Drip_Campaign_Send_After_References_Insert_Input>;
  on_conflict?: Maybe<Email_Drip_Campaign_Send_After_References_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Drip_Campaign_Send_After_References_OneArgs = {
  object: Email_Drip_Campaign_Send_After_References_Insert_Input;
  on_conflict?: Maybe<Email_Drip_Campaign_Send_After_References_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Drip_Campaign_Send_OpensArgs = {
  objects: Array<Email_Drip_Campaign_Send_Opens_Insert_Input>;
  on_conflict?: Maybe<Email_Drip_Campaign_Send_Opens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Drip_Campaign_Send_Opens_OneArgs = {
  object: Email_Drip_Campaign_Send_Opens_Insert_Input;
  on_conflict?: Maybe<Email_Drip_Campaign_Send_Opens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Drip_Campaign_SendsArgs = {
  objects: Array<Email_Drip_Campaign_Sends_Insert_Input>;
  on_conflict?: Maybe<Email_Drip_Campaign_Sends_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Drip_Campaign_Sends_OneArgs = {
  object: Email_Drip_Campaign_Sends_Insert_Input;
  on_conflict?: Maybe<Email_Drip_Campaign_Sends_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Drip_CampaignsArgs = {
  objects: Array<Email_Drip_Campaigns_Insert_Input>;
  on_conflict?: Maybe<Email_Drip_Campaigns_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Drip_Campaigns_OneArgs = {
  object: Email_Drip_Campaigns_Insert_Input;
  on_conflict?: Maybe<Email_Drip_Campaigns_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Drip_Sequence_StepsArgs = {
  objects: Array<Email_Drip_Sequence_Steps_Insert_Input>;
  on_conflict?: Maybe<Email_Drip_Sequence_Steps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Drip_Sequence_Steps_OneArgs = {
  object: Email_Drip_Sequence_Steps_Insert_Input;
  on_conflict?: Maybe<Email_Drip_Sequence_Steps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Drip_SequencesArgs = {
  objects: Array<Email_Drip_Sequences_Insert_Input>;
  on_conflict?: Maybe<Email_Drip_Sequences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Drip_Sequences_OneArgs = {
  object: Email_Drip_Sequences_Insert_Input;
  on_conflict?: Maybe<Email_Drip_Sequences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enrollment_TransfersArgs = {
  objects: Array<Enrollment_Transfers_Insert_Input>;
  on_conflict?: Maybe<Enrollment_Transfers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enrollment_Transfers_OneArgs = {
  object: Enrollment_Transfers_Insert_Input;
  on_conflict?: Maybe<Enrollment_Transfers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PractitionersArgs = {
  objects: Array<Practitioners_Insert_Input>;
  on_conflict?: Maybe<Practitioners_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Practitioners_OneArgs = {
  object: Practitioners_Insert_Input;
  on_conflict?: Maybe<Practitioners_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Registration_FormsArgs = {
  objects: Array<Registration_Forms_Insert_Input>;
  on_conflict?: Maybe<Registration_Forms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Registration_Forms_OneArgs = {
  object: Registration_Forms_Insert_Input;
  on_conflict?: Maybe<Registration_Forms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Registration_PaymentsArgs = {
  objects: Array<Registration_Payments_Insert_Input>;
  on_conflict?: Maybe<Registration_Payments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Registration_Payments_OneArgs = {
  object: Registration_Payments_Insert_Input;
  on_conflict?: Maybe<Registration_Payments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RegistrationsArgs = {
  objects: Array<Registrations_Insert_Input>;
  on_conflict?: Maybe<Registrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Registrations_OneArgs = {
  object: Registrations_Insert_Input;
  on_conflict?: Maybe<Registrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scheduled_Registration_PaymentsArgs = {
  objects: Array<Scheduled_Registration_Payments_Insert_Input>;
  on_conflict?: Maybe<Scheduled_Registration_Payments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scheduled_Registration_Payments_OneArgs = {
  object: Scheduled_Registration_Payments_Insert_Input;
  on_conflict?: Maybe<Scheduled_Registration_Payments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Shopify_WebhooksArgs = {
  objects: Array<Shopify_Webhooks_Insert_Input>;
  on_conflict?: Maybe<Shopify_Webhooks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Shopify_Webhooks_OneArgs = {
  object: Shopify_Webhooks_Insert_Input;
  on_conflict?: Maybe<Shopify_Webhooks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_WebhooksArgs = {
  objects: Array<Stripe_Webhooks_Insert_Input>;
  on_conflict?: Maybe<Stripe_Webhooks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stripe_Webhooks_OneArgs = {
  object: Stripe_Webhooks_Insert_Input;
  on_conflict?: Maybe<Stripe_Webhooks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TrainingsArgs = {
  objects: Array<Trainings_Insert_Input>;
  on_conflict?: Maybe<Trainings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trainings_OneArgs = {
  object: Trainings_Insert_Input;
  on_conflict?: Maybe<Trainings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootRegisterArgs = {
  formData: Scalars['json'];
  payInFull: Scalars['Boolean'];
  registrationData: RegistrationDataInput;
  trackingId?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_StructuresArgs = {
  _set?: Maybe<Commission_Structures_Set_Input>;
  where: Commission_Structures_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Commission_Structures_By_PkArgs = {
  _set?: Maybe<Commission_Structures_Set_Input>;
  pk_columns: Commission_Structures_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Coupon_Discount_TypesArgs = {
  _set?: Maybe<Coupon_Discount_Types_Set_Input>;
  where: Coupon_Discount_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Coupon_Discount_Types_By_PkArgs = {
  _set?: Maybe<Coupon_Discount_Types_Set_Input>;
  pk_columns: Coupon_Discount_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CouponsArgs = {
  _append?: Maybe<Coupons_Append_Input>;
  _delete_at_path?: Maybe<Coupons_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Coupons_Delete_Elem_Input>;
  _delete_key?: Maybe<Coupons_Delete_Key_Input>;
  _inc?: Maybe<Coupons_Inc_Input>;
  _prepend?: Maybe<Coupons_Prepend_Input>;
  _set?: Maybe<Coupons_Set_Input>;
  where: Coupons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Coupons_By_PkArgs = {
  _append?: Maybe<Coupons_Append_Input>;
  _delete_at_path?: Maybe<Coupons_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Coupons_Delete_Elem_Input>;
  _delete_key?: Maybe<Coupons_Delete_Key_Input>;
  _inc?: Maybe<Coupons_Inc_Input>;
  _prepend?: Maybe<Coupons_Prepend_Input>;
  _set?: Maybe<Coupons_Set_Input>;
  pk_columns: Coupons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Drip_Campaign_Cancelation_StatusArgs = {
  _set?: Maybe<Email_Drip_Campaign_Cancelation_Status_Set_Input>;
  where: Email_Drip_Campaign_Cancelation_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Drip_Campaign_Cancelation_Status_By_PkArgs = {
  _set?: Maybe<Email_Drip_Campaign_Cancelation_Status_Set_Input>;
  pk_columns: Email_Drip_Campaign_Cancelation_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Drip_Campaign_Goal_TypesArgs = {
  _set?: Maybe<Email_Drip_Campaign_Goal_Types_Set_Input>;
  where: Email_Drip_Campaign_Goal_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Drip_Campaign_Goal_Types_By_PkArgs = {
  _set?: Maybe<Email_Drip_Campaign_Goal_Types_Set_Input>;
  pk_columns: Email_Drip_Campaign_Goal_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Drip_Campaign_Send_After_ReferencesArgs = {
  _set?: Maybe<Email_Drip_Campaign_Send_After_References_Set_Input>;
  where: Email_Drip_Campaign_Send_After_References_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Drip_Campaign_Send_After_References_By_PkArgs = {
  _set?: Maybe<Email_Drip_Campaign_Send_After_References_Set_Input>;
  pk_columns: Email_Drip_Campaign_Send_After_References_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Drip_Campaign_Send_OpensArgs = {
  _set?: Maybe<Email_Drip_Campaign_Send_Opens_Set_Input>;
  where: Email_Drip_Campaign_Send_Opens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Drip_Campaign_Send_Opens_By_PkArgs = {
  _set?: Maybe<Email_Drip_Campaign_Send_Opens_Set_Input>;
  pk_columns: Email_Drip_Campaign_Send_Opens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Drip_Campaign_SendsArgs = {
  _append?: Maybe<Email_Drip_Campaign_Sends_Append_Input>;
  _delete_at_path?: Maybe<Email_Drip_Campaign_Sends_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Email_Drip_Campaign_Sends_Delete_Elem_Input>;
  _delete_key?: Maybe<Email_Drip_Campaign_Sends_Delete_Key_Input>;
  _prepend?: Maybe<Email_Drip_Campaign_Sends_Prepend_Input>;
  _set?: Maybe<Email_Drip_Campaign_Sends_Set_Input>;
  where: Email_Drip_Campaign_Sends_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Drip_Campaign_Sends_By_PkArgs = {
  _append?: Maybe<Email_Drip_Campaign_Sends_Append_Input>;
  _delete_at_path?: Maybe<Email_Drip_Campaign_Sends_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Email_Drip_Campaign_Sends_Delete_Elem_Input>;
  _delete_key?: Maybe<Email_Drip_Campaign_Sends_Delete_Key_Input>;
  _prepend?: Maybe<Email_Drip_Campaign_Sends_Prepend_Input>;
  _set?: Maybe<Email_Drip_Campaign_Sends_Set_Input>;
  pk_columns: Email_Drip_Campaign_Sends_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Drip_CampaignsArgs = {
  _append?: Maybe<Email_Drip_Campaigns_Append_Input>;
  _delete_at_path?: Maybe<Email_Drip_Campaigns_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Email_Drip_Campaigns_Delete_Elem_Input>;
  _delete_key?: Maybe<Email_Drip_Campaigns_Delete_Key_Input>;
  _prepend?: Maybe<Email_Drip_Campaigns_Prepend_Input>;
  _set?: Maybe<Email_Drip_Campaigns_Set_Input>;
  where: Email_Drip_Campaigns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Drip_Campaigns_By_PkArgs = {
  _append?: Maybe<Email_Drip_Campaigns_Append_Input>;
  _delete_at_path?: Maybe<Email_Drip_Campaigns_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Email_Drip_Campaigns_Delete_Elem_Input>;
  _delete_key?: Maybe<Email_Drip_Campaigns_Delete_Key_Input>;
  _prepend?: Maybe<Email_Drip_Campaigns_Prepend_Input>;
  _set?: Maybe<Email_Drip_Campaigns_Set_Input>;
  pk_columns: Email_Drip_Campaigns_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Drip_Sequence_StepsArgs = {
  _append?: Maybe<Email_Drip_Sequence_Steps_Append_Input>;
  _delete_at_path?: Maybe<Email_Drip_Sequence_Steps_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Email_Drip_Sequence_Steps_Delete_Elem_Input>;
  _delete_key?: Maybe<Email_Drip_Sequence_Steps_Delete_Key_Input>;
  _inc?: Maybe<Email_Drip_Sequence_Steps_Inc_Input>;
  _prepend?: Maybe<Email_Drip_Sequence_Steps_Prepend_Input>;
  _set?: Maybe<Email_Drip_Sequence_Steps_Set_Input>;
  where: Email_Drip_Sequence_Steps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Drip_Sequence_Steps_By_PkArgs = {
  _append?: Maybe<Email_Drip_Sequence_Steps_Append_Input>;
  _delete_at_path?: Maybe<Email_Drip_Sequence_Steps_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Email_Drip_Sequence_Steps_Delete_Elem_Input>;
  _delete_key?: Maybe<Email_Drip_Sequence_Steps_Delete_Key_Input>;
  _inc?: Maybe<Email_Drip_Sequence_Steps_Inc_Input>;
  _prepend?: Maybe<Email_Drip_Sequence_Steps_Prepend_Input>;
  _set?: Maybe<Email_Drip_Sequence_Steps_Set_Input>;
  pk_columns: Email_Drip_Sequence_Steps_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Drip_SequencesArgs = {
  _set?: Maybe<Email_Drip_Sequences_Set_Input>;
  where: Email_Drip_Sequences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Drip_Sequences_By_PkArgs = {
  _set?: Maybe<Email_Drip_Sequences_Set_Input>;
  pk_columns: Email_Drip_Sequences_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enrollment_TransfersArgs = {
  _set?: Maybe<Enrollment_Transfers_Set_Input>;
  where: Enrollment_Transfers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enrollment_Transfers_By_PkArgs = {
  _set?: Maybe<Enrollment_Transfers_Set_Input>;
  pk_columns: Enrollment_Transfers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PractitionersArgs = {
  _inc?: Maybe<Practitioners_Inc_Input>;
  _set?: Maybe<Practitioners_Set_Input>;
  where: Practitioners_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Practitioners_By_PkArgs = {
  _inc?: Maybe<Practitioners_Inc_Input>;
  _set?: Maybe<Practitioners_Set_Input>;
  pk_columns: Practitioners_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Registration_FormsArgs = {
  _inc?: Maybe<Registration_Forms_Inc_Input>;
  _set?: Maybe<Registration_Forms_Set_Input>;
  where: Registration_Forms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Registration_Forms_By_PkArgs = {
  _inc?: Maybe<Registration_Forms_Inc_Input>;
  _set?: Maybe<Registration_Forms_Set_Input>;
  pk_columns: Registration_Forms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Registration_PaymentsArgs = {
  _inc?: Maybe<Registration_Payments_Inc_Input>;
  _set?: Maybe<Registration_Payments_Set_Input>;
  where: Registration_Payments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Registration_Payments_By_PkArgs = {
  _inc?: Maybe<Registration_Payments_Inc_Input>;
  _set?: Maybe<Registration_Payments_Set_Input>;
  pk_columns: Registration_Payments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RegistrationsArgs = {
  _inc?: Maybe<Registrations_Inc_Input>;
  _set?: Maybe<Registrations_Set_Input>;
  where: Registrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Registrations_By_PkArgs = {
  _inc?: Maybe<Registrations_Inc_Input>;
  _set?: Maybe<Registrations_Set_Input>;
  pk_columns: Registrations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Scheduled_Registration_PaymentsArgs = {
  _inc?: Maybe<Scheduled_Registration_Payments_Inc_Input>;
  _set?: Maybe<Scheduled_Registration_Payments_Set_Input>;
  where: Scheduled_Registration_Payments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Scheduled_Registration_Payments_By_PkArgs = {
  _inc?: Maybe<Scheduled_Registration_Payments_Inc_Input>;
  _set?: Maybe<Scheduled_Registration_Payments_Set_Input>;
  pk_columns: Scheduled_Registration_Payments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Shopify_WebhooksArgs = {
  _inc?: Maybe<Shopify_Webhooks_Inc_Input>;
  _set?: Maybe<Shopify_Webhooks_Set_Input>;
  where: Shopify_Webhooks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Shopify_Webhooks_By_PkArgs = {
  _inc?: Maybe<Shopify_Webhooks_Inc_Input>;
  _set?: Maybe<Shopify_Webhooks_Set_Input>;
  pk_columns: Shopify_Webhooks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_WebhooksArgs = {
  _set?: Maybe<Stripe_Webhooks_Set_Input>;
  where: Stripe_Webhooks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stripe_Webhooks_By_PkArgs = {
  _set?: Maybe<Stripe_Webhooks_Set_Input>;
  pk_columns: Stripe_Webhooks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TrainingsArgs = {
  _inc?: Maybe<Trainings_Inc_Input>;
  _set?: Maybe<Trainings_Set_Input>;
  where: Trainings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Trainings_By_PkArgs = {
  _inc?: Maybe<Trainings_Inc_Input>;
  _set?: Maybe<Trainings_Set_Input>;
  pk_columns: Trainings_Pk_Columns_Input;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "practitioners" */
export type Practitioners = {
  __typename?: 'practitioners';
  city: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  profile_image: Scalars['String'];
  shopify_id: Scalars['bigint'];
  state: Scalars['String'];
  tags: Scalars['json'];
};


/** columns and relationships of "practitioners" */
export type PractitionersTagsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "practitioners" */
export type Practitioners_Aggregate = {
  __typename?: 'practitioners_aggregate';
  aggregate?: Maybe<Practitioners_Aggregate_Fields>;
  nodes: Array<Practitioners>;
};

/** aggregate fields of "practitioners" */
export type Practitioners_Aggregate_Fields = {
  __typename?: 'practitioners_aggregate_fields';
  avg?: Maybe<Practitioners_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Practitioners_Max_Fields>;
  min?: Maybe<Practitioners_Min_Fields>;
  stddev?: Maybe<Practitioners_Stddev_Fields>;
  stddev_pop?: Maybe<Practitioners_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Practitioners_Stddev_Samp_Fields>;
  sum?: Maybe<Practitioners_Sum_Fields>;
  var_pop?: Maybe<Practitioners_Var_Pop_Fields>;
  var_samp?: Maybe<Practitioners_Var_Samp_Fields>;
  variance?: Maybe<Practitioners_Variance_Fields>;
};


/** aggregate fields of "practitioners" */
export type Practitioners_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Practitioners_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "practitioners" */
export type Practitioners_Aggregate_Order_By = {
  avg?: Maybe<Practitioners_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Practitioners_Max_Order_By>;
  min?: Maybe<Practitioners_Min_Order_By>;
  stddev?: Maybe<Practitioners_Stddev_Order_By>;
  stddev_pop?: Maybe<Practitioners_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Practitioners_Stddev_Samp_Order_By>;
  sum?: Maybe<Practitioners_Sum_Order_By>;
  var_pop?: Maybe<Practitioners_Var_Pop_Order_By>;
  var_samp?: Maybe<Practitioners_Var_Samp_Order_By>;
  variance?: Maybe<Practitioners_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "practitioners" */
export type Practitioners_Arr_Rel_Insert_Input = {
  data: Array<Practitioners_Insert_Input>;
  on_conflict?: Maybe<Practitioners_On_Conflict>;
};

/** aggregate avg on columns */
export type Practitioners_Avg_Fields = {
  __typename?: 'practitioners_avg_fields';
  shopify_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "practitioners" */
export type Practitioners_Avg_Order_By = {
  shopify_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "practitioners". All fields are combined with a logical 'AND'. */
export type Practitioners_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Practitioners_Bool_Exp>>>;
  _not?: Maybe<Practitioners_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Practitioners_Bool_Exp>>>;
  city?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  profile_image?: Maybe<String_Comparison_Exp>;
  shopify_id?: Maybe<Bigint_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  tags?: Maybe<Json_Comparison_Exp>;
};

/** unique or primary key constraints on table "practitioners" */
export enum Practitioners_Constraint {
  /** unique or primary key constraint */
  PractitionersPkey = 'practitioners_pkey'
}

/** input type for incrementing integer column in table "practitioners" */
export type Practitioners_Inc_Input = {
  shopify_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "practitioners" */
export type Practitioners_Insert_Input = {
  city?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profile_image?: Maybe<Scalars['String']>;
  shopify_id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['json']>;
};

/** aggregate max on columns */
export type Practitioners_Max_Fields = {
  __typename?: 'practitioners_max_fields';
  city?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profile_image?: Maybe<Scalars['String']>;
  shopify_id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "practitioners" */
export type Practitioners_Max_Order_By = {
  city?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  profile_image?: Maybe<Order_By>;
  shopify_id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Practitioners_Min_Fields = {
  __typename?: 'practitioners_min_fields';
  city?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profile_image?: Maybe<Scalars['String']>;
  shopify_id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "practitioners" */
export type Practitioners_Min_Order_By = {
  city?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  profile_image?: Maybe<Order_By>;
  shopify_id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

/** response of any mutation on the table "practitioners" */
export type Practitioners_Mutation_Response = {
  __typename?: 'practitioners_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Practitioners>;
};

/** input type for inserting object relation for remote table "practitioners" */
export type Practitioners_Obj_Rel_Insert_Input = {
  data: Practitioners_Insert_Input;
  on_conflict?: Maybe<Practitioners_On_Conflict>;
};

/** on conflict condition type for table "practitioners" */
export type Practitioners_On_Conflict = {
  constraint: Practitioners_Constraint;
  update_columns: Array<Practitioners_Update_Column>;
  where?: Maybe<Practitioners_Bool_Exp>;
};

/** ordering options when selecting data from "practitioners" */
export type Practitioners_Order_By = {
  city?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  profile_image?: Maybe<Order_By>;
  shopify_id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
};

/** primary key columns input for table: "practitioners" */
export type Practitioners_Pk_Columns_Input = {
  shopify_id: Scalars['bigint'];
};

/** select columns of table "practitioners" */
export enum Practitioners_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Email = 'email',
  /** column name */
  Name = 'name',
  /** column name */
  ProfileImage = 'profile_image',
  /** column name */
  ShopifyId = 'shopify_id',
  /** column name */
  State = 'state',
  /** column name */
  Tags = 'tags'
}

/** input type for updating data in table "practitioners" */
export type Practitioners_Set_Input = {
  city?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profile_image?: Maybe<Scalars['String']>;
  shopify_id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['json']>;
};

/** aggregate stddev on columns */
export type Practitioners_Stddev_Fields = {
  __typename?: 'practitioners_stddev_fields';
  shopify_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "practitioners" */
export type Practitioners_Stddev_Order_By = {
  shopify_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Practitioners_Stddev_Pop_Fields = {
  __typename?: 'practitioners_stddev_pop_fields';
  shopify_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "practitioners" */
export type Practitioners_Stddev_Pop_Order_By = {
  shopify_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Practitioners_Stddev_Samp_Fields = {
  __typename?: 'practitioners_stddev_samp_fields';
  shopify_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "practitioners" */
export type Practitioners_Stddev_Samp_Order_By = {
  shopify_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Practitioners_Sum_Fields = {
  __typename?: 'practitioners_sum_fields';
  shopify_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "practitioners" */
export type Practitioners_Sum_Order_By = {
  shopify_id?: Maybe<Order_By>;
};

/** update columns of table "practitioners" */
export enum Practitioners_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  Email = 'email',
  /** column name */
  Name = 'name',
  /** column name */
  ProfileImage = 'profile_image',
  /** column name */
  ShopifyId = 'shopify_id',
  /** column name */
  State = 'state',
  /** column name */
  Tags = 'tags'
}

/** aggregate var_pop on columns */
export type Practitioners_Var_Pop_Fields = {
  __typename?: 'practitioners_var_pop_fields';
  shopify_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "practitioners" */
export type Practitioners_Var_Pop_Order_By = {
  shopify_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Practitioners_Var_Samp_Fields = {
  __typename?: 'practitioners_var_samp_fields';
  shopify_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "practitioners" */
export type Practitioners_Var_Samp_Order_By = {
  shopify_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Practitioners_Variance_Fields = {
  __typename?: 'practitioners_variance_fields';
  shopify_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "practitioners" */
export type Practitioners_Variance_Order_By = {
  shopify_id?: Maybe<Order_By>;
};

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "commission_structures" */
  commission_structures: Array<Commission_Structures>;
  /** fetch aggregated fields from the table: "commission_structures" */
  commission_structures_aggregate: Commission_Structures_Aggregate;
  /** fetch data from the table: "commission_structures" using primary key columns */
  commission_structures_by_pk?: Maybe<Commission_Structures>;
  /** fetch data from the table: "coupon_discount_types" */
  coupon_discount_types: Array<Coupon_Discount_Types>;
  /** fetch aggregated fields from the table: "coupon_discount_types" */
  coupon_discount_types_aggregate: Coupon_Discount_Types_Aggregate;
  /** fetch data from the table: "coupon_discount_types" using primary key columns */
  coupon_discount_types_by_pk?: Maybe<Coupon_Discount_Types>;
  /** fetch data from the table: "coupons" */
  coupons: Array<Coupons>;
  /** fetch aggregated fields from the table: "coupons" */
  coupons_aggregate: Coupons_Aggregate;
  /** fetch data from the table: "coupons" using primary key columns */
  coupons_by_pk?: Maybe<Coupons>;
  /** fetch data from the table: "email_drip_campaign_cancelation_status" */
  email_drip_campaign_cancelation_status: Array<Email_Drip_Campaign_Cancelation_Status>;
  /** fetch aggregated fields from the table: "email_drip_campaign_cancelation_status" */
  email_drip_campaign_cancelation_status_aggregate: Email_Drip_Campaign_Cancelation_Status_Aggregate;
  /** fetch data from the table: "email_drip_campaign_cancelation_status" using primary key columns */
  email_drip_campaign_cancelation_status_by_pk?: Maybe<Email_Drip_Campaign_Cancelation_Status>;
  /** fetch data from the table: "email_drip_campaign_goal_types" */
  email_drip_campaign_goal_types: Array<Email_Drip_Campaign_Goal_Types>;
  /** fetch aggregated fields from the table: "email_drip_campaign_goal_types" */
  email_drip_campaign_goal_types_aggregate: Email_Drip_Campaign_Goal_Types_Aggregate;
  /** fetch data from the table: "email_drip_campaign_goal_types" using primary key columns */
  email_drip_campaign_goal_types_by_pk?: Maybe<Email_Drip_Campaign_Goal_Types>;
  /** fetch data from the table: "email_drip_campaign_send_after_references" */
  email_drip_campaign_send_after_references: Array<Email_Drip_Campaign_Send_After_References>;
  /** fetch aggregated fields from the table: "email_drip_campaign_send_after_references" */
  email_drip_campaign_send_after_references_aggregate: Email_Drip_Campaign_Send_After_References_Aggregate;
  /** fetch data from the table: "email_drip_campaign_send_after_references" using primary key columns */
  email_drip_campaign_send_after_references_by_pk?: Maybe<Email_Drip_Campaign_Send_After_References>;
  /** fetch data from the table: "email_drip_campaign_send_opens" */
  email_drip_campaign_send_opens: Array<Email_Drip_Campaign_Send_Opens>;
  /** fetch aggregated fields from the table: "email_drip_campaign_send_opens" */
  email_drip_campaign_send_opens_aggregate: Email_Drip_Campaign_Send_Opens_Aggregate;
  /** fetch data from the table: "email_drip_campaign_send_opens" using primary key columns */
  email_drip_campaign_send_opens_by_pk?: Maybe<Email_Drip_Campaign_Send_Opens>;
  /** fetch data from the table: "email_drip_campaign_sends" */
  email_drip_campaign_sends: Array<Email_Drip_Campaign_Sends>;
  /** fetch aggregated fields from the table: "email_drip_campaign_sends" */
  email_drip_campaign_sends_aggregate: Email_Drip_Campaign_Sends_Aggregate;
  /** fetch data from the table: "email_drip_campaign_sends" using primary key columns */
  email_drip_campaign_sends_by_pk?: Maybe<Email_Drip_Campaign_Sends>;
  /** fetch data from the table: "email_drip_campaigns" */
  email_drip_campaigns: Array<Email_Drip_Campaigns>;
  /** fetch aggregated fields from the table: "email_drip_campaigns" */
  email_drip_campaigns_aggregate: Email_Drip_Campaigns_Aggregate;
  /** fetch data from the table: "email_drip_campaigns" using primary key columns */
  email_drip_campaigns_by_pk?: Maybe<Email_Drip_Campaigns>;
  /** fetch data from the table: "email_drip_sequence_steps" */
  email_drip_sequence_steps: Array<Email_Drip_Sequence_Steps>;
  /** fetch aggregated fields from the table: "email_drip_sequence_steps" */
  email_drip_sequence_steps_aggregate: Email_Drip_Sequence_Steps_Aggregate;
  /** fetch data from the table: "email_drip_sequence_steps" using primary key columns */
  email_drip_sequence_steps_by_pk?: Maybe<Email_Drip_Sequence_Steps>;
  /** fetch data from the table: "email_drip_sequences" */
  email_drip_sequences: Array<Email_Drip_Sequences>;
  /** fetch aggregated fields from the table: "email_drip_sequences" */
  email_drip_sequences_aggregate: Email_Drip_Sequences_Aggregate;
  /** fetch data from the table: "email_drip_sequences" using primary key columns */
  email_drip_sequences_by_pk?: Maybe<Email_Drip_Sequences>;
  /** fetch data from the table: "enrollment_transfers" */
  enrollment_transfers: Array<Enrollment_Transfers>;
  /** fetch aggregated fields from the table: "enrollment_transfers" */
  enrollment_transfers_aggregate: Enrollment_Transfers_Aggregate;
  /** fetch data from the table: "enrollment_transfers" using primary key columns */
  enrollment_transfers_by_pk?: Maybe<Enrollment_Transfers>;
  /** fetch data from the table: "practitioners" */
  practitioners: Array<Practitioners>;
  /** fetch aggregated fields from the table: "practitioners" */
  practitioners_aggregate: Practitioners_Aggregate;
  /** fetch data from the table: "practitioners" using primary key columns */
  practitioners_by_pk?: Maybe<Practitioners>;
  /** fetch data from the table: "registration_forms" */
  registration_forms: Array<Registration_Forms>;
  /** fetch aggregated fields from the table: "registration_forms" */
  registration_forms_aggregate: Registration_Forms_Aggregate;
  /** fetch data from the table: "registration_forms" using primary key columns */
  registration_forms_by_pk?: Maybe<Registration_Forms>;
  /** fetch data from the table: "registration_payments" */
  registration_payments: Array<Registration_Payments>;
  /** fetch aggregated fields from the table: "registration_payments" */
  registration_payments_aggregate: Registration_Payments_Aggregate;
  /** fetch data from the table: "registration_payments" using primary key columns */
  registration_payments_by_pk?: Maybe<Registration_Payments>;
  /** perform the action: "registration_report" */
  registration_report: Array<RegistrationReportRow>;
  /** fetch data from the table: "registrations" */
  registrations: Array<Registrations>;
  /** fetch aggregated fields from the table: "registrations" */
  registrations_aggregate: Registrations_Aggregate;
  /** fetch data from the table: "registrations" using primary key columns */
  registrations_by_pk?: Maybe<Registrations>;
  /** fetch data from the table: "scheduled_registration_payments" */
  scheduled_registration_payments: Array<Scheduled_Registration_Payments>;
  /** fetch aggregated fields from the table: "scheduled_registration_payments" */
  scheduled_registration_payments_aggregate: Scheduled_Registration_Payments_Aggregate;
  /** fetch data from the table: "scheduled_registration_payments" using primary key columns */
  scheduled_registration_payments_by_pk?: Maybe<Scheduled_Registration_Payments>;
  /** fetch data from the table: "shopify_webhooks" */
  shopify_webhooks: Array<Shopify_Webhooks>;
  /** fetch aggregated fields from the table: "shopify_webhooks" */
  shopify_webhooks_aggregate: Shopify_Webhooks_Aggregate;
  /** fetch data from the table: "shopify_webhooks" using primary key columns */
  shopify_webhooks_by_pk?: Maybe<Shopify_Webhooks>;
  /** fetch data from the table: "stripe_webhooks" */
  stripe_webhooks: Array<Stripe_Webhooks>;
  /** fetch aggregated fields from the table: "stripe_webhooks" */
  stripe_webhooks_aggregate: Stripe_Webhooks_Aggregate;
  /** fetch data from the table: "stripe_webhooks" using primary key columns */
  stripe_webhooks_by_pk?: Maybe<Stripe_Webhooks>;
  /** fetch data from the table: "trainings" */
  trainings: Array<Trainings>;
  /** fetch aggregated fields from the table: "trainings" */
  trainings_aggregate: Trainings_Aggregate;
  /** fetch data from the table: "trainings" using primary key columns */
  trainings_by_pk?: Maybe<Trainings>;
  /** perform the action: "validateCoupon" */
  validateCoupon?: Maybe<ValidateCouponResult>;
  /** perform the action: "validateTrainingDownpayment" */
  validateTrainingDownpayment: ValidateTrainingDownpaymentResult;
};


/** query root */
export type Query_RootCommission_StructuresArgs = {
  distinct_on?: Maybe<Array<Commission_Structures_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Commission_Structures_Order_By>>;
  where?: Maybe<Commission_Structures_Bool_Exp>;
};


/** query root */
export type Query_RootCommission_Structures_AggregateArgs = {
  distinct_on?: Maybe<Array<Commission_Structures_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Commission_Structures_Order_By>>;
  where?: Maybe<Commission_Structures_Bool_Exp>;
};


/** query root */
export type Query_RootCommission_Structures_By_PkArgs = {
  identifier: Scalars['String'];
};


/** query root */
export type Query_RootCoupon_Discount_TypesArgs = {
  distinct_on?: Maybe<Array<Coupon_Discount_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_Discount_Types_Order_By>>;
  where?: Maybe<Coupon_Discount_Types_Bool_Exp>;
};


/** query root */
export type Query_RootCoupon_Discount_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Coupon_Discount_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_Discount_Types_Order_By>>;
  where?: Maybe<Coupon_Discount_Types_Bool_Exp>;
};


/** query root */
export type Query_RootCoupon_Discount_Types_By_PkArgs = {
  name: Scalars['String'];
};


/** query root */
export type Query_RootCouponsArgs = {
  distinct_on?: Maybe<Array<Coupons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupons_Order_By>>;
  where?: Maybe<Coupons_Bool_Exp>;
};


/** query root */
export type Query_RootCoupons_AggregateArgs = {
  distinct_on?: Maybe<Array<Coupons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupons_Order_By>>;
  where?: Maybe<Coupons_Bool_Exp>;
};


/** query root */
export type Query_RootCoupons_By_PkArgs = {
  code: Scalars['String'];
};


/** query root */
export type Query_RootEmail_Drip_Campaign_Cancelation_StatusArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Cancelation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Cancelation_Status_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Cancelation_Status_Bool_Exp>;
};


/** query root */
export type Query_RootEmail_Drip_Campaign_Cancelation_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Cancelation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Cancelation_Status_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Cancelation_Status_Bool_Exp>;
};


/** query root */
export type Query_RootEmail_Drip_Campaign_Cancelation_Status_By_PkArgs = {
  name: Scalars['String'];
};


/** query root */
export type Query_RootEmail_Drip_Campaign_Goal_TypesArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Goal_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Goal_Types_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Goal_Types_Bool_Exp>;
};


/** query root */
export type Query_RootEmail_Drip_Campaign_Goal_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Goal_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Goal_Types_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Goal_Types_Bool_Exp>;
};


/** query root */
export type Query_RootEmail_Drip_Campaign_Goal_Types_By_PkArgs = {
  name: Scalars['String'];
};


/** query root */
export type Query_RootEmail_Drip_Campaign_Send_After_ReferencesArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Send_After_References_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Send_After_References_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Send_After_References_Bool_Exp>;
};


/** query root */
export type Query_RootEmail_Drip_Campaign_Send_After_References_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Send_After_References_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Send_After_References_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Send_After_References_Bool_Exp>;
};


/** query root */
export type Query_RootEmail_Drip_Campaign_Send_After_References_By_PkArgs = {
  title: Scalars['String'];
};


/** query root */
export type Query_RootEmail_Drip_Campaign_Send_OpensArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Send_Opens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Send_Opens_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Send_Opens_Bool_Exp>;
};


/** query root */
export type Query_RootEmail_Drip_Campaign_Send_Opens_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Send_Opens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Send_Opens_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Send_Opens_Bool_Exp>;
};


/** query root */
export type Query_RootEmail_Drip_Campaign_Send_Opens_By_PkArgs = {
  timestamp: Scalars['timestamptz'];
};


/** query root */
export type Query_RootEmail_Drip_Campaign_SendsArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Sends_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Sends_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Sends_Bool_Exp>;
};


/** query root */
export type Query_RootEmail_Drip_Campaign_Sends_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Sends_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Sends_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Sends_Bool_Exp>;
};


/** query root */
export type Query_RootEmail_Drip_Campaign_Sends_By_PkArgs = {
  email_drip_campaign_email: Scalars['String'];
  email_drip_sequence_step: Scalars['String'];
};


/** query root */
export type Query_RootEmail_Drip_CampaignsArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaigns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaigns_Order_By>>;
  where?: Maybe<Email_Drip_Campaigns_Bool_Exp>;
};


/** query root */
export type Query_RootEmail_Drip_Campaigns_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaigns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaigns_Order_By>>;
  where?: Maybe<Email_Drip_Campaigns_Bool_Exp>;
};


/** query root */
export type Query_RootEmail_Drip_Campaigns_By_PkArgs = {
  email: Scalars['String'];
  email_drip_sequence: Scalars['String'];
};


/** query root */
export type Query_RootEmail_Drip_Sequence_StepsArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Sequence_Steps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Sequence_Steps_Order_By>>;
  where?: Maybe<Email_Drip_Sequence_Steps_Bool_Exp>;
};


/** query root */
export type Query_RootEmail_Drip_Sequence_Steps_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Sequence_Steps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Sequence_Steps_Order_By>>;
  where?: Maybe<Email_Drip_Sequence_Steps_Bool_Exp>;
};


/** query root */
export type Query_RootEmail_Drip_Sequence_Steps_By_PkArgs = {
  title: Scalars['String'];
};


/** query root */
export type Query_RootEmail_Drip_SequencesArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Sequences_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Sequences_Order_By>>;
  where?: Maybe<Email_Drip_Sequences_Bool_Exp>;
};


/** query root */
export type Query_RootEmail_Drip_Sequences_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Sequences_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Sequences_Order_By>>;
  where?: Maybe<Email_Drip_Sequences_Bool_Exp>;
};


/** query root */
export type Query_RootEmail_Drip_Sequences_By_PkArgs = {
  title: Scalars['String'];
};


/** query root */
export type Query_RootEnrollment_TransfersArgs = {
  distinct_on?: Maybe<Array<Enrollment_Transfers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enrollment_Transfers_Order_By>>;
  where?: Maybe<Enrollment_Transfers_Bool_Exp>;
};


/** query root */
export type Query_RootEnrollment_Transfers_AggregateArgs = {
  distinct_on?: Maybe<Array<Enrollment_Transfers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enrollment_Transfers_Order_By>>;
  where?: Maybe<Enrollment_Transfers_Bool_Exp>;
};


/** query root */
export type Query_RootEnrollment_Transfers_By_PkArgs = {
  registration_email: Scalars['String'];
  registration_training: Scalars['String'];
  transfer_training: Scalars['String'];
};


/** query root */
export type Query_RootPractitionersArgs = {
  distinct_on?: Maybe<Array<Practitioners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Practitioners_Order_By>>;
  where?: Maybe<Practitioners_Bool_Exp>;
};


/** query root */
export type Query_RootPractitioners_AggregateArgs = {
  distinct_on?: Maybe<Array<Practitioners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Practitioners_Order_By>>;
  where?: Maybe<Practitioners_Bool_Exp>;
};


/** query root */
export type Query_RootPractitioners_By_PkArgs = {
  shopify_id: Scalars['bigint'];
};


/** query root */
export type Query_RootRegistration_FormsArgs = {
  distinct_on?: Maybe<Array<Registration_Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registration_Forms_Order_By>>;
  where?: Maybe<Registration_Forms_Bool_Exp>;
};


/** query root */
export type Query_RootRegistration_Forms_AggregateArgs = {
  distinct_on?: Maybe<Array<Registration_Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registration_Forms_Order_By>>;
  where?: Maybe<Registration_Forms_Bool_Exp>;
};


/** query root */
export type Query_RootRegistration_Forms_By_PkArgs = {
  name: Scalars['String'];
};


/** query root */
export type Query_RootRegistration_PaymentsArgs = {
  distinct_on?: Maybe<Array<Registration_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registration_Payments_Order_By>>;
  where?: Maybe<Registration_Payments_Bool_Exp>;
};


/** query root */
export type Query_RootRegistration_Payments_AggregateArgs = {
  distinct_on?: Maybe<Array<Registration_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registration_Payments_Order_By>>;
  where?: Maybe<Registration_Payments_Bool_Exp>;
};


/** query root */
export type Query_RootRegistration_Payments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootRegistration_ReportArgs = {
  end: Scalars['date'];
  start: Scalars['date'];
  type?: Maybe<RegistrationReportType>;
};


/** query root */
export type Query_RootRegistrationsArgs = {
  distinct_on?: Maybe<Array<Registrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registrations_Order_By>>;
  where?: Maybe<Registrations_Bool_Exp>;
};


/** query root */
export type Query_RootRegistrations_AggregateArgs = {
  distinct_on?: Maybe<Array<Registrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registrations_Order_By>>;
  where?: Maybe<Registrations_Bool_Exp>;
};


/** query root */
export type Query_RootRegistrations_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  training: Scalars['String'];
};


/** query root */
export type Query_RootScheduled_Registration_PaymentsArgs = {
  distinct_on?: Maybe<Array<Scheduled_Registration_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scheduled_Registration_Payments_Order_By>>;
  where?: Maybe<Scheduled_Registration_Payments_Bool_Exp>;
};


/** query root */
export type Query_RootScheduled_Registration_Payments_AggregateArgs = {
  distinct_on?: Maybe<Array<Scheduled_Registration_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scheduled_Registration_Payments_Order_By>>;
  where?: Maybe<Scheduled_Registration_Payments_Bool_Exp>;
};


/** query root */
export type Query_RootScheduled_Registration_Payments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootShopify_WebhooksArgs = {
  distinct_on?: Maybe<Array<Shopify_Webhooks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shopify_Webhooks_Order_By>>;
  where?: Maybe<Shopify_Webhooks_Bool_Exp>;
};


/** query root */
export type Query_RootShopify_Webhooks_AggregateArgs = {
  distinct_on?: Maybe<Array<Shopify_Webhooks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shopify_Webhooks_Order_By>>;
  where?: Maybe<Shopify_Webhooks_Bool_Exp>;
};


/** query root */
export type Query_RootShopify_Webhooks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootStripe_WebhooksArgs = {
  distinct_on?: Maybe<Array<Stripe_Webhooks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stripe_Webhooks_Order_By>>;
  where?: Maybe<Stripe_Webhooks_Bool_Exp>;
};


/** query root */
export type Query_RootStripe_Webhooks_AggregateArgs = {
  distinct_on?: Maybe<Array<Stripe_Webhooks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stripe_Webhooks_Order_By>>;
  where?: Maybe<Stripe_Webhooks_Bool_Exp>;
};


/** query root */
export type Query_RootStripe_Webhooks_By_PkArgs = {
  event_id: Scalars['String'];
};


/** query root */
export type Query_RootTrainingsArgs = {
  distinct_on?: Maybe<Array<Trainings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trainings_Order_By>>;
  where?: Maybe<Trainings_Bool_Exp>;
};


/** query root */
export type Query_RootTrainings_AggregateArgs = {
  distinct_on?: Maybe<Array<Trainings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trainings_Order_By>>;
  where?: Maybe<Trainings_Bool_Exp>;
};


/** query root */
export type Query_RootTrainings_By_PkArgs = {
  slug: Scalars['String'];
};


/** query root */
export type Query_RootValidateCouponArgs = {
  input: ValidateCouponInput;
};


/** query root */
export type Query_RootValidateTrainingDownpaymentArgs = {
  training: Scalars['String'];
};

/** columns and relationships of "registration_forms" */
export type Registration_Forms = {
  __typename?: 'registration_forms';
  inputs: Scalars['json'];
  legacy_id?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  /** An array relationship */
  trainings: Array<Trainings>;
  /** An aggregated array relationship */
  trainings_aggregate: Trainings_Aggregate;
};


/** columns and relationships of "registration_forms" */
export type Registration_FormsInputsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "registration_forms" */
export type Registration_FormsTrainingsArgs = {
  distinct_on?: Maybe<Array<Trainings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trainings_Order_By>>;
  where?: Maybe<Trainings_Bool_Exp>;
};


/** columns and relationships of "registration_forms" */
export type Registration_FormsTrainings_AggregateArgs = {
  distinct_on?: Maybe<Array<Trainings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trainings_Order_By>>;
  where?: Maybe<Trainings_Bool_Exp>;
};

/** aggregated selection of "registration_forms" */
export type Registration_Forms_Aggregate = {
  __typename?: 'registration_forms_aggregate';
  aggregate?: Maybe<Registration_Forms_Aggregate_Fields>;
  nodes: Array<Registration_Forms>;
};

/** aggregate fields of "registration_forms" */
export type Registration_Forms_Aggregate_Fields = {
  __typename?: 'registration_forms_aggregate_fields';
  avg?: Maybe<Registration_Forms_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Registration_Forms_Max_Fields>;
  min?: Maybe<Registration_Forms_Min_Fields>;
  stddev?: Maybe<Registration_Forms_Stddev_Fields>;
  stddev_pop?: Maybe<Registration_Forms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Registration_Forms_Stddev_Samp_Fields>;
  sum?: Maybe<Registration_Forms_Sum_Fields>;
  var_pop?: Maybe<Registration_Forms_Var_Pop_Fields>;
  var_samp?: Maybe<Registration_Forms_Var_Samp_Fields>;
  variance?: Maybe<Registration_Forms_Variance_Fields>;
};


/** aggregate fields of "registration_forms" */
export type Registration_Forms_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Registration_Forms_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "registration_forms" */
export type Registration_Forms_Aggregate_Order_By = {
  avg?: Maybe<Registration_Forms_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Registration_Forms_Max_Order_By>;
  min?: Maybe<Registration_Forms_Min_Order_By>;
  stddev?: Maybe<Registration_Forms_Stddev_Order_By>;
  stddev_pop?: Maybe<Registration_Forms_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Registration_Forms_Stddev_Samp_Order_By>;
  sum?: Maybe<Registration_Forms_Sum_Order_By>;
  var_pop?: Maybe<Registration_Forms_Var_Pop_Order_By>;
  var_samp?: Maybe<Registration_Forms_Var_Samp_Order_By>;
  variance?: Maybe<Registration_Forms_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "registration_forms" */
export type Registration_Forms_Arr_Rel_Insert_Input = {
  data: Array<Registration_Forms_Insert_Input>;
  on_conflict?: Maybe<Registration_Forms_On_Conflict>;
};

/** aggregate avg on columns */
export type Registration_Forms_Avg_Fields = {
  __typename?: 'registration_forms_avg_fields';
  legacy_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "registration_forms" */
export type Registration_Forms_Avg_Order_By = {
  legacy_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "registration_forms". All fields are combined with a logical 'AND'. */
export type Registration_Forms_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Registration_Forms_Bool_Exp>>>;
  _not?: Maybe<Registration_Forms_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Registration_Forms_Bool_Exp>>>;
  inputs?: Maybe<Json_Comparison_Exp>;
  legacy_id?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  trainings?: Maybe<Trainings_Bool_Exp>;
};

/** unique or primary key constraints on table "registration_forms" */
export enum Registration_Forms_Constraint {
  /** unique or primary key constraint */
  RegistrationFormsPkey = 'registration_forms_pkey'
}

/** input type for incrementing integer column in table "registration_forms" */
export type Registration_Forms_Inc_Input = {
  legacy_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "registration_forms" */
export type Registration_Forms_Insert_Input = {
  inputs?: Maybe<Scalars['json']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  trainings?: Maybe<Trainings_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Registration_Forms_Max_Fields = {
  __typename?: 'registration_forms_max_fields';
  legacy_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "registration_forms" */
export type Registration_Forms_Max_Order_By = {
  legacy_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Registration_Forms_Min_Fields = {
  __typename?: 'registration_forms_min_fields';
  legacy_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "registration_forms" */
export type Registration_Forms_Min_Order_By = {
  legacy_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "registration_forms" */
export type Registration_Forms_Mutation_Response = {
  __typename?: 'registration_forms_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Registration_Forms>;
};

/** input type for inserting object relation for remote table "registration_forms" */
export type Registration_Forms_Obj_Rel_Insert_Input = {
  data: Registration_Forms_Insert_Input;
  on_conflict?: Maybe<Registration_Forms_On_Conflict>;
};

/** on conflict condition type for table "registration_forms" */
export type Registration_Forms_On_Conflict = {
  constraint: Registration_Forms_Constraint;
  update_columns: Array<Registration_Forms_Update_Column>;
  where?: Maybe<Registration_Forms_Bool_Exp>;
};

/** ordering options when selecting data from "registration_forms" */
export type Registration_Forms_Order_By = {
  inputs?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  trainings_aggregate?: Maybe<Trainings_Aggregate_Order_By>;
};

/** primary key columns input for table: "registration_forms" */
export type Registration_Forms_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "registration_forms" */
export enum Registration_Forms_Select_Column {
  /** column name */
  Inputs = 'inputs',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "registration_forms" */
export type Registration_Forms_Set_Input = {
  inputs?: Maybe<Scalars['json']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Registration_Forms_Stddev_Fields = {
  __typename?: 'registration_forms_stddev_fields';
  legacy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "registration_forms" */
export type Registration_Forms_Stddev_Order_By = {
  legacy_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Registration_Forms_Stddev_Pop_Fields = {
  __typename?: 'registration_forms_stddev_pop_fields';
  legacy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "registration_forms" */
export type Registration_Forms_Stddev_Pop_Order_By = {
  legacy_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Registration_Forms_Stddev_Samp_Fields = {
  __typename?: 'registration_forms_stddev_samp_fields';
  legacy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "registration_forms" */
export type Registration_Forms_Stddev_Samp_Order_By = {
  legacy_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Registration_Forms_Sum_Fields = {
  __typename?: 'registration_forms_sum_fields';
  legacy_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "registration_forms" */
export type Registration_Forms_Sum_Order_By = {
  legacy_id?: Maybe<Order_By>;
};

/** update columns of table "registration_forms" */
export enum Registration_Forms_Update_Column {
  /** column name */
  Inputs = 'inputs',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Registration_Forms_Var_Pop_Fields = {
  __typename?: 'registration_forms_var_pop_fields';
  legacy_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "registration_forms" */
export type Registration_Forms_Var_Pop_Order_By = {
  legacy_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Registration_Forms_Var_Samp_Fields = {
  __typename?: 'registration_forms_var_samp_fields';
  legacy_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "registration_forms" */
export type Registration_Forms_Var_Samp_Order_By = {
  legacy_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Registration_Forms_Variance_Fields = {
  __typename?: 'registration_forms_variance_fields';
  legacy_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "registration_forms" */
export type Registration_Forms_Variance_Order_By = {
  legacy_id?: Maybe<Order_By>;
};

/** columns and relationships of "registration_payments" */
export type Registration_Payments = {
  __typename?: 'registration_payments';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  legacy_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  registration?: Maybe<Registrations>;
  /** An object relationship */
  registrationByRegistrationCreatedAtRegistrationEmailRegistrationTraining?: Maybe<Registrations>;
  registration_created_at: Scalars['timestamptz'];
  registration_email: Scalars['String'];
  registration_training: Scalars['String'];
  /** An array relationship */
  scheduled_registration_payments: Array<Scheduled_Registration_Payments>;
  /** An aggregated array relationship */
  scheduled_registration_payments_aggregate: Scheduled_Registration_Payments_Aggregate;
  stripe_payment_intent?: Maybe<Scalars['String']>;
  unit_amount: Scalars['Int'];
};


/** columns and relationships of "registration_payments" */
export type Registration_PaymentsScheduled_Registration_PaymentsArgs = {
  distinct_on?: Maybe<Array<Scheduled_Registration_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scheduled_Registration_Payments_Order_By>>;
  where?: Maybe<Scheduled_Registration_Payments_Bool_Exp>;
};


/** columns and relationships of "registration_payments" */
export type Registration_PaymentsScheduled_Registration_Payments_AggregateArgs = {
  distinct_on?: Maybe<Array<Scheduled_Registration_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scheduled_Registration_Payments_Order_By>>;
  where?: Maybe<Scheduled_Registration_Payments_Bool_Exp>;
};

/** aggregated selection of "registration_payments" */
export type Registration_Payments_Aggregate = {
  __typename?: 'registration_payments_aggregate';
  aggregate?: Maybe<Registration_Payments_Aggregate_Fields>;
  nodes: Array<Registration_Payments>;
};

/** aggregate fields of "registration_payments" */
export type Registration_Payments_Aggregate_Fields = {
  __typename?: 'registration_payments_aggregate_fields';
  avg?: Maybe<Registration_Payments_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Registration_Payments_Max_Fields>;
  min?: Maybe<Registration_Payments_Min_Fields>;
  stddev?: Maybe<Registration_Payments_Stddev_Fields>;
  stddev_pop?: Maybe<Registration_Payments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Registration_Payments_Stddev_Samp_Fields>;
  sum?: Maybe<Registration_Payments_Sum_Fields>;
  var_pop?: Maybe<Registration_Payments_Var_Pop_Fields>;
  var_samp?: Maybe<Registration_Payments_Var_Samp_Fields>;
  variance?: Maybe<Registration_Payments_Variance_Fields>;
};


/** aggregate fields of "registration_payments" */
export type Registration_Payments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Registration_Payments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "registration_payments" */
export type Registration_Payments_Aggregate_Order_By = {
  avg?: Maybe<Registration_Payments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Registration_Payments_Max_Order_By>;
  min?: Maybe<Registration_Payments_Min_Order_By>;
  stddev?: Maybe<Registration_Payments_Stddev_Order_By>;
  stddev_pop?: Maybe<Registration_Payments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Registration_Payments_Stddev_Samp_Order_By>;
  sum?: Maybe<Registration_Payments_Sum_Order_By>;
  var_pop?: Maybe<Registration_Payments_Var_Pop_Order_By>;
  var_samp?: Maybe<Registration_Payments_Var_Samp_Order_By>;
  variance?: Maybe<Registration_Payments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "registration_payments" */
export type Registration_Payments_Arr_Rel_Insert_Input = {
  data: Array<Registration_Payments_Insert_Input>;
  on_conflict?: Maybe<Registration_Payments_On_Conflict>;
};

/** aggregate avg on columns */
export type Registration_Payments_Avg_Fields = {
  __typename?: 'registration_payments_avg_fields';
  legacy_id?: Maybe<Scalars['Float']>;
  unit_amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "registration_payments" */
export type Registration_Payments_Avg_Order_By = {
  legacy_id?: Maybe<Order_By>;
  unit_amount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "registration_payments". All fields are combined with a logical 'AND'. */
export type Registration_Payments_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Registration_Payments_Bool_Exp>>>;
  _not?: Maybe<Registration_Payments_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Registration_Payments_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  legacy_id?: Maybe<Bigint_Comparison_Exp>;
  registration?: Maybe<Registrations_Bool_Exp>;
  registrationByRegistrationCreatedAtRegistrationEmailRegistrationTraining?: Maybe<Registrations_Bool_Exp>;
  registration_created_at?: Maybe<Timestamptz_Comparison_Exp>;
  registration_email?: Maybe<String_Comparison_Exp>;
  registration_training?: Maybe<String_Comparison_Exp>;
  scheduled_registration_payments?: Maybe<Scheduled_Registration_Payments_Bool_Exp>;
  stripe_payment_intent?: Maybe<String_Comparison_Exp>;
  unit_amount?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "registration_payments" */
export enum Registration_Payments_Constraint {
  /** unique or primary key constraint */
  RegistrationPaymentsPkey = 'registration_payments_pkey'
}

/** input type for incrementing integer column in table "registration_payments" */
export type Registration_Payments_Inc_Input = {
  legacy_id?: Maybe<Scalars['bigint']>;
  unit_amount?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "registration_payments" */
export type Registration_Payments_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  registration?: Maybe<Registrations_Obj_Rel_Insert_Input>;
  registrationByRegistrationCreatedAtRegistrationEmailRegistrationTraining?: Maybe<Registrations_Obj_Rel_Insert_Input>;
  registration_created_at?: Maybe<Scalars['timestamptz']>;
  registration_email?: Maybe<Scalars['String']>;
  registration_training?: Maybe<Scalars['String']>;
  scheduled_registration_payments?: Maybe<Scheduled_Registration_Payments_Arr_Rel_Insert_Input>;
  stripe_payment_intent?: Maybe<Scalars['String']>;
  unit_amount?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Registration_Payments_Max_Fields = {
  __typename?: 'registration_payments_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  registration_created_at?: Maybe<Scalars['timestamptz']>;
  registration_email?: Maybe<Scalars['String']>;
  registration_training?: Maybe<Scalars['String']>;
  stripe_payment_intent?: Maybe<Scalars['String']>;
  unit_amount?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "registration_payments" */
export type Registration_Payments_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  registration_created_at?: Maybe<Order_By>;
  registration_email?: Maybe<Order_By>;
  registration_training?: Maybe<Order_By>;
  stripe_payment_intent?: Maybe<Order_By>;
  unit_amount?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Registration_Payments_Min_Fields = {
  __typename?: 'registration_payments_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  registration_created_at?: Maybe<Scalars['timestamptz']>;
  registration_email?: Maybe<Scalars['String']>;
  registration_training?: Maybe<Scalars['String']>;
  stripe_payment_intent?: Maybe<Scalars['String']>;
  unit_amount?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "registration_payments" */
export type Registration_Payments_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  registration_created_at?: Maybe<Order_By>;
  registration_email?: Maybe<Order_By>;
  registration_training?: Maybe<Order_By>;
  stripe_payment_intent?: Maybe<Order_By>;
  unit_amount?: Maybe<Order_By>;
};

/** response of any mutation on the table "registration_payments" */
export type Registration_Payments_Mutation_Response = {
  __typename?: 'registration_payments_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Registration_Payments>;
};

/** input type for inserting object relation for remote table "registration_payments" */
export type Registration_Payments_Obj_Rel_Insert_Input = {
  data: Registration_Payments_Insert_Input;
  on_conflict?: Maybe<Registration_Payments_On_Conflict>;
};

/** on conflict condition type for table "registration_payments" */
export type Registration_Payments_On_Conflict = {
  constraint: Registration_Payments_Constraint;
  update_columns: Array<Registration_Payments_Update_Column>;
  where?: Maybe<Registration_Payments_Bool_Exp>;
};

/** ordering options when selecting data from "registration_payments" */
export type Registration_Payments_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  registration?: Maybe<Registrations_Order_By>;
  registrationByRegistrationCreatedAtRegistrationEmailRegistrationTraining?: Maybe<Registrations_Order_By>;
  registration_created_at?: Maybe<Order_By>;
  registration_email?: Maybe<Order_By>;
  registration_training?: Maybe<Order_By>;
  scheduled_registration_payments_aggregate?: Maybe<Scheduled_Registration_Payments_Aggregate_Order_By>;
  stripe_payment_intent?: Maybe<Order_By>;
  unit_amount?: Maybe<Order_By>;
};

/** primary key columns input for table: "registration_payments" */
export type Registration_Payments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "registration_payments" */
export enum Registration_Payments_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  RegistrationCreatedAt = 'registration_created_at',
  /** column name */
  RegistrationEmail = 'registration_email',
  /** column name */
  RegistrationTraining = 'registration_training',
  /** column name */
  StripePaymentIntent = 'stripe_payment_intent',
  /** column name */
  UnitAmount = 'unit_amount'
}

/** input type for updating data in table "registration_payments" */
export type Registration_Payments_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  registration_created_at?: Maybe<Scalars['timestamptz']>;
  registration_email?: Maybe<Scalars['String']>;
  registration_training?: Maybe<Scalars['String']>;
  stripe_payment_intent?: Maybe<Scalars['String']>;
  unit_amount?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Registration_Payments_Stddev_Fields = {
  __typename?: 'registration_payments_stddev_fields';
  legacy_id?: Maybe<Scalars['Float']>;
  unit_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "registration_payments" */
export type Registration_Payments_Stddev_Order_By = {
  legacy_id?: Maybe<Order_By>;
  unit_amount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Registration_Payments_Stddev_Pop_Fields = {
  __typename?: 'registration_payments_stddev_pop_fields';
  legacy_id?: Maybe<Scalars['Float']>;
  unit_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "registration_payments" */
export type Registration_Payments_Stddev_Pop_Order_By = {
  legacy_id?: Maybe<Order_By>;
  unit_amount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Registration_Payments_Stddev_Samp_Fields = {
  __typename?: 'registration_payments_stddev_samp_fields';
  legacy_id?: Maybe<Scalars['Float']>;
  unit_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "registration_payments" */
export type Registration_Payments_Stddev_Samp_Order_By = {
  legacy_id?: Maybe<Order_By>;
  unit_amount?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Registration_Payments_Sum_Fields = {
  __typename?: 'registration_payments_sum_fields';
  legacy_id?: Maybe<Scalars['bigint']>;
  unit_amount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "registration_payments" */
export type Registration_Payments_Sum_Order_By = {
  legacy_id?: Maybe<Order_By>;
  unit_amount?: Maybe<Order_By>;
};

/** update columns of table "registration_payments" */
export enum Registration_Payments_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  RegistrationCreatedAt = 'registration_created_at',
  /** column name */
  RegistrationEmail = 'registration_email',
  /** column name */
  RegistrationTraining = 'registration_training',
  /** column name */
  StripePaymentIntent = 'stripe_payment_intent',
  /** column name */
  UnitAmount = 'unit_amount'
}

/** aggregate var_pop on columns */
export type Registration_Payments_Var_Pop_Fields = {
  __typename?: 'registration_payments_var_pop_fields';
  legacy_id?: Maybe<Scalars['Float']>;
  unit_amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "registration_payments" */
export type Registration_Payments_Var_Pop_Order_By = {
  legacy_id?: Maybe<Order_By>;
  unit_amount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Registration_Payments_Var_Samp_Fields = {
  __typename?: 'registration_payments_var_samp_fields';
  legacy_id?: Maybe<Scalars['Float']>;
  unit_amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "registration_payments" */
export type Registration_Payments_Var_Samp_Order_By = {
  legacy_id?: Maybe<Order_By>;
  unit_amount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Registration_Payments_Variance_Fields = {
  __typename?: 'registration_payments_variance_fields';
  legacy_id?: Maybe<Scalars['Float']>;
  unit_amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "registration_payments" */
export type Registration_Payments_Variance_Order_By = {
  legacy_id?: Maybe<Order_By>;
  unit_amount?: Maybe<Order_By>;
};

/** columns and relationships of "registrations" */
export type Registrations = {
  __typename?: 'registrations';
  coupon?: Maybe<Scalars['String']>;
  /** An object relationship */
  couponByCoupon?: Maybe<Coupons>;
  created_at: Scalars['timestamptz'];
  discount: Scalars['Int'];
  downpayment: Scalars['Int'];
  email: Scalars['String'];
  enrolled: Scalars['Boolean'];
  /** An array relationship */
  enrollment_transfers: Array<Enrollment_Transfers>;
  /** An aggregated array relationship */
  enrollment_transfers_aggregate: Enrollment_Transfers_Aggregate;
  form_data: Scalars['json'];
  legacy_id?: Maybe<Scalars['bigint']>;
  manual_paid: Scalars['Boolean'];
  /** An array relationship */
  registrationPaymentsByRegistrationCreatedAtRegistrationEmailRegistrationTraining: Array<Registration_Payments>;
  /** An aggregated array relationship */
  registrationPaymentsByRegistrationCreatedAtRegistrationEmailRegistrationTraining_aggregate: Registration_Payments_Aggregate;
  /** An array relationship */
  registration_payments: Array<Registration_Payments>;
  /** An aggregated array relationship */
  registration_payments_aggregate: Registration_Payments_Aggregate;
  /** An array relationship */
  scheduled_registration_payments: Array<Scheduled_Registration_Payments>;
  /** An aggregated array relationship */
  scheduled_registration_payments_aggregate: Scheduled_Registration_Payments_Aggregate;
  subtotal: Scalars['Int'];
  test: Scalars['Boolean'];
  tracking_id?: Maybe<Scalars['String']>;
  training: Scalars['String'];
  /** An object relationship */
  trainingByTraining: Trainings;
};


/** columns and relationships of "registrations" */
export type RegistrationsEnrollment_TransfersArgs = {
  distinct_on?: Maybe<Array<Enrollment_Transfers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enrollment_Transfers_Order_By>>;
  where?: Maybe<Enrollment_Transfers_Bool_Exp>;
};


/** columns and relationships of "registrations" */
export type RegistrationsEnrollment_Transfers_AggregateArgs = {
  distinct_on?: Maybe<Array<Enrollment_Transfers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enrollment_Transfers_Order_By>>;
  where?: Maybe<Enrollment_Transfers_Bool_Exp>;
};


/** columns and relationships of "registrations" */
export type RegistrationsForm_DataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "registrations" */
export type RegistrationsRegistrationPaymentsByRegistrationCreatedAtRegistrationEmailRegistrationTrainingArgs = {
  distinct_on?: Maybe<Array<Registration_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registration_Payments_Order_By>>;
  where?: Maybe<Registration_Payments_Bool_Exp>;
};


/** columns and relationships of "registrations" */
export type RegistrationsRegistrationPaymentsByRegistrationCreatedAtRegistrationEmailRegistrationTraining_AggregateArgs = {
  distinct_on?: Maybe<Array<Registration_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registration_Payments_Order_By>>;
  where?: Maybe<Registration_Payments_Bool_Exp>;
};


/** columns and relationships of "registrations" */
export type RegistrationsRegistration_PaymentsArgs = {
  distinct_on?: Maybe<Array<Registration_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registration_Payments_Order_By>>;
  where?: Maybe<Registration_Payments_Bool_Exp>;
};


/** columns and relationships of "registrations" */
export type RegistrationsRegistration_Payments_AggregateArgs = {
  distinct_on?: Maybe<Array<Registration_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registration_Payments_Order_By>>;
  where?: Maybe<Registration_Payments_Bool_Exp>;
};


/** columns and relationships of "registrations" */
export type RegistrationsScheduled_Registration_PaymentsArgs = {
  distinct_on?: Maybe<Array<Scheduled_Registration_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scheduled_Registration_Payments_Order_By>>;
  where?: Maybe<Scheduled_Registration_Payments_Bool_Exp>;
};


/** columns and relationships of "registrations" */
export type RegistrationsScheduled_Registration_Payments_AggregateArgs = {
  distinct_on?: Maybe<Array<Scheduled_Registration_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scheduled_Registration_Payments_Order_By>>;
  where?: Maybe<Scheduled_Registration_Payments_Bool_Exp>;
};

/** aggregated selection of "registrations" */
export type Registrations_Aggregate = {
  __typename?: 'registrations_aggregate';
  aggregate?: Maybe<Registrations_Aggregate_Fields>;
  nodes: Array<Registrations>;
};

/** aggregate fields of "registrations" */
export type Registrations_Aggregate_Fields = {
  __typename?: 'registrations_aggregate_fields';
  avg?: Maybe<Registrations_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Registrations_Max_Fields>;
  min?: Maybe<Registrations_Min_Fields>;
  stddev?: Maybe<Registrations_Stddev_Fields>;
  stddev_pop?: Maybe<Registrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Registrations_Stddev_Samp_Fields>;
  sum?: Maybe<Registrations_Sum_Fields>;
  var_pop?: Maybe<Registrations_Var_Pop_Fields>;
  var_samp?: Maybe<Registrations_Var_Samp_Fields>;
  variance?: Maybe<Registrations_Variance_Fields>;
};


/** aggregate fields of "registrations" */
export type Registrations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Registrations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "registrations" */
export type Registrations_Aggregate_Order_By = {
  avg?: Maybe<Registrations_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Registrations_Max_Order_By>;
  min?: Maybe<Registrations_Min_Order_By>;
  stddev?: Maybe<Registrations_Stddev_Order_By>;
  stddev_pop?: Maybe<Registrations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Registrations_Stddev_Samp_Order_By>;
  sum?: Maybe<Registrations_Sum_Order_By>;
  var_pop?: Maybe<Registrations_Var_Pop_Order_By>;
  var_samp?: Maybe<Registrations_Var_Samp_Order_By>;
  variance?: Maybe<Registrations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "registrations" */
export type Registrations_Arr_Rel_Insert_Input = {
  data: Array<Registrations_Insert_Input>;
  on_conflict?: Maybe<Registrations_On_Conflict>;
};

/** aggregate avg on columns */
export type Registrations_Avg_Fields = {
  __typename?: 'registrations_avg_fields';
  discount?: Maybe<Scalars['Float']>;
  downpayment?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "registrations" */
export type Registrations_Avg_Order_By = {
  discount?: Maybe<Order_By>;
  downpayment?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  subtotal?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "registrations". All fields are combined with a logical 'AND'. */
export type Registrations_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Registrations_Bool_Exp>>>;
  _not?: Maybe<Registrations_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Registrations_Bool_Exp>>>;
  coupon?: Maybe<String_Comparison_Exp>;
  couponByCoupon?: Maybe<Coupons_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  discount?: Maybe<Int_Comparison_Exp>;
  downpayment?: Maybe<Int_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  enrolled?: Maybe<Boolean_Comparison_Exp>;
  enrollment_transfers?: Maybe<Enrollment_Transfers_Bool_Exp>;
  form_data?: Maybe<Json_Comparison_Exp>;
  legacy_id?: Maybe<Bigint_Comparison_Exp>;
  manual_paid?: Maybe<Boolean_Comparison_Exp>;
  registrationPaymentsByRegistrationCreatedAtRegistrationEmailRegistrationTraining?: Maybe<Registration_Payments_Bool_Exp>;
  registration_payments?: Maybe<Registration_Payments_Bool_Exp>;
  scheduled_registration_payments?: Maybe<Scheduled_Registration_Payments_Bool_Exp>;
  subtotal?: Maybe<Int_Comparison_Exp>;
  test?: Maybe<Boolean_Comparison_Exp>;
  tracking_id?: Maybe<String_Comparison_Exp>;
  training?: Maybe<String_Comparison_Exp>;
  trainingByTraining?: Maybe<Trainings_Bool_Exp>;
};

/** unique or primary key constraints on table "registrations" */
export enum Registrations_Constraint {
  /** unique or primary key constraint */
  RegistrationsPkey = 'registrations_pkey'
}

/** input type for incrementing integer column in table "registrations" */
export type Registrations_Inc_Input = {
  discount?: Maybe<Scalars['Int']>;
  downpayment?: Maybe<Scalars['Int']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  subtotal?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "registrations" */
export type Registrations_Insert_Input = {
  coupon?: Maybe<Scalars['String']>;
  couponByCoupon?: Maybe<Coupons_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discount?: Maybe<Scalars['Int']>;
  downpayment?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  enrolled?: Maybe<Scalars['Boolean']>;
  enrollment_transfers?: Maybe<Enrollment_Transfers_Arr_Rel_Insert_Input>;
  form_data?: Maybe<Scalars['json']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  manual_paid?: Maybe<Scalars['Boolean']>;
  registrationPaymentsByRegistrationCreatedAtRegistrationEmailRegistrationTraining?: Maybe<Registration_Payments_Arr_Rel_Insert_Input>;
  registration_payments?: Maybe<Registration_Payments_Arr_Rel_Insert_Input>;
  scheduled_registration_payments?: Maybe<Scheduled_Registration_Payments_Arr_Rel_Insert_Input>;
  subtotal?: Maybe<Scalars['Int']>;
  test?: Maybe<Scalars['Boolean']>;
  tracking_id?: Maybe<Scalars['String']>;
  training?: Maybe<Scalars['String']>;
  trainingByTraining?: Maybe<Trainings_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Registrations_Max_Fields = {
  __typename?: 'registrations_max_fields';
  coupon?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discount?: Maybe<Scalars['Int']>;
  downpayment?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  subtotal?: Maybe<Scalars['Int']>;
  tracking_id?: Maybe<Scalars['String']>;
  training?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "registrations" */
export type Registrations_Max_Order_By = {
  coupon?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  downpayment?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  subtotal?: Maybe<Order_By>;
  tracking_id?: Maybe<Order_By>;
  training?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Registrations_Min_Fields = {
  __typename?: 'registrations_min_fields';
  coupon?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discount?: Maybe<Scalars['Int']>;
  downpayment?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  subtotal?: Maybe<Scalars['Int']>;
  tracking_id?: Maybe<Scalars['String']>;
  training?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "registrations" */
export type Registrations_Min_Order_By = {
  coupon?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  downpayment?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  subtotal?: Maybe<Order_By>;
  tracking_id?: Maybe<Order_By>;
  training?: Maybe<Order_By>;
};

/** response of any mutation on the table "registrations" */
export type Registrations_Mutation_Response = {
  __typename?: 'registrations_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Registrations>;
};

/** input type for inserting object relation for remote table "registrations" */
export type Registrations_Obj_Rel_Insert_Input = {
  data: Registrations_Insert_Input;
  on_conflict?: Maybe<Registrations_On_Conflict>;
};

/** on conflict condition type for table "registrations" */
export type Registrations_On_Conflict = {
  constraint: Registrations_Constraint;
  update_columns: Array<Registrations_Update_Column>;
  where?: Maybe<Registrations_Bool_Exp>;
};

/** ordering options when selecting data from "registrations" */
export type Registrations_Order_By = {
  coupon?: Maybe<Order_By>;
  couponByCoupon?: Maybe<Coupons_Order_By>;
  created_at?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  downpayment?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  enrolled?: Maybe<Order_By>;
  enrollment_transfers_aggregate?: Maybe<Enrollment_Transfers_Aggregate_Order_By>;
  form_data?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  manual_paid?: Maybe<Order_By>;
  registrationPaymentsByRegistrationCreatedAtRegistrationEmailRegistrationTraining_aggregate?: Maybe<Registration_Payments_Aggregate_Order_By>;
  registration_payments_aggregate?: Maybe<Registration_Payments_Aggregate_Order_By>;
  scheduled_registration_payments_aggregate?: Maybe<Scheduled_Registration_Payments_Aggregate_Order_By>;
  subtotal?: Maybe<Order_By>;
  test?: Maybe<Order_By>;
  tracking_id?: Maybe<Order_By>;
  training?: Maybe<Order_By>;
  trainingByTraining?: Maybe<Trainings_Order_By>;
};

/** primary key columns input for table: "registrations" */
export type Registrations_Pk_Columns_Input = {
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  training: Scalars['String'];
};

/** select columns of table "registrations" */
export enum Registrations_Select_Column {
  /** column name */
  Coupon = 'coupon',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Discount = 'discount',
  /** column name */
  Downpayment = 'downpayment',
  /** column name */
  Email = 'email',
  /** column name */
  Enrolled = 'enrolled',
  /** column name */
  FormData = 'form_data',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  ManualPaid = 'manual_paid',
  /** column name */
  Subtotal = 'subtotal',
  /** column name */
  Test = 'test',
  /** column name */
  TrackingId = 'tracking_id',
  /** column name */
  Training = 'training'
}

/** input type for updating data in table "registrations" */
export type Registrations_Set_Input = {
  coupon?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discount?: Maybe<Scalars['Int']>;
  downpayment?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  enrolled?: Maybe<Scalars['Boolean']>;
  form_data?: Maybe<Scalars['json']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  manual_paid?: Maybe<Scalars['Boolean']>;
  subtotal?: Maybe<Scalars['Int']>;
  test?: Maybe<Scalars['Boolean']>;
  tracking_id?: Maybe<Scalars['String']>;
  training?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Registrations_Stddev_Fields = {
  __typename?: 'registrations_stddev_fields';
  discount?: Maybe<Scalars['Float']>;
  downpayment?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "registrations" */
export type Registrations_Stddev_Order_By = {
  discount?: Maybe<Order_By>;
  downpayment?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  subtotal?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Registrations_Stddev_Pop_Fields = {
  __typename?: 'registrations_stddev_pop_fields';
  discount?: Maybe<Scalars['Float']>;
  downpayment?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "registrations" */
export type Registrations_Stddev_Pop_Order_By = {
  discount?: Maybe<Order_By>;
  downpayment?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  subtotal?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Registrations_Stddev_Samp_Fields = {
  __typename?: 'registrations_stddev_samp_fields';
  discount?: Maybe<Scalars['Float']>;
  downpayment?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "registrations" */
export type Registrations_Stddev_Samp_Order_By = {
  discount?: Maybe<Order_By>;
  downpayment?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  subtotal?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Registrations_Sum_Fields = {
  __typename?: 'registrations_sum_fields';
  discount?: Maybe<Scalars['Int']>;
  downpayment?: Maybe<Scalars['Int']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  subtotal?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "registrations" */
export type Registrations_Sum_Order_By = {
  discount?: Maybe<Order_By>;
  downpayment?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  subtotal?: Maybe<Order_By>;
};

/** update columns of table "registrations" */
export enum Registrations_Update_Column {
  /** column name */
  Coupon = 'coupon',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Discount = 'discount',
  /** column name */
  Downpayment = 'downpayment',
  /** column name */
  Email = 'email',
  /** column name */
  Enrolled = 'enrolled',
  /** column name */
  FormData = 'form_data',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  ManualPaid = 'manual_paid',
  /** column name */
  Subtotal = 'subtotal',
  /** column name */
  Test = 'test',
  /** column name */
  TrackingId = 'tracking_id',
  /** column name */
  Training = 'training'
}

/** aggregate var_pop on columns */
export type Registrations_Var_Pop_Fields = {
  __typename?: 'registrations_var_pop_fields';
  discount?: Maybe<Scalars['Float']>;
  downpayment?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "registrations" */
export type Registrations_Var_Pop_Order_By = {
  discount?: Maybe<Order_By>;
  downpayment?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  subtotal?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Registrations_Var_Samp_Fields = {
  __typename?: 'registrations_var_samp_fields';
  discount?: Maybe<Scalars['Float']>;
  downpayment?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "registrations" */
export type Registrations_Var_Samp_Order_By = {
  discount?: Maybe<Order_By>;
  downpayment?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  subtotal?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Registrations_Variance_Fields = {
  __typename?: 'registrations_variance_fields';
  discount?: Maybe<Scalars['Float']>;
  downpayment?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "registrations" */
export type Registrations_Variance_Order_By = {
  discount?: Maybe<Order_By>;
  downpayment?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  subtotal?: Maybe<Order_By>;
};

/** columns and relationships of "scheduled_registration_payments" */
export type Scheduled_Registration_Payments = {
  __typename?: 'scheduled_registration_payments';
  amount: Scalars['Int'];
  canceled: Scalars['Boolean'];
  id: Scalars['uuid'];
  payment?: Maybe<Scalars['uuid']>;
  processing_date: Scalars['date'];
  processing_errors: Scalars['json'];
  /** An object relationship */
  registration?: Maybe<Registrations>;
  registration_email: Scalars['String'];
  /** An object relationship */
  registration_payment?: Maybe<Registration_Payments>;
  registration_training: Scalars['String'];
  stripe_payment_intent?: Maybe<Scalars['String']>;
};


/** columns and relationships of "scheduled_registration_payments" */
export type Scheduled_Registration_PaymentsProcessing_ErrorsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Aggregate = {
  __typename?: 'scheduled_registration_payments_aggregate';
  aggregate?: Maybe<Scheduled_Registration_Payments_Aggregate_Fields>;
  nodes: Array<Scheduled_Registration_Payments>;
};

/** aggregate fields of "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Aggregate_Fields = {
  __typename?: 'scheduled_registration_payments_aggregate_fields';
  avg?: Maybe<Scheduled_Registration_Payments_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Scheduled_Registration_Payments_Max_Fields>;
  min?: Maybe<Scheduled_Registration_Payments_Min_Fields>;
  stddev?: Maybe<Scheduled_Registration_Payments_Stddev_Fields>;
  stddev_pop?: Maybe<Scheduled_Registration_Payments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Scheduled_Registration_Payments_Stddev_Samp_Fields>;
  sum?: Maybe<Scheduled_Registration_Payments_Sum_Fields>;
  var_pop?: Maybe<Scheduled_Registration_Payments_Var_Pop_Fields>;
  var_samp?: Maybe<Scheduled_Registration_Payments_Var_Samp_Fields>;
  variance?: Maybe<Scheduled_Registration_Payments_Variance_Fields>;
};


/** aggregate fields of "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Scheduled_Registration_Payments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Aggregate_Order_By = {
  avg?: Maybe<Scheduled_Registration_Payments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Scheduled_Registration_Payments_Max_Order_By>;
  min?: Maybe<Scheduled_Registration_Payments_Min_Order_By>;
  stddev?: Maybe<Scheduled_Registration_Payments_Stddev_Order_By>;
  stddev_pop?: Maybe<Scheduled_Registration_Payments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Scheduled_Registration_Payments_Stddev_Samp_Order_By>;
  sum?: Maybe<Scheduled_Registration_Payments_Sum_Order_By>;
  var_pop?: Maybe<Scheduled_Registration_Payments_Var_Pop_Order_By>;
  var_samp?: Maybe<Scheduled_Registration_Payments_Var_Samp_Order_By>;
  variance?: Maybe<Scheduled_Registration_Payments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Arr_Rel_Insert_Input = {
  data: Array<Scheduled_Registration_Payments_Insert_Input>;
  on_conflict?: Maybe<Scheduled_Registration_Payments_On_Conflict>;
};

/** aggregate avg on columns */
export type Scheduled_Registration_Payments_Avg_Fields = {
  __typename?: 'scheduled_registration_payments_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Avg_Order_By = {
  amount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "scheduled_registration_payments". All fields are combined with a logical 'AND'. */
export type Scheduled_Registration_Payments_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Scheduled_Registration_Payments_Bool_Exp>>>;
  _not?: Maybe<Scheduled_Registration_Payments_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Scheduled_Registration_Payments_Bool_Exp>>>;
  amount?: Maybe<Int_Comparison_Exp>;
  canceled?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  payment?: Maybe<Uuid_Comparison_Exp>;
  processing_date?: Maybe<Date_Comparison_Exp>;
  processing_errors?: Maybe<Json_Comparison_Exp>;
  registration?: Maybe<Registrations_Bool_Exp>;
  registration_email?: Maybe<String_Comparison_Exp>;
  registration_payment?: Maybe<Registration_Payments_Bool_Exp>;
  registration_training?: Maybe<String_Comparison_Exp>;
  stripe_payment_intent?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "scheduled_registration_payments" */
export enum Scheduled_Registration_Payments_Constraint {
  /** unique or primary key constraint */
  ScheduledRegistrationPaymentsPkey = 'scheduled_registration_payments_pkey'
}

/** input type for incrementing integer column in table "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Inc_Input = {
  amount?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Insert_Input = {
  amount?: Maybe<Scalars['Int']>;
  canceled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  payment?: Maybe<Scalars['uuid']>;
  processing_date?: Maybe<Scalars['date']>;
  processing_errors?: Maybe<Scalars['json']>;
  registration?: Maybe<Registrations_Obj_Rel_Insert_Input>;
  registration_email?: Maybe<Scalars['String']>;
  registration_payment?: Maybe<Registration_Payments_Obj_Rel_Insert_Input>;
  registration_training?: Maybe<Scalars['String']>;
  stripe_payment_intent?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Scheduled_Registration_Payments_Max_Fields = {
  __typename?: 'scheduled_registration_payments_max_fields';
  amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  payment?: Maybe<Scalars['uuid']>;
  processing_date?: Maybe<Scalars['date']>;
  registration_email?: Maybe<Scalars['String']>;
  registration_training?: Maybe<Scalars['String']>;
  stripe_payment_intent?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Max_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment?: Maybe<Order_By>;
  processing_date?: Maybe<Order_By>;
  registration_email?: Maybe<Order_By>;
  registration_training?: Maybe<Order_By>;
  stripe_payment_intent?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Scheduled_Registration_Payments_Min_Fields = {
  __typename?: 'scheduled_registration_payments_min_fields';
  amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  payment?: Maybe<Scalars['uuid']>;
  processing_date?: Maybe<Scalars['date']>;
  registration_email?: Maybe<Scalars['String']>;
  registration_training?: Maybe<Scalars['String']>;
  stripe_payment_intent?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Min_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment?: Maybe<Order_By>;
  processing_date?: Maybe<Order_By>;
  registration_email?: Maybe<Order_By>;
  registration_training?: Maybe<Order_By>;
  stripe_payment_intent?: Maybe<Order_By>;
};

/** response of any mutation on the table "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Mutation_Response = {
  __typename?: 'scheduled_registration_payments_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Scheduled_Registration_Payments>;
};

/** input type for inserting object relation for remote table "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Obj_Rel_Insert_Input = {
  data: Scheduled_Registration_Payments_Insert_Input;
  on_conflict?: Maybe<Scheduled_Registration_Payments_On_Conflict>;
};

/** on conflict condition type for table "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_On_Conflict = {
  constraint: Scheduled_Registration_Payments_Constraint;
  update_columns: Array<Scheduled_Registration_Payments_Update_Column>;
  where?: Maybe<Scheduled_Registration_Payments_Bool_Exp>;
};

/** ordering options when selecting data from "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Order_By = {
  amount?: Maybe<Order_By>;
  canceled?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment?: Maybe<Order_By>;
  processing_date?: Maybe<Order_By>;
  processing_errors?: Maybe<Order_By>;
  registration?: Maybe<Registrations_Order_By>;
  registration_email?: Maybe<Order_By>;
  registration_payment?: Maybe<Registration_Payments_Order_By>;
  registration_training?: Maybe<Order_By>;
  stripe_payment_intent?: Maybe<Order_By>;
};

/** primary key columns input for table: "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "scheduled_registration_payments" */
export enum Scheduled_Registration_Payments_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Canceled = 'canceled',
  /** column name */
  Id = 'id',
  /** column name */
  Payment = 'payment',
  /** column name */
  ProcessingDate = 'processing_date',
  /** column name */
  ProcessingErrors = 'processing_errors',
  /** column name */
  RegistrationEmail = 'registration_email',
  /** column name */
  RegistrationTraining = 'registration_training',
  /** column name */
  StripePaymentIntent = 'stripe_payment_intent'
}

/** input type for updating data in table "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Set_Input = {
  amount?: Maybe<Scalars['Int']>;
  canceled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  payment?: Maybe<Scalars['uuid']>;
  processing_date?: Maybe<Scalars['date']>;
  processing_errors?: Maybe<Scalars['json']>;
  registration_email?: Maybe<Scalars['String']>;
  registration_training?: Maybe<Scalars['String']>;
  stripe_payment_intent?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Scheduled_Registration_Payments_Stddev_Fields = {
  __typename?: 'scheduled_registration_payments_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Scheduled_Registration_Payments_Stddev_Pop_Fields = {
  __typename?: 'scheduled_registration_payments_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Scheduled_Registration_Payments_Stddev_Samp_Fields = {
  __typename?: 'scheduled_registration_payments_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Scheduled_Registration_Payments_Sum_Fields = {
  __typename?: 'scheduled_registration_payments_sum_fields';
  amount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Sum_Order_By = {
  amount?: Maybe<Order_By>;
};

/** update columns of table "scheduled_registration_payments" */
export enum Scheduled_Registration_Payments_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Canceled = 'canceled',
  /** column name */
  Id = 'id',
  /** column name */
  Payment = 'payment',
  /** column name */
  ProcessingDate = 'processing_date',
  /** column name */
  ProcessingErrors = 'processing_errors',
  /** column name */
  RegistrationEmail = 'registration_email',
  /** column name */
  RegistrationTraining = 'registration_training',
  /** column name */
  StripePaymentIntent = 'stripe_payment_intent'
}

/** aggregate var_pop on columns */
export type Scheduled_Registration_Payments_Var_Pop_Fields = {
  __typename?: 'scheduled_registration_payments_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Scheduled_Registration_Payments_Var_Samp_Fields = {
  __typename?: 'scheduled_registration_payments_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Scheduled_Registration_Payments_Variance_Fields = {
  __typename?: 'scheduled_registration_payments_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "scheduled_registration_payments" */
export type Scheduled_Registration_Payments_Variance_Order_By = {
  amount?: Maybe<Order_By>;
};

/** columns and relationships of "shopify_webhooks" */
export type Shopify_Webhooks = {
  __typename?: 'shopify_webhooks';
  id: Scalars['uuid'];
  shopify_id?: Maybe<Scalars['bigint']>;
  topic: Scalars['String'];
};

/** aggregated selection of "shopify_webhooks" */
export type Shopify_Webhooks_Aggregate = {
  __typename?: 'shopify_webhooks_aggregate';
  aggregate?: Maybe<Shopify_Webhooks_Aggregate_Fields>;
  nodes: Array<Shopify_Webhooks>;
};

/** aggregate fields of "shopify_webhooks" */
export type Shopify_Webhooks_Aggregate_Fields = {
  __typename?: 'shopify_webhooks_aggregate_fields';
  avg?: Maybe<Shopify_Webhooks_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Shopify_Webhooks_Max_Fields>;
  min?: Maybe<Shopify_Webhooks_Min_Fields>;
  stddev?: Maybe<Shopify_Webhooks_Stddev_Fields>;
  stddev_pop?: Maybe<Shopify_Webhooks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Shopify_Webhooks_Stddev_Samp_Fields>;
  sum?: Maybe<Shopify_Webhooks_Sum_Fields>;
  var_pop?: Maybe<Shopify_Webhooks_Var_Pop_Fields>;
  var_samp?: Maybe<Shopify_Webhooks_Var_Samp_Fields>;
  variance?: Maybe<Shopify_Webhooks_Variance_Fields>;
};


/** aggregate fields of "shopify_webhooks" */
export type Shopify_Webhooks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shopify_Webhooks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "shopify_webhooks" */
export type Shopify_Webhooks_Aggregate_Order_By = {
  avg?: Maybe<Shopify_Webhooks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Shopify_Webhooks_Max_Order_By>;
  min?: Maybe<Shopify_Webhooks_Min_Order_By>;
  stddev?: Maybe<Shopify_Webhooks_Stddev_Order_By>;
  stddev_pop?: Maybe<Shopify_Webhooks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Shopify_Webhooks_Stddev_Samp_Order_By>;
  sum?: Maybe<Shopify_Webhooks_Sum_Order_By>;
  var_pop?: Maybe<Shopify_Webhooks_Var_Pop_Order_By>;
  var_samp?: Maybe<Shopify_Webhooks_Var_Samp_Order_By>;
  variance?: Maybe<Shopify_Webhooks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "shopify_webhooks" */
export type Shopify_Webhooks_Arr_Rel_Insert_Input = {
  data: Array<Shopify_Webhooks_Insert_Input>;
  on_conflict?: Maybe<Shopify_Webhooks_On_Conflict>;
};

/** aggregate avg on columns */
export type Shopify_Webhooks_Avg_Fields = {
  __typename?: 'shopify_webhooks_avg_fields';
  shopify_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "shopify_webhooks" */
export type Shopify_Webhooks_Avg_Order_By = {
  shopify_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "shopify_webhooks". All fields are combined with a logical 'AND'. */
export type Shopify_Webhooks_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Shopify_Webhooks_Bool_Exp>>>;
  _not?: Maybe<Shopify_Webhooks_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Shopify_Webhooks_Bool_Exp>>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  shopify_id?: Maybe<Bigint_Comparison_Exp>;
  topic?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "shopify_webhooks" */
export enum Shopify_Webhooks_Constraint {
  /** unique or primary key constraint */
  ShopifyWebhooksPkey = 'shopify_webhooks_pkey'
}

/** input type for incrementing integer column in table "shopify_webhooks" */
export type Shopify_Webhooks_Inc_Input = {
  shopify_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "shopify_webhooks" */
export type Shopify_Webhooks_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  shopify_id?: Maybe<Scalars['bigint']>;
  topic?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Shopify_Webhooks_Max_Fields = {
  __typename?: 'shopify_webhooks_max_fields';
  id?: Maybe<Scalars['uuid']>;
  shopify_id?: Maybe<Scalars['bigint']>;
  topic?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "shopify_webhooks" */
export type Shopify_Webhooks_Max_Order_By = {
  id?: Maybe<Order_By>;
  shopify_id?: Maybe<Order_By>;
  topic?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Shopify_Webhooks_Min_Fields = {
  __typename?: 'shopify_webhooks_min_fields';
  id?: Maybe<Scalars['uuid']>;
  shopify_id?: Maybe<Scalars['bigint']>;
  topic?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "shopify_webhooks" */
export type Shopify_Webhooks_Min_Order_By = {
  id?: Maybe<Order_By>;
  shopify_id?: Maybe<Order_By>;
  topic?: Maybe<Order_By>;
};

/** response of any mutation on the table "shopify_webhooks" */
export type Shopify_Webhooks_Mutation_Response = {
  __typename?: 'shopify_webhooks_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Shopify_Webhooks>;
};

/** input type for inserting object relation for remote table "shopify_webhooks" */
export type Shopify_Webhooks_Obj_Rel_Insert_Input = {
  data: Shopify_Webhooks_Insert_Input;
  on_conflict?: Maybe<Shopify_Webhooks_On_Conflict>;
};

/** on conflict condition type for table "shopify_webhooks" */
export type Shopify_Webhooks_On_Conflict = {
  constraint: Shopify_Webhooks_Constraint;
  update_columns: Array<Shopify_Webhooks_Update_Column>;
  where?: Maybe<Shopify_Webhooks_Bool_Exp>;
};

/** ordering options when selecting data from "shopify_webhooks" */
export type Shopify_Webhooks_Order_By = {
  id?: Maybe<Order_By>;
  shopify_id?: Maybe<Order_By>;
  topic?: Maybe<Order_By>;
};

/** primary key columns input for table: "shopify_webhooks" */
export type Shopify_Webhooks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "shopify_webhooks" */
export enum Shopify_Webhooks_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ShopifyId = 'shopify_id',
  /** column name */
  Topic = 'topic'
}

/** input type for updating data in table "shopify_webhooks" */
export type Shopify_Webhooks_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  shopify_id?: Maybe<Scalars['bigint']>;
  topic?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Shopify_Webhooks_Stddev_Fields = {
  __typename?: 'shopify_webhooks_stddev_fields';
  shopify_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "shopify_webhooks" */
export type Shopify_Webhooks_Stddev_Order_By = {
  shopify_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Shopify_Webhooks_Stddev_Pop_Fields = {
  __typename?: 'shopify_webhooks_stddev_pop_fields';
  shopify_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "shopify_webhooks" */
export type Shopify_Webhooks_Stddev_Pop_Order_By = {
  shopify_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Shopify_Webhooks_Stddev_Samp_Fields = {
  __typename?: 'shopify_webhooks_stddev_samp_fields';
  shopify_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "shopify_webhooks" */
export type Shopify_Webhooks_Stddev_Samp_Order_By = {
  shopify_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Shopify_Webhooks_Sum_Fields = {
  __typename?: 'shopify_webhooks_sum_fields';
  shopify_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "shopify_webhooks" */
export type Shopify_Webhooks_Sum_Order_By = {
  shopify_id?: Maybe<Order_By>;
};

/** update columns of table "shopify_webhooks" */
export enum Shopify_Webhooks_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ShopifyId = 'shopify_id',
  /** column name */
  Topic = 'topic'
}

/** aggregate var_pop on columns */
export type Shopify_Webhooks_Var_Pop_Fields = {
  __typename?: 'shopify_webhooks_var_pop_fields';
  shopify_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "shopify_webhooks" */
export type Shopify_Webhooks_Var_Pop_Order_By = {
  shopify_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Shopify_Webhooks_Var_Samp_Fields = {
  __typename?: 'shopify_webhooks_var_samp_fields';
  shopify_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "shopify_webhooks" */
export type Shopify_Webhooks_Var_Samp_Order_By = {
  shopify_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Shopify_Webhooks_Variance_Fields = {
  __typename?: 'shopify_webhooks_variance_fields';
  shopify_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "shopify_webhooks" */
export type Shopify_Webhooks_Variance_Order_By = {
  shopify_id?: Maybe<Order_By>;
};

/** columns and relationships of "stripe_webhooks" */
export type Stripe_Webhooks = {
  __typename?: 'stripe_webhooks';
  event_id: Scalars['String'];
};

/** aggregated selection of "stripe_webhooks" */
export type Stripe_Webhooks_Aggregate = {
  __typename?: 'stripe_webhooks_aggregate';
  aggregate?: Maybe<Stripe_Webhooks_Aggregate_Fields>;
  nodes: Array<Stripe_Webhooks>;
};

/** aggregate fields of "stripe_webhooks" */
export type Stripe_Webhooks_Aggregate_Fields = {
  __typename?: 'stripe_webhooks_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stripe_Webhooks_Max_Fields>;
  min?: Maybe<Stripe_Webhooks_Min_Fields>;
};


/** aggregate fields of "stripe_webhooks" */
export type Stripe_Webhooks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stripe_Webhooks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stripe_webhooks" */
export type Stripe_Webhooks_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Stripe_Webhooks_Max_Order_By>;
  min?: Maybe<Stripe_Webhooks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "stripe_webhooks" */
export type Stripe_Webhooks_Arr_Rel_Insert_Input = {
  data: Array<Stripe_Webhooks_Insert_Input>;
  on_conflict?: Maybe<Stripe_Webhooks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "stripe_webhooks". All fields are combined with a logical 'AND'. */
export type Stripe_Webhooks_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Stripe_Webhooks_Bool_Exp>>>;
  _not?: Maybe<Stripe_Webhooks_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Stripe_Webhooks_Bool_Exp>>>;
  event_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stripe_webhooks" */
export enum Stripe_Webhooks_Constraint {
  /** unique or primary key constraint */
  StripeWebhooksPkey = 'stripe_webhooks_pkey'
}

/** input type for inserting data into table "stripe_webhooks" */
export type Stripe_Webhooks_Insert_Input = {
  event_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Stripe_Webhooks_Max_Fields = {
  __typename?: 'stripe_webhooks_max_fields';
  event_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stripe_webhooks" */
export type Stripe_Webhooks_Max_Order_By = {
  event_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Stripe_Webhooks_Min_Fields = {
  __typename?: 'stripe_webhooks_min_fields';
  event_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stripe_webhooks" */
export type Stripe_Webhooks_Min_Order_By = {
  event_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "stripe_webhooks" */
export type Stripe_Webhooks_Mutation_Response = {
  __typename?: 'stripe_webhooks_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stripe_Webhooks>;
};

/** input type for inserting object relation for remote table "stripe_webhooks" */
export type Stripe_Webhooks_Obj_Rel_Insert_Input = {
  data: Stripe_Webhooks_Insert_Input;
  on_conflict?: Maybe<Stripe_Webhooks_On_Conflict>;
};

/** on conflict condition type for table "stripe_webhooks" */
export type Stripe_Webhooks_On_Conflict = {
  constraint: Stripe_Webhooks_Constraint;
  update_columns: Array<Stripe_Webhooks_Update_Column>;
  where?: Maybe<Stripe_Webhooks_Bool_Exp>;
};

/** ordering options when selecting data from "stripe_webhooks" */
export type Stripe_Webhooks_Order_By = {
  event_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "stripe_webhooks" */
export type Stripe_Webhooks_Pk_Columns_Input = {
  event_id: Scalars['String'];
};

/** select columns of table "stripe_webhooks" */
export enum Stripe_Webhooks_Select_Column {
  /** column name */
  EventId = 'event_id'
}

/** input type for updating data in table "stripe_webhooks" */
export type Stripe_Webhooks_Set_Input = {
  event_id?: Maybe<Scalars['String']>;
};

/** update columns of table "stripe_webhooks" */
export enum Stripe_Webhooks_Update_Column {
  /** column name */
  EventId = 'event_id'
}

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "commission_structures" */
  commission_structures: Array<Commission_Structures>;
  /** fetch aggregated fields from the table: "commission_structures" */
  commission_structures_aggregate: Commission_Structures_Aggregate;
  /** fetch data from the table: "commission_structures" using primary key columns */
  commission_structures_by_pk?: Maybe<Commission_Structures>;
  /** fetch data from the table: "coupon_discount_types" */
  coupon_discount_types: Array<Coupon_Discount_Types>;
  /** fetch aggregated fields from the table: "coupon_discount_types" */
  coupon_discount_types_aggregate: Coupon_Discount_Types_Aggregate;
  /** fetch data from the table: "coupon_discount_types" using primary key columns */
  coupon_discount_types_by_pk?: Maybe<Coupon_Discount_Types>;
  /** fetch data from the table: "coupons" */
  coupons: Array<Coupons>;
  /** fetch aggregated fields from the table: "coupons" */
  coupons_aggregate: Coupons_Aggregate;
  /** fetch data from the table: "coupons" using primary key columns */
  coupons_by_pk?: Maybe<Coupons>;
  /** fetch data from the table: "email_drip_campaign_cancelation_status" */
  email_drip_campaign_cancelation_status: Array<Email_Drip_Campaign_Cancelation_Status>;
  /** fetch aggregated fields from the table: "email_drip_campaign_cancelation_status" */
  email_drip_campaign_cancelation_status_aggregate: Email_Drip_Campaign_Cancelation_Status_Aggregate;
  /** fetch data from the table: "email_drip_campaign_cancelation_status" using primary key columns */
  email_drip_campaign_cancelation_status_by_pk?: Maybe<Email_Drip_Campaign_Cancelation_Status>;
  /** fetch data from the table: "email_drip_campaign_goal_types" */
  email_drip_campaign_goal_types: Array<Email_Drip_Campaign_Goal_Types>;
  /** fetch aggregated fields from the table: "email_drip_campaign_goal_types" */
  email_drip_campaign_goal_types_aggregate: Email_Drip_Campaign_Goal_Types_Aggregate;
  /** fetch data from the table: "email_drip_campaign_goal_types" using primary key columns */
  email_drip_campaign_goal_types_by_pk?: Maybe<Email_Drip_Campaign_Goal_Types>;
  /** fetch data from the table: "email_drip_campaign_send_after_references" */
  email_drip_campaign_send_after_references: Array<Email_Drip_Campaign_Send_After_References>;
  /** fetch aggregated fields from the table: "email_drip_campaign_send_after_references" */
  email_drip_campaign_send_after_references_aggregate: Email_Drip_Campaign_Send_After_References_Aggregate;
  /** fetch data from the table: "email_drip_campaign_send_after_references" using primary key columns */
  email_drip_campaign_send_after_references_by_pk?: Maybe<Email_Drip_Campaign_Send_After_References>;
  /** fetch data from the table: "email_drip_campaign_send_opens" */
  email_drip_campaign_send_opens: Array<Email_Drip_Campaign_Send_Opens>;
  /** fetch aggregated fields from the table: "email_drip_campaign_send_opens" */
  email_drip_campaign_send_opens_aggregate: Email_Drip_Campaign_Send_Opens_Aggregate;
  /** fetch data from the table: "email_drip_campaign_send_opens" using primary key columns */
  email_drip_campaign_send_opens_by_pk?: Maybe<Email_Drip_Campaign_Send_Opens>;
  /** fetch data from the table: "email_drip_campaign_sends" */
  email_drip_campaign_sends: Array<Email_Drip_Campaign_Sends>;
  /** fetch aggregated fields from the table: "email_drip_campaign_sends" */
  email_drip_campaign_sends_aggregate: Email_Drip_Campaign_Sends_Aggregate;
  /** fetch data from the table: "email_drip_campaign_sends" using primary key columns */
  email_drip_campaign_sends_by_pk?: Maybe<Email_Drip_Campaign_Sends>;
  /** fetch data from the table: "email_drip_campaigns" */
  email_drip_campaigns: Array<Email_Drip_Campaigns>;
  /** fetch aggregated fields from the table: "email_drip_campaigns" */
  email_drip_campaigns_aggregate: Email_Drip_Campaigns_Aggregate;
  /** fetch data from the table: "email_drip_campaigns" using primary key columns */
  email_drip_campaigns_by_pk?: Maybe<Email_Drip_Campaigns>;
  /** fetch data from the table: "email_drip_sequence_steps" */
  email_drip_sequence_steps: Array<Email_Drip_Sequence_Steps>;
  /** fetch aggregated fields from the table: "email_drip_sequence_steps" */
  email_drip_sequence_steps_aggregate: Email_Drip_Sequence_Steps_Aggregate;
  /** fetch data from the table: "email_drip_sequence_steps" using primary key columns */
  email_drip_sequence_steps_by_pk?: Maybe<Email_Drip_Sequence_Steps>;
  /** fetch data from the table: "email_drip_sequences" */
  email_drip_sequences: Array<Email_Drip_Sequences>;
  /** fetch aggregated fields from the table: "email_drip_sequences" */
  email_drip_sequences_aggregate: Email_Drip_Sequences_Aggregate;
  /** fetch data from the table: "email_drip_sequences" using primary key columns */
  email_drip_sequences_by_pk?: Maybe<Email_Drip_Sequences>;
  /** fetch data from the table: "enrollment_transfers" */
  enrollment_transfers: Array<Enrollment_Transfers>;
  /** fetch aggregated fields from the table: "enrollment_transfers" */
  enrollment_transfers_aggregate: Enrollment_Transfers_Aggregate;
  /** fetch data from the table: "enrollment_transfers" using primary key columns */
  enrollment_transfers_by_pk?: Maybe<Enrollment_Transfers>;
  /** fetch data from the table: "practitioners" */
  practitioners: Array<Practitioners>;
  /** fetch aggregated fields from the table: "practitioners" */
  practitioners_aggregate: Practitioners_Aggregate;
  /** fetch data from the table: "practitioners" using primary key columns */
  practitioners_by_pk?: Maybe<Practitioners>;
  /** fetch data from the table: "registration_forms" */
  registration_forms: Array<Registration_Forms>;
  /** fetch aggregated fields from the table: "registration_forms" */
  registration_forms_aggregate: Registration_Forms_Aggregate;
  /** fetch data from the table: "registration_forms" using primary key columns */
  registration_forms_by_pk?: Maybe<Registration_Forms>;
  /** fetch data from the table: "registration_payments" */
  registration_payments: Array<Registration_Payments>;
  /** fetch aggregated fields from the table: "registration_payments" */
  registration_payments_aggregate: Registration_Payments_Aggregate;
  /** fetch data from the table: "registration_payments" using primary key columns */
  registration_payments_by_pk?: Maybe<Registration_Payments>;
  /** perform the action: "registration_report" */
  registration_report: Array<RegistrationReportRow>;
  /** fetch data from the table: "registrations" */
  registrations: Array<Registrations>;
  /** fetch aggregated fields from the table: "registrations" */
  registrations_aggregate: Registrations_Aggregate;
  /** fetch data from the table: "registrations" using primary key columns */
  registrations_by_pk?: Maybe<Registrations>;
  /** fetch data from the table: "scheduled_registration_payments" */
  scheduled_registration_payments: Array<Scheduled_Registration_Payments>;
  /** fetch aggregated fields from the table: "scheduled_registration_payments" */
  scheduled_registration_payments_aggregate: Scheduled_Registration_Payments_Aggregate;
  /** fetch data from the table: "scheduled_registration_payments" using primary key columns */
  scheduled_registration_payments_by_pk?: Maybe<Scheduled_Registration_Payments>;
  /** fetch data from the table: "shopify_webhooks" */
  shopify_webhooks: Array<Shopify_Webhooks>;
  /** fetch aggregated fields from the table: "shopify_webhooks" */
  shopify_webhooks_aggregate: Shopify_Webhooks_Aggregate;
  /** fetch data from the table: "shopify_webhooks" using primary key columns */
  shopify_webhooks_by_pk?: Maybe<Shopify_Webhooks>;
  /** fetch data from the table: "stripe_webhooks" */
  stripe_webhooks: Array<Stripe_Webhooks>;
  /** fetch aggregated fields from the table: "stripe_webhooks" */
  stripe_webhooks_aggregate: Stripe_Webhooks_Aggregate;
  /** fetch data from the table: "stripe_webhooks" using primary key columns */
  stripe_webhooks_by_pk?: Maybe<Stripe_Webhooks>;
  /** fetch data from the table: "trainings" */
  trainings: Array<Trainings>;
  /** fetch aggregated fields from the table: "trainings" */
  trainings_aggregate: Trainings_Aggregate;
  /** fetch data from the table: "trainings" using primary key columns */
  trainings_by_pk?: Maybe<Trainings>;
  /** perform the action: "validateCoupon" */
  validateCoupon?: Maybe<ValidateCouponResult>;
  /** perform the action: "validateTrainingDownpayment" */
  validateTrainingDownpayment: ValidateTrainingDownpaymentResult;
};


/** subscription root */
export type Subscription_RootCommission_StructuresArgs = {
  distinct_on?: Maybe<Array<Commission_Structures_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Commission_Structures_Order_By>>;
  where?: Maybe<Commission_Structures_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCommission_Structures_AggregateArgs = {
  distinct_on?: Maybe<Array<Commission_Structures_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Commission_Structures_Order_By>>;
  where?: Maybe<Commission_Structures_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCommission_Structures_By_PkArgs = {
  identifier: Scalars['String'];
};


/** subscription root */
export type Subscription_RootCoupon_Discount_TypesArgs = {
  distinct_on?: Maybe<Array<Coupon_Discount_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_Discount_Types_Order_By>>;
  where?: Maybe<Coupon_Discount_Types_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCoupon_Discount_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Coupon_Discount_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupon_Discount_Types_Order_By>>;
  where?: Maybe<Coupon_Discount_Types_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCoupon_Discount_Types_By_PkArgs = {
  name: Scalars['String'];
};


/** subscription root */
export type Subscription_RootCouponsArgs = {
  distinct_on?: Maybe<Array<Coupons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupons_Order_By>>;
  where?: Maybe<Coupons_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCoupons_AggregateArgs = {
  distinct_on?: Maybe<Array<Coupons_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Coupons_Order_By>>;
  where?: Maybe<Coupons_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCoupons_By_PkArgs = {
  code: Scalars['String'];
};


/** subscription root */
export type Subscription_RootEmail_Drip_Campaign_Cancelation_StatusArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Cancelation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Cancelation_Status_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Cancelation_Status_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmail_Drip_Campaign_Cancelation_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Cancelation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Cancelation_Status_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Cancelation_Status_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmail_Drip_Campaign_Cancelation_Status_By_PkArgs = {
  name: Scalars['String'];
};


/** subscription root */
export type Subscription_RootEmail_Drip_Campaign_Goal_TypesArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Goal_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Goal_Types_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Goal_Types_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmail_Drip_Campaign_Goal_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Goal_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Goal_Types_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Goal_Types_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmail_Drip_Campaign_Goal_Types_By_PkArgs = {
  name: Scalars['String'];
};


/** subscription root */
export type Subscription_RootEmail_Drip_Campaign_Send_After_ReferencesArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Send_After_References_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Send_After_References_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Send_After_References_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmail_Drip_Campaign_Send_After_References_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Send_After_References_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Send_After_References_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Send_After_References_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmail_Drip_Campaign_Send_After_References_By_PkArgs = {
  title: Scalars['String'];
};


/** subscription root */
export type Subscription_RootEmail_Drip_Campaign_Send_OpensArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Send_Opens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Send_Opens_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Send_Opens_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmail_Drip_Campaign_Send_Opens_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Send_Opens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Send_Opens_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Send_Opens_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmail_Drip_Campaign_Send_Opens_By_PkArgs = {
  timestamp: Scalars['timestamptz'];
};


/** subscription root */
export type Subscription_RootEmail_Drip_Campaign_SendsArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Sends_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Sends_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Sends_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmail_Drip_Campaign_Sends_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaign_Sends_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaign_Sends_Order_By>>;
  where?: Maybe<Email_Drip_Campaign_Sends_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmail_Drip_Campaign_Sends_By_PkArgs = {
  email_drip_campaign_email: Scalars['String'];
  email_drip_sequence_step: Scalars['String'];
};


/** subscription root */
export type Subscription_RootEmail_Drip_CampaignsArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaigns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaigns_Order_By>>;
  where?: Maybe<Email_Drip_Campaigns_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmail_Drip_Campaigns_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Campaigns_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Campaigns_Order_By>>;
  where?: Maybe<Email_Drip_Campaigns_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmail_Drip_Campaigns_By_PkArgs = {
  email: Scalars['String'];
  email_drip_sequence: Scalars['String'];
};


/** subscription root */
export type Subscription_RootEmail_Drip_Sequence_StepsArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Sequence_Steps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Sequence_Steps_Order_By>>;
  where?: Maybe<Email_Drip_Sequence_Steps_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmail_Drip_Sequence_Steps_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Sequence_Steps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Sequence_Steps_Order_By>>;
  where?: Maybe<Email_Drip_Sequence_Steps_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmail_Drip_Sequence_Steps_By_PkArgs = {
  title: Scalars['String'];
};


/** subscription root */
export type Subscription_RootEmail_Drip_SequencesArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Sequences_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Sequences_Order_By>>;
  where?: Maybe<Email_Drip_Sequences_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmail_Drip_Sequences_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Drip_Sequences_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Drip_Sequences_Order_By>>;
  where?: Maybe<Email_Drip_Sequences_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmail_Drip_Sequences_By_PkArgs = {
  title: Scalars['String'];
};


/** subscription root */
export type Subscription_RootEnrollment_TransfersArgs = {
  distinct_on?: Maybe<Array<Enrollment_Transfers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enrollment_Transfers_Order_By>>;
  where?: Maybe<Enrollment_Transfers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEnrollment_Transfers_AggregateArgs = {
  distinct_on?: Maybe<Array<Enrollment_Transfers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enrollment_Transfers_Order_By>>;
  where?: Maybe<Enrollment_Transfers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEnrollment_Transfers_By_PkArgs = {
  registration_email: Scalars['String'];
  registration_training: Scalars['String'];
  transfer_training: Scalars['String'];
};


/** subscription root */
export type Subscription_RootPractitionersArgs = {
  distinct_on?: Maybe<Array<Practitioners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Practitioners_Order_By>>;
  where?: Maybe<Practitioners_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPractitioners_AggregateArgs = {
  distinct_on?: Maybe<Array<Practitioners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Practitioners_Order_By>>;
  where?: Maybe<Practitioners_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPractitioners_By_PkArgs = {
  shopify_id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootRegistration_FormsArgs = {
  distinct_on?: Maybe<Array<Registration_Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registration_Forms_Order_By>>;
  where?: Maybe<Registration_Forms_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRegistration_Forms_AggregateArgs = {
  distinct_on?: Maybe<Array<Registration_Forms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registration_Forms_Order_By>>;
  where?: Maybe<Registration_Forms_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRegistration_Forms_By_PkArgs = {
  name: Scalars['String'];
};


/** subscription root */
export type Subscription_RootRegistration_PaymentsArgs = {
  distinct_on?: Maybe<Array<Registration_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registration_Payments_Order_By>>;
  where?: Maybe<Registration_Payments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRegistration_Payments_AggregateArgs = {
  distinct_on?: Maybe<Array<Registration_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registration_Payments_Order_By>>;
  where?: Maybe<Registration_Payments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRegistration_Payments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootRegistration_ReportArgs = {
  end: Scalars['date'];
  start: Scalars['date'];
  type?: Maybe<RegistrationReportType>;
};


/** subscription root */
export type Subscription_RootRegistrationsArgs = {
  distinct_on?: Maybe<Array<Registrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registrations_Order_By>>;
  where?: Maybe<Registrations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRegistrations_AggregateArgs = {
  distinct_on?: Maybe<Array<Registrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registrations_Order_By>>;
  where?: Maybe<Registrations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRegistrations_By_PkArgs = {
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  training: Scalars['String'];
};


/** subscription root */
export type Subscription_RootScheduled_Registration_PaymentsArgs = {
  distinct_on?: Maybe<Array<Scheduled_Registration_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scheduled_Registration_Payments_Order_By>>;
  where?: Maybe<Scheduled_Registration_Payments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootScheduled_Registration_Payments_AggregateArgs = {
  distinct_on?: Maybe<Array<Scheduled_Registration_Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scheduled_Registration_Payments_Order_By>>;
  where?: Maybe<Scheduled_Registration_Payments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootScheduled_Registration_Payments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootShopify_WebhooksArgs = {
  distinct_on?: Maybe<Array<Shopify_Webhooks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shopify_Webhooks_Order_By>>;
  where?: Maybe<Shopify_Webhooks_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootShopify_Webhooks_AggregateArgs = {
  distinct_on?: Maybe<Array<Shopify_Webhooks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shopify_Webhooks_Order_By>>;
  where?: Maybe<Shopify_Webhooks_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootShopify_Webhooks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootStripe_WebhooksArgs = {
  distinct_on?: Maybe<Array<Stripe_Webhooks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stripe_Webhooks_Order_By>>;
  where?: Maybe<Stripe_Webhooks_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStripe_Webhooks_AggregateArgs = {
  distinct_on?: Maybe<Array<Stripe_Webhooks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stripe_Webhooks_Order_By>>;
  where?: Maybe<Stripe_Webhooks_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStripe_Webhooks_By_PkArgs = {
  event_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootTrainingsArgs = {
  distinct_on?: Maybe<Array<Trainings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trainings_Order_By>>;
  where?: Maybe<Trainings_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTrainings_AggregateArgs = {
  distinct_on?: Maybe<Array<Trainings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Trainings_Order_By>>;
  where?: Maybe<Trainings_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTrainings_By_PkArgs = {
  slug: Scalars['String'];
};


/** subscription root */
export type Subscription_RootValidateCouponArgs = {
  input: ValidateCouponInput;
};


/** subscription root */
export type Subscription_RootValidateTrainingDownpaymentArgs = {
  training: Scalars['String'];
};


/** expression to compare columns of type timestamp. All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "trainings" */
export type Trainings = {
  __typename?: 'trainings';
  additional_checkbox?: Maybe<Scalars['String']>;
  allows_coupons: Scalars['Boolean'];
  commission_structure?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  downpayment?: Maybe<Scalars['Int']>;
  downpayment_cutoff_days?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  /** An array relationship */
  enrollment_transfers: Array<Enrollment_Transfers>;
  /** An aggregated array relationship */
  enrollment_transfers_aggregate: Enrollment_Transfers_Aggregate;
  excerpt?: Maybe<Scalars['String']>;
  legacy_course_id?: Maybe<Scalars['bigint']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  legacy_slug?: Maybe<Scalars['String']>;
  payment_plan_dates: Scalars['json'];
  price: Scalars['Int'];
  /** An object relationship */
  registrationFormByRegistrationForm?: Maybe<Registration_Forms>;
  registration_form?: Maybe<Scalars['String']>;
  /** An array relationship */
  registrations: Array<Registrations>;
  /** An aggregated array relationship */
  registrations_aggregate: Registrations_Aggregate;
  slug: Scalars['String'];
  start_date?: Maybe<Scalars['date']>;
  teachable_course_id: Scalars['bigint'];
  thank_you_page_tracking_id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};


/** columns and relationships of "trainings" */
export type TrainingsEnrollment_TransfersArgs = {
  distinct_on?: Maybe<Array<Enrollment_Transfers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enrollment_Transfers_Order_By>>;
  where?: Maybe<Enrollment_Transfers_Bool_Exp>;
};


/** columns and relationships of "trainings" */
export type TrainingsEnrollment_Transfers_AggregateArgs = {
  distinct_on?: Maybe<Array<Enrollment_Transfers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enrollment_Transfers_Order_By>>;
  where?: Maybe<Enrollment_Transfers_Bool_Exp>;
};


/** columns and relationships of "trainings" */
export type TrainingsPayment_Plan_DatesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "trainings" */
export type TrainingsRegistrationsArgs = {
  distinct_on?: Maybe<Array<Registrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registrations_Order_By>>;
  where?: Maybe<Registrations_Bool_Exp>;
};


/** columns and relationships of "trainings" */
export type TrainingsRegistrations_AggregateArgs = {
  distinct_on?: Maybe<Array<Registrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Registrations_Order_By>>;
  where?: Maybe<Registrations_Bool_Exp>;
};

/** aggregated selection of "trainings" */
export type Trainings_Aggregate = {
  __typename?: 'trainings_aggregate';
  aggregate?: Maybe<Trainings_Aggregate_Fields>;
  nodes: Array<Trainings>;
};

/** aggregate fields of "trainings" */
export type Trainings_Aggregate_Fields = {
  __typename?: 'trainings_aggregate_fields';
  avg?: Maybe<Trainings_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Trainings_Max_Fields>;
  min?: Maybe<Trainings_Min_Fields>;
  stddev?: Maybe<Trainings_Stddev_Fields>;
  stddev_pop?: Maybe<Trainings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Trainings_Stddev_Samp_Fields>;
  sum?: Maybe<Trainings_Sum_Fields>;
  var_pop?: Maybe<Trainings_Var_Pop_Fields>;
  var_samp?: Maybe<Trainings_Var_Samp_Fields>;
  variance?: Maybe<Trainings_Variance_Fields>;
};


/** aggregate fields of "trainings" */
export type Trainings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Trainings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "trainings" */
export type Trainings_Aggregate_Order_By = {
  avg?: Maybe<Trainings_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Trainings_Max_Order_By>;
  min?: Maybe<Trainings_Min_Order_By>;
  stddev?: Maybe<Trainings_Stddev_Order_By>;
  stddev_pop?: Maybe<Trainings_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Trainings_Stddev_Samp_Order_By>;
  sum?: Maybe<Trainings_Sum_Order_By>;
  var_pop?: Maybe<Trainings_Var_Pop_Order_By>;
  var_samp?: Maybe<Trainings_Var_Samp_Order_By>;
  variance?: Maybe<Trainings_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "trainings" */
export type Trainings_Arr_Rel_Insert_Input = {
  data: Array<Trainings_Insert_Input>;
  on_conflict?: Maybe<Trainings_On_Conflict>;
};

/** aggregate avg on columns */
export type Trainings_Avg_Fields = {
  __typename?: 'trainings_avg_fields';
  downpayment?: Maybe<Scalars['Float']>;
  downpayment_cutoff_days?: Maybe<Scalars['Float']>;
  legacy_course_id?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  teachable_course_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "trainings" */
export type Trainings_Avg_Order_By = {
  downpayment?: Maybe<Order_By>;
  downpayment_cutoff_days?: Maybe<Order_By>;
  legacy_course_id?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  teachable_course_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "trainings". All fields are combined with a logical 'AND'. */
export type Trainings_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Trainings_Bool_Exp>>>;
  _not?: Maybe<Trainings_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Trainings_Bool_Exp>>>;
  additional_checkbox?: Maybe<String_Comparison_Exp>;
  allows_coupons?: Maybe<Boolean_Comparison_Exp>;
  commission_structure?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  downpayment?: Maybe<Int_Comparison_Exp>;
  downpayment_cutoff_days?: Maybe<Int_Comparison_Exp>;
  end_date?: Maybe<Date_Comparison_Exp>;
  enrollment_transfers?: Maybe<Enrollment_Transfers_Bool_Exp>;
  excerpt?: Maybe<String_Comparison_Exp>;
  legacy_course_id?: Maybe<Bigint_Comparison_Exp>;
  legacy_id?: Maybe<Bigint_Comparison_Exp>;
  legacy_slug?: Maybe<String_Comparison_Exp>;
  payment_plan_dates?: Maybe<Json_Comparison_Exp>;
  price?: Maybe<Int_Comparison_Exp>;
  registrationFormByRegistrationForm?: Maybe<Registration_Forms_Bool_Exp>;
  registration_form?: Maybe<String_Comparison_Exp>;
  registrations?: Maybe<Registrations_Bool_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
  start_date?: Maybe<Date_Comparison_Exp>;
  teachable_course_id?: Maybe<Bigint_Comparison_Exp>;
  thank_you_page_tracking_id?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "trainings" */
export enum Trainings_Constraint {
  /** unique or primary key constraint */
  TrainingsLegacySlugKey = 'trainings_legacy_slug_key',
  /** unique or primary key constraint */
  TrainingsPkey = 'trainings_pkey'
}

/** input type for incrementing integer column in table "trainings" */
export type Trainings_Inc_Input = {
  downpayment?: Maybe<Scalars['Int']>;
  downpayment_cutoff_days?: Maybe<Scalars['Int']>;
  legacy_course_id?: Maybe<Scalars['bigint']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['Int']>;
  teachable_course_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "trainings" */
export type Trainings_Insert_Input = {
  additional_checkbox?: Maybe<Scalars['String']>;
  allows_coupons?: Maybe<Scalars['Boolean']>;
  commission_structure?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  downpayment?: Maybe<Scalars['Int']>;
  downpayment_cutoff_days?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  enrollment_transfers?: Maybe<Enrollment_Transfers_Arr_Rel_Insert_Input>;
  excerpt?: Maybe<Scalars['String']>;
  legacy_course_id?: Maybe<Scalars['bigint']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  legacy_slug?: Maybe<Scalars['String']>;
  payment_plan_dates?: Maybe<Scalars['json']>;
  price?: Maybe<Scalars['Int']>;
  registrationFormByRegistrationForm?: Maybe<Registration_Forms_Obj_Rel_Insert_Input>;
  registration_form?: Maybe<Scalars['String']>;
  registrations?: Maybe<Registrations_Arr_Rel_Insert_Input>;
  slug?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  teachable_course_id?: Maybe<Scalars['bigint']>;
  thank_you_page_tracking_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Trainings_Max_Fields = {
  __typename?: 'trainings_max_fields';
  additional_checkbox?: Maybe<Scalars['String']>;
  commission_structure?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  downpayment?: Maybe<Scalars['Int']>;
  downpayment_cutoff_days?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  excerpt?: Maybe<Scalars['String']>;
  legacy_course_id?: Maybe<Scalars['bigint']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  legacy_slug?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  registration_form?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  teachable_course_id?: Maybe<Scalars['bigint']>;
  thank_you_page_tracking_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "trainings" */
export type Trainings_Max_Order_By = {
  additional_checkbox?: Maybe<Order_By>;
  commission_structure?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  downpayment?: Maybe<Order_By>;
  downpayment_cutoff_days?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  excerpt?: Maybe<Order_By>;
  legacy_course_id?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  legacy_slug?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  registration_form?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  teachable_course_id?: Maybe<Order_By>;
  thank_you_page_tracking_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Trainings_Min_Fields = {
  __typename?: 'trainings_min_fields';
  additional_checkbox?: Maybe<Scalars['String']>;
  commission_structure?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  downpayment?: Maybe<Scalars['Int']>;
  downpayment_cutoff_days?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  excerpt?: Maybe<Scalars['String']>;
  legacy_course_id?: Maybe<Scalars['bigint']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  legacy_slug?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  registration_form?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  teachable_course_id?: Maybe<Scalars['bigint']>;
  thank_you_page_tracking_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "trainings" */
export type Trainings_Min_Order_By = {
  additional_checkbox?: Maybe<Order_By>;
  commission_structure?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  downpayment?: Maybe<Order_By>;
  downpayment_cutoff_days?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  excerpt?: Maybe<Order_By>;
  legacy_course_id?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  legacy_slug?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  registration_form?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  teachable_course_id?: Maybe<Order_By>;
  thank_you_page_tracking_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "trainings" */
export type Trainings_Mutation_Response = {
  __typename?: 'trainings_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Trainings>;
};

/** input type for inserting object relation for remote table "trainings" */
export type Trainings_Obj_Rel_Insert_Input = {
  data: Trainings_Insert_Input;
  on_conflict?: Maybe<Trainings_On_Conflict>;
};

/** on conflict condition type for table "trainings" */
export type Trainings_On_Conflict = {
  constraint: Trainings_Constraint;
  update_columns: Array<Trainings_Update_Column>;
  where?: Maybe<Trainings_Bool_Exp>;
};

/** ordering options when selecting data from "trainings" */
export type Trainings_Order_By = {
  additional_checkbox?: Maybe<Order_By>;
  allows_coupons?: Maybe<Order_By>;
  commission_structure?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  downpayment?: Maybe<Order_By>;
  downpayment_cutoff_days?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  enrollment_transfers_aggregate?: Maybe<Enrollment_Transfers_Aggregate_Order_By>;
  excerpt?: Maybe<Order_By>;
  legacy_course_id?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  legacy_slug?: Maybe<Order_By>;
  payment_plan_dates?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  registrationFormByRegistrationForm?: Maybe<Registration_Forms_Order_By>;
  registration_form?: Maybe<Order_By>;
  registrations_aggregate?: Maybe<Registrations_Aggregate_Order_By>;
  slug?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  teachable_course_id?: Maybe<Order_By>;
  thank_you_page_tracking_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "trainings" */
export type Trainings_Pk_Columns_Input = {
  slug: Scalars['String'];
};

/** select columns of table "trainings" */
export enum Trainings_Select_Column {
  /** column name */
  AdditionalCheckbox = 'additional_checkbox',
  /** column name */
  AllowsCoupons = 'allows_coupons',
  /** column name */
  CommissionStructure = 'commission_structure',
  /** column name */
  Description = 'description',
  /** column name */
  Downpayment = 'downpayment',
  /** column name */
  DownpaymentCutoffDays = 'downpayment_cutoff_days',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Excerpt = 'excerpt',
  /** column name */
  LegacyCourseId = 'legacy_course_id',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  LegacySlug = 'legacy_slug',
  /** column name */
  PaymentPlanDates = 'payment_plan_dates',
  /** column name */
  Price = 'price',
  /** column name */
  RegistrationForm = 'registration_form',
  /** column name */
  Slug = 'slug',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  TeachableCourseId = 'teachable_course_id',
  /** column name */
  ThankYouPageTrackingId = 'thank_you_page_tracking_id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "trainings" */
export type Trainings_Set_Input = {
  additional_checkbox?: Maybe<Scalars['String']>;
  allows_coupons?: Maybe<Scalars['Boolean']>;
  commission_structure?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  downpayment?: Maybe<Scalars['Int']>;
  downpayment_cutoff_days?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['date']>;
  excerpt?: Maybe<Scalars['String']>;
  legacy_course_id?: Maybe<Scalars['bigint']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  legacy_slug?: Maybe<Scalars['String']>;
  payment_plan_dates?: Maybe<Scalars['json']>;
  price?: Maybe<Scalars['Int']>;
  registration_form?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  teachable_course_id?: Maybe<Scalars['bigint']>;
  thank_you_page_tracking_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Trainings_Stddev_Fields = {
  __typename?: 'trainings_stddev_fields';
  downpayment?: Maybe<Scalars['Float']>;
  downpayment_cutoff_days?: Maybe<Scalars['Float']>;
  legacy_course_id?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  teachable_course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "trainings" */
export type Trainings_Stddev_Order_By = {
  downpayment?: Maybe<Order_By>;
  downpayment_cutoff_days?: Maybe<Order_By>;
  legacy_course_id?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  teachable_course_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Trainings_Stddev_Pop_Fields = {
  __typename?: 'trainings_stddev_pop_fields';
  downpayment?: Maybe<Scalars['Float']>;
  downpayment_cutoff_days?: Maybe<Scalars['Float']>;
  legacy_course_id?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  teachable_course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "trainings" */
export type Trainings_Stddev_Pop_Order_By = {
  downpayment?: Maybe<Order_By>;
  downpayment_cutoff_days?: Maybe<Order_By>;
  legacy_course_id?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  teachable_course_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Trainings_Stddev_Samp_Fields = {
  __typename?: 'trainings_stddev_samp_fields';
  downpayment?: Maybe<Scalars['Float']>;
  downpayment_cutoff_days?: Maybe<Scalars['Float']>;
  legacy_course_id?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  teachable_course_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "trainings" */
export type Trainings_Stddev_Samp_Order_By = {
  downpayment?: Maybe<Order_By>;
  downpayment_cutoff_days?: Maybe<Order_By>;
  legacy_course_id?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  teachable_course_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Trainings_Sum_Fields = {
  __typename?: 'trainings_sum_fields';
  downpayment?: Maybe<Scalars['Int']>;
  downpayment_cutoff_days?: Maybe<Scalars['Int']>;
  legacy_course_id?: Maybe<Scalars['bigint']>;
  legacy_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['Int']>;
  teachable_course_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "trainings" */
export type Trainings_Sum_Order_By = {
  downpayment?: Maybe<Order_By>;
  downpayment_cutoff_days?: Maybe<Order_By>;
  legacy_course_id?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  teachable_course_id?: Maybe<Order_By>;
};

/** update columns of table "trainings" */
export enum Trainings_Update_Column {
  /** column name */
  AdditionalCheckbox = 'additional_checkbox',
  /** column name */
  AllowsCoupons = 'allows_coupons',
  /** column name */
  CommissionStructure = 'commission_structure',
  /** column name */
  Description = 'description',
  /** column name */
  Downpayment = 'downpayment',
  /** column name */
  DownpaymentCutoffDays = 'downpayment_cutoff_days',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Excerpt = 'excerpt',
  /** column name */
  LegacyCourseId = 'legacy_course_id',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  LegacySlug = 'legacy_slug',
  /** column name */
  PaymentPlanDates = 'payment_plan_dates',
  /** column name */
  Price = 'price',
  /** column name */
  RegistrationForm = 'registration_form',
  /** column name */
  Slug = 'slug',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  TeachableCourseId = 'teachable_course_id',
  /** column name */
  ThankYouPageTrackingId = 'thank_you_page_tracking_id',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type Trainings_Var_Pop_Fields = {
  __typename?: 'trainings_var_pop_fields';
  downpayment?: Maybe<Scalars['Float']>;
  downpayment_cutoff_days?: Maybe<Scalars['Float']>;
  legacy_course_id?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  teachable_course_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "trainings" */
export type Trainings_Var_Pop_Order_By = {
  downpayment?: Maybe<Order_By>;
  downpayment_cutoff_days?: Maybe<Order_By>;
  legacy_course_id?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  teachable_course_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Trainings_Var_Samp_Fields = {
  __typename?: 'trainings_var_samp_fields';
  downpayment?: Maybe<Scalars['Float']>;
  downpayment_cutoff_days?: Maybe<Scalars['Float']>;
  legacy_course_id?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  teachable_course_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "trainings" */
export type Trainings_Var_Samp_Order_By = {
  downpayment?: Maybe<Order_By>;
  downpayment_cutoff_days?: Maybe<Order_By>;
  legacy_course_id?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  teachable_course_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Trainings_Variance_Fields = {
  __typename?: 'trainings_variance_fields';
  downpayment?: Maybe<Scalars['Float']>;
  downpayment_cutoff_days?: Maybe<Scalars['Float']>;
  legacy_course_id?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  teachable_course_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "trainings" */
export type Trainings_Variance_Order_By = {
  downpayment?: Maybe<Order_By>;
  downpayment_cutoff_days?: Maybe<Order_By>;
  legacy_course_id?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  teachable_course_id?: Maybe<Order_By>;
};


/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type RegisterMutationVariables = Exact<{
  email: Scalars['String'];
  training: Scalars['String'];
  coupon?: Maybe<Scalars['String']>;
  payInFull: Scalars['Boolean'];
  formData: Scalars['json'];
  trackingId?: Maybe<Scalars['String']>;
}>;


export type RegisterMutation = (
  { __typename?: 'mutation_root' }
  & { register?: Maybe<(
    { __typename?: 'RegistrationResult' }
    & Pick<RegistrationResult, 'checkoutSession' | 'email' | 'training' | 'errors'>
  )> }
);

export type TrainingForRegistrationQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type TrainingForRegistrationQuery = (
  { __typename?: 'query_root' }
  & { trainings: Array<(
    { __typename?: 'trainings' }
    & Pick<Trainings, 'allows_coupons' | 'description' | 'downpayment' | 'downpayment_cutoff_days' | 'end_date' | 'price' | 'slug' | 'start_date' | 'title' | 'payment_plan_dates' | 'thank_you_page_tracking_id' | 'additional_checkbox'>
    & { registrationFormByRegistrationForm?: Maybe<(
      { __typename?: 'registration_forms' }
      & Pick<Registration_Forms, 'inputs'>
    )> }
  )> }
);

export type ValidateTrainingDownpaymentQueryVariables = Exact<{
  training: Scalars['String'];
}>;


export type ValidateTrainingDownpaymentQuery = (
  { __typename?: 'query_root' }
  & { validateTrainingDownpayment: (
    { __typename?: 'ValidateTrainingDownpaymentResult' }
    & Pick<ValidateTrainingDownpaymentResult, 'isValid'>
  ) }
);

export type ValidateCouponQueryVariables = Exact<{
  code: Scalars['String'];
  email: Scalars['String'];
  training: Scalars['String'];
}>;


export type ValidateCouponQuery = (
  { __typename?: 'query_root' }
  & { validateCoupon?: Maybe<(
    { __typename?: 'ValidateCouponResult' }
    & Pick<ValidateCouponResult, 'isValid'>
  )> }
);


export const RegisterDocument = gql`
    mutation Register($email: String!, $training: String!, $coupon: String, $payInFull: Boolean!, $formData: json!, $trackingId: String) {
  register(
    formData: $formData
    payInFull: $payInFull
    registrationData: {email: $email, training: $training, coupon: $coupon}
    trackingId: $trackingId
  ) {
    checkoutSession
    email
    training
    errors
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      email: // value for 'email'
 *      training: // value for 'training'
 *      coupon: // value for 'coupon'
 *      payInFull: // value for 'payInFull'
 *      formData: // value for 'formData'
 *      trackingId: // value for 'trackingId'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, baseOptions);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const TrainingForRegistrationDocument = gql`
    query TrainingForRegistration($slug: String!) {
  trainings(where: {_or: [{slug: {_eq: $slug}}, {legacy_slug: {_eq: $slug}}]}) {
    allows_coupons
    description
    downpayment
    downpayment_cutoff_days
    end_date
    price
    registrationFormByRegistrationForm {
      inputs
    }
    slug
    start_date
    title
    payment_plan_dates
    thank_you_page_tracking_id
    additional_checkbox
  }
}
    `;

/**
 * __useTrainingForRegistrationQuery__
 *
 * To run a query within a React component, call `useTrainingForRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingForRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingForRegistrationQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useTrainingForRegistrationQuery(baseOptions: Apollo.QueryHookOptions<TrainingForRegistrationQuery, TrainingForRegistrationQueryVariables>) {
        return Apollo.useQuery<TrainingForRegistrationQuery, TrainingForRegistrationQueryVariables>(TrainingForRegistrationDocument, baseOptions);
      }
export function useTrainingForRegistrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrainingForRegistrationQuery, TrainingForRegistrationQueryVariables>) {
          return Apollo.useLazyQuery<TrainingForRegistrationQuery, TrainingForRegistrationQueryVariables>(TrainingForRegistrationDocument, baseOptions);
        }
export type TrainingForRegistrationQueryHookResult = ReturnType<typeof useTrainingForRegistrationQuery>;
export type TrainingForRegistrationLazyQueryHookResult = ReturnType<typeof useTrainingForRegistrationLazyQuery>;
export type TrainingForRegistrationQueryResult = Apollo.QueryResult<TrainingForRegistrationQuery, TrainingForRegistrationQueryVariables>;
export const ValidateTrainingDownpaymentDocument = gql`
    query ValidateTrainingDownpayment($training: String!) {
  validateTrainingDownpayment(training: $training) {
    isValid
  }
}
    `;

/**
 * __useValidateTrainingDownpaymentQuery__
 *
 * To run a query within a React component, call `useValidateTrainingDownpaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateTrainingDownpaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateTrainingDownpaymentQuery({
 *   variables: {
 *      training: // value for 'training'
 *   },
 * });
 */
export function useValidateTrainingDownpaymentQuery(baseOptions: Apollo.QueryHookOptions<ValidateTrainingDownpaymentQuery, ValidateTrainingDownpaymentQueryVariables>) {
        return Apollo.useQuery<ValidateTrainingDownpaymentQuery, ValidateTrainingDownpaymentQueryVariables>(ValidateTrainingDownpaymentDocument, baseOptions);
      }
export function useValidateTrainingDownpaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateTrainingDownpaymentQuery, ValidateTrainingDownpaymentQueryVariables>) {
          return Apollo.useLazyQuery<ValidateTrainingDownpaymentQuery, ValidateTrainingDownpaymentQueryVariables>(ValidateTrainingDownpaymentDocument, baseOptions);
        }
export type ValidateTrainingDownpaymentQueryHookResult = ReturnType<typeof useValidateTrainingDownpaymentQuery>;
export type ValidateTrainingDownpaymentLazyQueryHookResult = ReturnType<typeof useValidateTrainingDownpaymentLazyQuery>;
export type ValidateTrainingDownpaymentQueryResult = Apollo.QueryResult<ValidateTrainingDownpaymentQuery, ValidateTrainingDownpaymentQueryVariables>;
export const ValidateCouponDocument = gql`
    query ValidateCoupon($code: String!, $email: String!, $training: String!) {
  validateCoupon(input: {code: $code, email: $email, training: $training}) {
    isValid
  }
}
    `;

/**
 * __useValidateCouponQuery__
 *
 * To run a query within a React component, call `useValidateCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateCouponQuery({
 *   variables: {
 *      code: // value for 'code'
 *      email: // value for 'email'
 *      training: // value for 'training'
 *   },
 * });
 */
export function useValidateCouponQuery(baseOptions: Apollo.QueryHookOptions<ValidateCouponQuery, ValidateCouponQueryVariables>) {
        return Apollo.useQuery<ValidateCouponQuery, ValidateCouponQueryVariables>(ValidateCouponDocument, baseOptions);
      }
export function useValidateCouponLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateCouponQuery, ValidateCouponQueryVariables>) {
          return Apollo.useLazyQuery<ValidateCouponQuery, ValidateCouponQueryVariables>(ValidateCouponDocument, baseOptions);
        }
export type ValidateCouponQueryHookResult = ReturnType<typeof useValidateCouponQuery>;
export type ValidateCouponLazyQueryHookResult = ReturnType<typeof useValidateCouponLazyQuery>;
export type ValidateCouponQueryResult = Apollo.QueryResult<ValidateCouponQuery, ValidateCouponQueryVariables>;