import { FC, useEffect, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import {
  useRegisterMutation,
  useTrainingForRegistrationQuery,
  useValidateCouponLazyQuery,
  useValidateTrainingDownpaymentQuery,
} from "./generated/graphql";
import {
  currencyFormatter,
  dateRange,
  RegistrationFormInput as RegistrationFormInputType,
  trainingCostDisplay,
} from "./lib";
import { Controller, useForm } from "react-hook-form";
import RegistrationFormInput from "./RegistrationFormInput";
import { DateTime } from "luxon";
import { useDebounce } from "use-debounce/lib";
import _ from "lodash";
import { loadStripe } from "@stripe/stripe-js";

const TrainingRegistration: FC = () => {
  const [tapfiliateTrackingId, setTapfiliateTrackingId] = useState<string>();

  useEffect(() => {
    (function (t, a, p) {
      (t as any).TapfiliateObject = a;
      t[a as any] =
        t[a as any] ||
        function () {
          ((t[a as any] as any).q = (t[a as any] as any).q || []).push(
            arguments
          );
        };
    })(window, "tap");

    (window as any).tap("create", "12473-d7ca5f");
    (window as any).tap("detect", { cookie_domain: "yomassage.com" });

    (window as any).tap("getTrackingId", null, (trackingId: string) => {
      setTapfiliateTrackingId(trackingId);
    });
  });

  const {
    params: { slug },
  } = useRouteMatch<{ slug: string }>();

  const history = useHistory();

  const trainingQuery = useTrainingForRegistrationQuery({
    variables: { slug },
    skip: !slug,
  });

  const training = trainingQuery.data?.trainings[0];

  const validateTrainingDownpaymentQuery = useValidateTrainingDownpaymentQuery({
    skip: !training,
    variables: {
      training: training?.slug ?? "",
    },
  });

  const acceptsDownpayment =
    validateTrainingDownpaymentQuery.data?.validateTrainingDownpayment.isValid;

  const formInputs =
    (training?.registrationFormByRegistrationForm
      ?.inputs as RegistrationFormInputType[]) ?? [];

  const { control, register, getValues, watch, handleSubmit } = useForm<{
    first_name: string;
    last_name: string;
    email: string;
    tos: boolean;
    [k: string]: string | boolean;
    pay_in_full: string;
    coupon: string;
  }>({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      tos: false,
      pay_in_full: "Yes",
      coupon: "",
    },
  });

  const watchCoupon = watch("coupon", "") as string;
  const watchEmail = watch("email");
  const watchPaymentPlan = watch("pay_in_full");

  const [debouncedCouponValue] = useDebounce(watchCoupon, 1000);

  const [validateCouponQuery, validateCouponQueryResult] =
    useValidateCouponLazyQuery();

  useEffect(() => {
    debouncedCouponValue?.length &&
      watchEmail.length &&
      validateCouponQuery({
        variables: {
          email: watchEmail,
          code: debouncedCouponValue,
          training: training?.slug as string,
        },
      });
  }, [debouncedCouponValue, validateCouponQuery, watchEmail, training]);

  const [registerMutation, registerMutationMeta] = useRegisterMutation();

  async function onSubmit() {
    if (!training) return;
    if (registerMutationMeta.loading) return;

    const values = getValues();

    const { data, errors } = await registerMutation({
      variables: {
        email: values.email,
        training: training?.slug,
        coupon: values.coupon,
        payInFull: values.pay_in_full === "Yes",
        trackingId: tapfiliateTrackingId,
        formData: _.omit(values, ["email", "tos", "pay_in_full", "coupon"]),
      },
    });

    if (errors?.length) {
      alert("Something went wrong.");
    } else if (data?.register?.errors?.length) {
      alert("Already registered.");
    } else {
      const session = data?.register?.checkoutSession;
      if (!session) {
        history.push(
          `/thank-you?trainingType=${training.thank_you_page_tracking_id}`
        );
      } else {
        const stripe = await loadStripe(
          process.env.NODE_ENV === "production"
            ? "pk_live_TEp9GTzpmwD7xBXQjY2pWtSP"
            : "pk_test_Cll9z0rZ8ACrxXy8TRg5Brph"
        );

        await stripe?.redirectToCheckout({
          sessionId: session,
        });
      }
    }
  }

  return !training ? (
    <></>
  ) : (
    <>
      <h2 className="low-bottom">{training.title}</h2>
      <h4>
        {dateRange(
          DateTime.fromISO(training.start_date).isValid
            ? DateTime.fromISO(training.start_date).toJSDate()
            : null,
          DateTime.fromISO(training.end_date).isValid
            ? DateTime.fromISO(training.end_date).toJSDate()
            : null
        )}
      </h4>
      <h3
        className="highest-bottom"
        style={acceptsDownpayment ? { marginBottom: 0 } : {}}
      >
        {trainingCostDisplay(training, acceptsDownpayment)}
      </h3>
      {acceptsDownpayment && (
        <h5
          style={{
            marginBottom: 18,
            fontWeight: "normal",
            fontFamily: '"Cormorant Reg", sans-serif',
          }}
        >
          {currencyFormatter.format(training.price / 100)} due today if paid in
          full
        </h5>
      )}
      <div
        dangerouslySetInnerHTML={{ __html: training.description }}
        style={{ textAlign: "center", marginTop: 10 }}
      ></div>
      <div className="form-block w-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="first_name"
            render={({ name, onChange }) => (
              <RegistrationFormInput
                name={name}
                required={true}
                inputType="text"
                placeholder="First name"
                onChange={(v) => onChange(v)}
              />
            )}
          />
          <Controller
            control={control}
            name="last_name"
            render={({ name, onChange }) => (
              <RegistrationFormInput
                name={name}
                required={true}
                inputType="text"
                placeholder="Last name"
                onChange={(v) => onChange(v)}
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            render={({ name, onChange }) => (
              <RegistrationFormInput
                name={name}
                required={true}
                inputType="text"
                placeholder="Email"
                onChange={(v) => onChange(v)}
              />
            )}
          />

          {[...formInputs]
            .sort((a, b) => a.rank - b.rank)
            .map((input) => (
              <Controller
                key={`registration-form-input-${input.name}`}
                control={control}
                name={input.name}
                defaultValue=""
                render={({ name, onChange }) => (
                  <RegistrationFormInput
                    name={name}
                    inputType={input.type}
                    placeholder={input.placeholder}
                    onChange={(v) => onChange(v)}
                    required={input.required}
                  />
                )}
              />
            ))}

          {training.allows_coupons && (
            <Controller
              control={control}
              name="coupon"
              defaultValue=""
              render={({ name, onChange }) => (
                <RegistrationFormInput
                  name={name}
                  inputType="text"
                  placeholder="Coupon"
                  onChange={(v) => onChange(v)}
                />
              )}
            />
          )}

          <div className="form-error">
            {!!validateCouponQueryResult.data?.validateCoupon?.isValid &&
              (watchPaymentPlan === "Yes"
                ? "Discount applied at checkout"
                : "Discount applied to payment plan")}
            {!!validateCouponQueryResult.error ||
              (validateCouponQueryResult.data?.validateCoupon?.isValid ===
                false &&
                "Coupon is invalid")}
          </div>

          {!!acceptsDownpayment && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", marginRight: 20 }}>
                <input
                  type="radio"
                  value="Yes"
                  name="pay_in_full"
                  ref={register({ required: true })}
                />
                <label style={{ marginLeft: 10 }} htmlFor="Pay in Full">
                  Pay in Full
                </label>
              </div>
              <div style={{ display: "flex" }}>
                <input
                  name="pay_in_full"
                  type="radio"
                  value="No"
                  ref={register({ required: true })}
                />
                <label
                  style={{ marginLeft: 10 }}
                  htmlFor="Pay With Payment Plan"
                >
                  Pay with Payment Plan
                </label>
              </div>
            </div>
          )}

          <label className="w-checkbox checkbox">
            <input
              type="checkbox"
              id="Terms and conditions"
              required
              className="w-checkbox-input toc"
              name="tos"
              ref={register}
            />
            <span style={{ fontWeight: "normal" }} className="w-form-label">
              I agree to the Yomassage®&nbsp;
              <a
                href="/terms-and-conditions.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="copyright-link"
                style={{ textDecoration: "underline", fontWeight: "bold" }}
              >
                terms and conditions
              </a>
              &nbsp;and understand the&nbsp;
              <a
                href="https://yomassage.com/pages/how-we-support-you"
                target="_blank"
                rel="noopener noreferrer"
                className="copyright-link"
                style={{ textDecoration: "underline", fontWeight: "bold" }}
              >
                licensing agreement
              </a>
              &nbsp;to use the Yomassage® brand.
            </span>
          </label>

          {!!training.additional_checkbox && (
            <label className="w-checkbox checkbox">
              <input
                type="checkbox"
                required
                className="w-checkbox-input toc"
                name="ac"
                ref={register}
              />
              <span style={{ fontWeight: "normal" }} className="w-form-label">
                {training.additional_checkbox}
              </span>
            </label>
          )}

          <button
            type="submit"
            className="submit-button w-button"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {registerMutationMeta.loading ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    </>
  );
};

export default TrainingRegistration;
